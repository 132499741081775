import { FC } from 'react';
import { useStyles } from './main-page-styles';
import React from 'react';
import { MainHeader } from './components/main-header';

export const MainPage: FC = ({ children }) => {

    const classes = useStyles();
    return (
        <>
            <div className={classes.containerBody}>
                <MainHeader />
                {children}
            </div>
        </>
    );
};
