import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    botaoSelecionado: {
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: '30px',
        height: '3rem',
        boxShadow: 'none'
    },
    botaoNaoSelecionado: {
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.light,
        borderRadius: '30px',
        height: '3rem',
        boxShadow: 'none',
        '&.MuiButton-outlined': {
            border: 'none'
        }
    },
    botoesAgrupados: {
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.primary.light,
        borderRadius: '30px',
        height: '3rem',
        display: 'flex',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            margin: `0 ${theme.spacing(1)}px`
        }
    }
}));