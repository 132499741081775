import {
  IdentidadeAutenticarProps,
  usePostIdentidadeAutenticar,
} from 'data/api/gestao/identidade';
import React, { useState } from 'react';
import { TokenGestaoEmpresaModel, TokenGestaoModel } from '../../../../model/api/gestao/master';
import {
  GestaoStorageKeys,
  useGestaoStorage,
  useGestaoToken,
} from '../../use-cases';
import { useCallback } from 'react';
import { ApiError } from '../../../../model/app/errors/api-erros';
import { IdentidadeIntegracaoProps, usePostIdentidadeIntegracao } from 'data/api/gestao/identidade/post-integracao';
import { EnumRetornoApiBase } from 'data/api/base/api-base-response';
import { useGetPoliticaValidar } from 'data/api/gestao/politica';
import { UsuarioConectadoModel } from 'model/app';
import { isEmpty } from 'lodash';
import { usePostIdentidadeRefreshToken } from 'data/api/gestao/identidade/post-refresh-token';

interface SessaoAtualContextValue {
  usuario?: TokenGestaoModel;
  carregando: boolean;
  logar: (
    usuario: string,
    senha: string,
    manterConectado: boolean,
  ) => Promise<void>;
  logarIntegracao: (
    id: string,
    documento: string,
  ) => Promise<void>;
  deslogar: (usuarioId: string) => void;
  setarUser: (token: string, manterConecotado: boolean) => Promise<void>;
  converterToken: (token: string) => TokenGestaoModel | undefined;
  persistirTermos: (status: boolean, statusCode: number) => void;
  termosDeUsoAtivo: () => boolean;
  getEmpresa: () => TokenGestaoEmpresaModel;
  carregarDadosUsuario: (user: TokenGestaoModel) => Promise<void>;
  refreshUser: (refreshToken: string) => Promise<boolean>;
  validaUsuarioConectado: () => boolean;
}

const SessaoAtualContext = React.createContext<SessaoAtualContextValue>({
  usuario: undefined,
  carregando: false,

  logar: (usuario: string, senha: string, manterConecotado: boolean) => {
    return new Promise<void>(() => true);
  },
  logarIntegracao: (id: string, documento: string) => {
    return new Promise<void>(() => true);
  },
  converterToken: (token: string) => {
    return undefined;
  },
  carregarDadosUsuario: (user: TokenGestaoModel) => {
    return new Promise<void>(() => true);
  },
  setarUser: async () => { },
  deslogar: () => { },
  persistirTermos: (status: boolean, statusCode: number) => { },
  termosDeUsoAtivo: () => false,
  getEmpresa: () => new TokenGestaoEmpresaModel(),
  refreshUser: async (refreshToken: string) => false,
  validaUsuarioConectado: () => false,
});

export interface SessaoAtualProviderProps {
  children: React.ReactNode;
}

export const useSessaoAtual = () => {
  return React.useContext(SessaoAtualContext);
};

export const SessaoAtualProvider = ({ children }: SessaoAtualProviderProps) => {
  // PROVIDERS
  const {
    getTokenFromStorage,
    convertToken,
    isTokenValid,
    persistToken,
    getTermosDeUso,
    persistTermosDeUso,
    addConnectedUser,
    removeConnectedUser,
  } = useGestaoToken();

  const { getRegistro } = useGestaoStorage();

  // STATES E REFS
  const [loadingManual, setLoadingManual] = useState(false);

  const [usuario, setarUsuario] = React.useState(getTokenFromStorage());

  // CHAMDAS API
  const { postIdentidadeAutenticar, carregando: carregandoApiAutenticar } =
    usePostIdentidadeAutenticar();
  const { postIdentidadeRefreshToken, carregando: carregandoRefreshToken } = usePostIdentidadeRefreshToken();
  const { getPoliticaValidar, carregando: carregandoTermos } = useGetPoliticaValidar();

  const { postIdentidadeIntegracao, carregando: carregandoApiIntegrcao } = usePostIdentidadeIntegracao()

  // AUX
  const loading =
    carregandoApiAutenticar ||
    carregandoApiIntegrcao ||
    loadingManual ||
    carregandoTermos ||
    carregandoRefreshToken;

  const converterToken = React.useCallback(
    (token: string): TokenGestaoModel | undefined => {
      return convertToken(token);
    },
    [convertToken],
  );

  const limparVariaveis = useCallback(() => {
    sessionStorage.clear();
  }, []);

  const deslogar = React.useCallback(
    (usuarioId: string) => {
      removeConnectedUser(usuarioId)
      persistToken(undefined);
      setarUsuario(undefined);
      limparVariaveis();
    },
    [removeConnectedUser, persistToken, limparVariaveis],
  );

  const setarUser = useCallback(
    async (token: string, manterConectado: boolean) => {
      const user = convertToken(token);
      if (user) {
        user.usuarioId = user.jti
        if (typeof user.empresa === 'string') {
          user.empresa = JSON.parse(user.empresa) as TokenGestaoEmpresaModel[];
        }
      }
      if (!isTokenValid(user))
        throw new Error(
          'O usuário informado não é válido ou expirou seu acesso. (InvalidToken)',
        );

      // limpando dados do storage
      limparVariaveis();
      //SALVO NO STORAGE
      persistToken(token);
      //SETAMOS O USUARIO
      setarUsuario(user);

      if (manterConectado) {
        if (user !== undefined) addConnectedUser(user);
      }


    },
    [addConnectedUser, convertToken, isTokenValid, limparVariaveis, persistToken],
  );

  const logar = React.useCallback(
    async (
      usuario: string,
      senha: string,
      manterConectado: boolean,
    ): Promise<void> => {
      try {
        setLoadingManual(true);
        const res = await postIdentidadeAutenticar(
          new IdentidadeAutenticarProps(usuario, senha, {
            contratoId: ''
          }),
        );

        //USUARIO NAO CONFIRMADO ESTÁ SEM AUTORIZACAO
        if (res.statusCode === 401) {
          throw new ApiError(res.statusCode, res.erro.message);
        }

        if (res.erro) {
          throw res.erro;
        }
        await setarUser(res.resultado?.data?.accessToken, manterConectado);
      } catch (e) {
        throw e;
      } finally {
        setLoadingManual(false);
      }
    },
    [postIdentidadeAutenticar, setarUser],
  );

  const getConnectedUsers = useCallback((): UsuarioConectadoModel[] => {
    let conectados: UsuarioConectadoModel[] = getRegistro(GestaoStorageKeys.UsuariosConectados, false) as UsuarioConectadoModel[];
    if (isEmpty(conectados)) {
      conectados = new Array<UsuarioConectadoModel>();
    }
    return conectados;
  }, [getRegistro]);


  const validaUsuarioConectado = useCallback(() => {
    const users = getConnectedUsers();
    return users.filter((x) => x.usuarioId === usuario?.usuarioId).length > 0;
  }, [getConnectedUsers, usuario?.usuarioId]);


  const refreshUser = React.useCallback(
    async (refreshToken: string): Promise<boolean> => {
      try {
        const retRefresh = await postIdentidadeRefreshToken(refreshToken);

        if (retRefresh?.resultado?.data?.accessToken) {
          const newuser = convertToken(
            retRefresh?.resultado?.data?.accessToken,
          );
          if (newuser) {
            newuser.usuarioId = newuser.jti
            if (typeof newuser.empresa === 'string') {
              newuser.empresa = JSON.parse(newuser.empresa) as TokenGestaoEmpresaModel[];
            }
          }
          if (newuser) {
            if (validaUsuarioConectado()) {
              addConnectedUser(newuser);
            }

            persistToken(retRefresh?.resultado?.data?.accessToken);
            await carregarDadosUsuario(newuser);
            setarUsuario(newuser);
            return true;
          }
        }
      } catch (e: any) {
        persistToken(undefined);
        persistTermosDeUso(undefined, undefined);
        setarUsuario(undefined);
        throw e;
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );


  const logarIntegracao = React.useCallback(
    async (
      id: string,
      documento: string,
    ): Promise<void> => {
      try {
        setLoadingManual(true);
        const res = await postIdentidadeIntegracao(
          new IdentidadeIntegracaoProps(id, documento),
        );

        //USUARIO NAO CONFIRMADO ESTÁ SEM AUTORIZACAO
        if (res.statusCode === 401) {
          throw new ApiError(res.statusCode, res.erro.message);
        }

        if (res.erro) {
          throw res.erro;
        }
        await setarUser(res.resultado?.data?.accessToken, false);
      } catch (e) {
        throw e;
      } finally {
        setLoadingManual(false);
      }
    },
    [postIdentidadeIntegracao, setarUser],
  );

  const persistirTermos = useCallback((status: boolean, statusCode: number) => {
    persistTermosDeUso(status, statusCode)
  }, [persistTermosDeUso])

  const termosDeUsoAtivo = useCallback(() => {
    return getTermosDeUso();
  }, [getTermosDeUso])

  const carregarDadosUsuario = React.useCallback(
    async (user: TokenGestaoModel) => {
      try {
        setLoadingManual(true);

        let termosDeUso = false;
        let statusApiTermos = 404;
        const retPolitica = await getPoliticaValidar(user?.contratoId || '');
        if (
          retPolitica.tipoRetorno === EnumRetornoApiBase.Sucesso &&
          retPolitica.statusCode === 204
        ) {
          termosDeUso = true;
          statusApiTermos = 204;
        } else {
          statusApiTermos = retPolitica.statusCode;
        }
        persistTermosDeUso(termosDeUso, statusApiTermos);

        setLoadingManual(false);

      } catch (e: any) {
        throw e;
      } finally {
      }
    },
    [getPoliticaValidar, persistTermosDeUso],
  );

  const getEmpresa = useCallback(() => {
    if (typeof usuario?.empresa === 'string') {
      return JSON.parse(usuario.empresa)[0] || new TokenGestaoEmpresaModel();
    }
    //TODO: CODIGO TEMPORÁRIO PARA CONTA DE TESTES, REMOVER DEPOIS
    if (usuario?.empresa[0].ContratoId === '19917701-3bb0-48e3-a6e4-d5618aeea858') {
      const emp = usuario.empresa.find(x => x.Id === 'f4092cbd-52ba-4424-81e5-02be747493d0');
      if (emp) {
        return emp;
      }
    }
    //
    return usuario?.empresa[0] || new TokenGestaoEmpresaModel()
  }, [usuario?.empresa])

  return (
    <SessaoAtualContext.Provider
      value={{
        usuario,
        carregando: loading,
        converterToken,
        deslogar,
        logar,
        setarUser,
        persistirTermos,
        termosDeUsoAtivo,
        logarIntegracao,
        getEmpresa,
        carregarDadosUsuario,
        refreshUser,
        validaUsuarioConectado
      }}
    >
      {children}
    </SessaoAtualContext.Provider>
  );
};
