import { useMemo } from 'react';
import * as Yup from 'yup';
import { useValidationYupDocumento, useValidationYupRazaoSocial } from '../../../form-validations'

export const useFormEmpresaValidation = () => {
    const { documentoYup } = useValidationYupDocumento(true)
    const { razaoSocialYup } = useValidationYupRazaoSocial();

    const FormEmpresaValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                cpfcnpj: documentoYup(),
                razaoSocial: razaoSocialYup(),
                nomeFantasia: Yup.string().required('O nome fantasia é obrigatório.')
            })
        )
    }, [documentoYup, razaoSocialYup])
    return {
        FormEmpresaValidationYup,
    }
}

