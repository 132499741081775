import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  Checkbox
} from '@material-ui/core';
import { useSessaoAtual } from 'services/app/providers';
import { FormLogin } from 'views/components/form/master/login/form-login';
import { LoginFormModel } from 'model/app';
import { CircularLoading } from 'views';
import { DefaultFormRefs } from 'views/components/form/utils/form-default-props';
import { LoginIcon, NovaPessoaIcon } from 'views/components/icons';
import { useToastSaurus } from 'services/app';
import { makeUtilClasses } from '../../../theme/util-make-styles';
import { ApiError } from '../../../../model/app/errors/api-erros';
import { useStyles } from './login-page-styles'
import { PublicPageHeader } from 'views/components/headers';

const LoginPage = () => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const { logar, carregando } = useSessaoAtual();
  const [bloqueado, setBloqueado] = useState(false);
  const loginRefs = useRef<DefaultFormRefs<LoginFormModel>>(null);
  const [manterConectado, setManterConectado] = useState<boolean>(false)

  useEffect(() => {
    if (loginRefs) loginRefs.current?.fillForm(new LoginFormModel('', ''));
  }, []);

  const efetuarLogin = async (model: LoginFormModel) => {
    try {
      await logar(model.email, model.senha, manterConectado);
      setBloqueado(true);
      history.push('/termos');
    } catch (e: any) {
      if (e.constructor.name === ApiError.name) {
        history.push(`/ativar-email?email=${model.email}`);
        return;
      } else {
        showToast('error', e.message);
        loginRefs.current?.resetForm();
      }
    }
  };

  return (
    <>
      <PublicPageHeader topOpacity='0.8' />
      <div className={`${utilClasses.contentCenter} ${classes.rootPadding}`}>
        <Grid container className={utilClasses.rootContainer}>
          {carregando || bloqueado ? (
            <CircularLoading tipo="FULLSIZED" />
          ) : null}
          <Grid item xs={12}>
            <Card className={classes.loginCard}>
              <Grid container direction="column">
                <Grid item xs={12} className={classes.textContainer}>
                  <Typography align="center" variant="h4" color="primary" style={{ fontWeight: 600 }}>
                    Bem vindo (a) ao sistema de gerenciamento de boletos!
                  </Typography>
                  <Typography variant='body1' color='primary' align='center'>
                    Por favor, insira seu e-mail e sua senha para seguir com o acesso.
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ width: '100%' }}>
                  <FormLogin
                    showLoading={false}
                    ref={loginRefs}
                    loading={carregando}
                    onSubmit={(model) => {
                      efetuarLogin(model);
                    }}
                  />
                  <Box my={1}>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth={true}
                        disabled={carregando || bloqueado}
                        onClick={() => {
                          loginRefs.current?.submitForm();
                        }}
                      >
                        <LoginIcon tipo="BUTTON_PRIMARY" />
                        Entrar
                      </Button>
                    </Grid>
                  </Box>
                  <Grid container>
                    <Grid item xs={6} className={classes.manterConectadoContainer}>
                      <Typography color='secondary'>
                        <Checkbox
                          className={classes.checkbox}
                          onChange={() => setManterConectado(prev => !prev)}
                          checked={manterConectado}
                          color="secondary"
                          size='medium'
                        />
                        Mantenha-me conectado.
                      </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.forgotPasswordContainer}>
                      <Typography color='secondary' className={classes.forgotPassword} onClick={() => {
                        history.push('/recuperar-senha')
                      }}>
                        Esqueci minha senha.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.cadastroButton}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    size="large"
                    fullWidth={true}
                    disabled={carregando || bloqueado}
                    onClick={() => {
                      history.push('/cadastro')
                    }}
                  >
                    <NovaPessoaIcon tipo="BUTTON" />
                    Cadastre-se
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LoginPage;
