import { useValidationYupDocumento } from 'views/components/form-validations';
import * as Yup from 'yup'

export const useFormContaBancariaValidation = () => {
    const { documentoYup } = useValidationYupDocumento(false);
    const FormContaBancariaValidation = Yup.object().shape({
        agencia: Yup.number().required('Informa a agência').typeError('Informe apenas os números'),
        digitoAgencia: Yup.number().required('Informe o Digito').typeError('Informe apenas o Digito'),
        conta: Yup.number().required('Informe a Conta').typeError('Informe apenas os números'),
        digitoConta: Yup.number().required('Informe o Digito da Conta').typeError('Informe apenas o digito'),
        nomeTitular: Yup.string().required('Informe o Nome do Titular'),
        documentoTitular: documentoYup().required('Informe o Documento do Titular')
    });

    return {
        FormContaBancariaValidation
    }
}