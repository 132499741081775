import { LoteListModel } from "model/api/gestao/lote/lote-list-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardLote } from "views/components/cards/card-lote/card-lote";
import { InformacaoIcon } from "views/components/icons";

interface RegistroListDataProps {
    list: LoteListModel[];
    carregando: boolean;
    onCardClick: (model: LoteListModel) => void;
}

export const RegistroListData = ({
    list,
    carregando,
    onCardClick
}: RegistroListDataProps) => {


    return (
        <>
            {(list.length === 0 && !carregando) && (
                <CardNaoEncontrado
                    icon={<InformacaoIcon tipo='GERAL' />}
                    mensagem="Nenhuma Remessa foi encontrada."
                />
            )}
            {list.length > 0 && (
                list.map(lote => (
                    <CardLote
                        model={lote}
                        onClick={onCardClick}
                        hideDetalhes
                    />
                ))
            )}
        </>
    )
}