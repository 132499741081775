import { BoletoListModel } from "model/api/gestao/boleto/boleto-list-model";
import { useStyles } from "./info-boletos-styles"
import { Box, Button, Theme, Tooltip, Typography } from '@material-ui/core'
import { StatusBoletoMock } from "data/mocks/status-boleto-mock";
import { useMemo, useState } from "react";
import { EnumStatusBoleto } from "model/enums/enum-status-boleto";
import classNames from "classnames";
import { useThemeQueries } from "views/theme";
import { toCurrency } from "utils/toCurrency";
import { ExpandIcon } from "views/components/icons";

const retornarCorStatus = (situacao: EnumStatusBoleto, theme: Theme): string => {
    switch (situacao) {
        case EnumStatusBoleto.Aceito:
            return theme.palette.success.light;
        case EnumStatusBoleto.Abortada:
            return theme.palette.error.light;
        case EnumStatusBoleto.ErroInterno:
            return theme.palette.error.main;
        case EnumStatusBoleto.Autorizada:
            return theme.palette.success.main;
        case EnumStatusBoleto.Pendente:
            return theme.palette.warning.light;
        case EnumStatusBoleto.Rejeitada:
            return theme.palette.error.dark;
        case EnumStatusBoleto.ProcessandoEstorno:
            return theme.palette.info.main;
        case EnumStatusBoleto.Estornada:
            return theme.palette.warning.dark;
        case EnumStatusBoleto.Expirada:
            return theme.palette.warning.main;
        default:
            return theme.palette.info.light;
    }
}


interface InfoBoletosProps {
    boletos: BoletoListModel[];
    children: React.ReactNode;
}

export const InfoBoletos = ({ boletos, children }: InfoBoletosProps) => {

    const { theme, isMobile } = useThemeQueries();
    const [hide, setHide] = useState<boolean>(isMobile)

    const classes = useStyles({
        hide
    });

    const returnStatusInfo = useMemo(() => {
        const items: JSX.Element[] = StatusBoletoMock.filter(status => status.Key !== EnumStatusBoleto.Todos).map((status) => {
            const boletosStatus = boletos.filter(boleto => boleto.status === status.Key)

            const valorTotal = boletosStatus.reduce<number>((prev, curr) => prev + curr.valorTitulo, 0)
            const count = boletosStatus.length
            return (
                <Box className={classes.itemInfo}>
                    <Tooltip arrow title={status.Value} placement="right">
                        <Box
                            bgcolor={retornarCorStatus(status.Key, theme)}
                            className={classNames(classes.tagStatus)}
                        ></Box>
                    </Tooltip>
                    <Box className={classes.textContainer}>
                        <Typography variant='caption' color='textSecondary'>
                            {status.Value}s: <span className={classes.textValor}>{count}</span>
                        </Typography>
                        <Typography variant='caption' color='textSecondary'>
                            <span className={classes.textValor}>{toCurrency(valorTotal)}</span>
                        </Typography>
                    </Box>
                </Box>
            )
        })

        return items
    }, [boletos, classes.itemInfo, classes.tagStatus, classes.textContainer, classes.textValor, theme])



    return (
        <Box className={classes.listBottomCard}>
            <Button variant='outlined'
                color='primary'
                className={classes.showBtn}
                fullWidth
                onClick={() => setHide(prev => !prev)}
            >
                <ExpandIcon tipo='BUTTON' />
                {hide ? 'Mostrar Status' : 'Esconder Status'}
            </Button>
            <Box className={classes.items}>
                {returnStatusInfo}
            </Box>
            {children}
        </Box>
    )
}