import { Route, Switch } from 'react-router-dom';
import LogoutPage from 'views/pages/public/logout/logout-page';
import LoginPage from 'views/pages/public/login/login-page';
import RouterMain from './components/router-main/router-main';
import { ExpiredTokenRoute } from './components/expired-token-route';
import { useMemo } from 'react';
import { ValidateRoute } from './components/validate-route';
import NovoContratoPage from 'views/pages/public/novo-contrato/novo-contrato-page';
import { RecuperarSenhaPage } from 'views/pages/public/recuperar-senha/recuperar-senha-page';
import RecuperarSenhaRetornoPage from 'views/pages/public/recuperar-senha-retorno/recuperar-senha-retorno-page';
import PoliticaPage from 'views/pages/private/politica/politica-page';

export const RouterApp = () => {
  const switchApp = useMemo(() =>
    <Switch>
      <Route exact path="/logout" component={LogoutPage} />
      <Route
        exact
        path="/login/:parametro?"
        component={LoginPage}
      />
      <Route
        exact
        path="/cadastro"
        component={NovoContratoPage}
      />
      <Route
        exact
        path="/recuperar-senha"
        component={RecuperarSenhaPage}
      />
      <Route
        exact
        path="/recuperar-senha/retorno"
        component={RecuperarSenhaRetornoPage}
      />
      <Route exact path="/termos" component={PoliticaPage}/>
      <ValidateRoute>
        <ExpiredTokenRoute>
          <RouterMain />
        </ExpiredTokenRoute>
      </ValidateRoute>
    </Switch>
    , []);
  return (
    <>
      {switchApp}
    </>
  );
};
