import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/use-cases/events/event-tools";
import { AppEventEnum } from "model/enums/enum-event-app";
import { DialogContaBancaria } from "views/components/dialog/dialog-conta-bancaria/dialog-conta-bancaria";
import { useLocation } from "react-router-dom";

class ContaBancariaDialogProps {
    constructor(
        public aberto: boolean = false,
        public id?: string,
    ) { }
}

const contaRoute = `/conta-bancaria/`;
const configRoute = `/configuracoes`

export const ContaBancariaDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<ContaBancariaDialogProps>(
        new ContaBancariaDialogProps()
    );

    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes(contaRoute) && location.pathname.includes(configRoute)) {
            const posrota = location.pathname.split(contaRoute)[1];
            const id = posrota
            if (posrota.length > 0) {
                setModalState(prev => ({
                    aberto: true,
                    id: id && id.length === 36 ? id : prev.id
                }));
                return;
            }
        }
    }, [location.pathname]);

    const modalAlterado = useCallback(({ aberto, id }: ContaBancariaDialogProps) => {
        setModalState({
            aberto,
            id,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogContaBancaria, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogContaBancaria, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogContaBancaria
                    aberto={modalState.aberto}
                    id={modalState.id}
                />
            )}
        </>
    )
}