import { Theme, makeStyles } from "@material-ui/core";
import { EnumArquivoRegistro } from "model/enums/enum-arquivo-registro";

interface StylesProps{
    openned: boolean;
    padding: 'small' | 'large';
    selectedPage?: EnumArquivoRegistro;
}

export const useSearchBarStyles = makeStyles<Theme, StylesProps>((theme) => ({
    bar: {
        width: '100%',
        padding: ({padding}) => {
            if(padding === 'large'){
                return theme.spacing(3)
            }
            return `${theme.spacing(2)}px ${theme.spacing(3)}px`
        },
        background: theme.palette.background.paper,
        boxShadow: '0px 4px 4px 0px #00000040',
        display: ({openned}) => openned ? 'flex': 'none',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    form: {
        display: 'flex',
        gap: theme.spacing(4),
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    closeOpenBar: {
        background: theme.palette.primary.main,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1),
        gap: theme.spacing(1),
        color: theme.palette.primary.contrastText,
        cursor: 'pointer',
        '& svg': {
            fill: theme.palette.primary.contrastText,
            height: 20,
            transform: ({openned}) => `rotate(${openned ? 180 : 0}deg)`
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }

    },
    textfield: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTextField-root, .MuiAutocomplete-root': {
            height: 40,
            flexBasis: '100%'
        }
    },
    registroContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    btn: {
        height: 40
    },
    btnNovaRemessa: {
        [theme.breakpoints.up('md')]: {
            width: 350
        }
    },
    btnSwitchContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexGrow: ({selectedPage}) => selectedPage !== EnumArquivoRegistro.Boleto ? 1 : 'unset',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    formGrid: {
        flex: ({selectedPage}) => selectedPage !== EnumArquivoRegistro.Boleto ? 1 : 'unset',
    }
}));