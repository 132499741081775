import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetPoliticaPendente() {

    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPoliticaPendente = useCallback(
        (contratoId: string) => invocarApi({
            url: `${VariaveisAmbiente.apiVersion}/termo-privacidade/${contratoId}/pendentes`,
            method: 'GET',
            enviarTokenUsuario: true
        }),
        [invocarApi],
    );

    return {
        ...outrasPropriedades,
        getPoliticaPendente,
    };

}
