import { DefaultCard, useDefaultCardStyles } from "../components/default-card";
import { Grid, Typography, Button, Box } from '@material-ui/core'
import { toCurrency } from "utils/toCurrency";
import { useStyles } from "./card-lote-styles";
import { LoteIcon } from "views/components/icons/lote-icon";
import { LoteListModel } from "model/api/gestao/lote/lote-list-model";
import { DownloadIcon } from "views/components/icons/download-icon";
import { useCadastros } from "services/app/hooks/cadastros";
import { toDateString } from "utils/to-date";
import { DividerSaurus } from "views/components/divider/divider-saurus";
import { ReloadIcon } from "views/components/icons/reload-icon";
import { AddReloadIcon } from "views/components/icons/add-reload-icon";
import { RoundConfirmIcon } from "views/components/icons/aceito-icon";
import { CancelarIcon } from "views/components/icons";
import { DetalhesLote } from "views/components/dialog/dialog-detalhes-lote/dialog-detalhes-lote";

interface CardFaturaProps {
    model: LoteListModel;
    onClick: (model: LoteListModel) => void;
    hideDetalhes?: boolean;
    qtdBoletos? : DetalhesLote;
}

export const CardLote = ({
    model,
    onClick,
    hideDetalhes,
    qtdBoletos
}: CardFaturaProps) => {
    const classes = useDefaultCardStyles({});
    const cardClasses = useStyles({
        expanded: false
    });
    const { abrirDialogDetalhesLote } = useCadastros();

    return (
        <>
            <DefaultCard
                onClick={() => {
                    abrirDialogDetalhesLote(model)
                }}
            >
                <Box className={cardClasses.iconContainer}>
                    <LoteIcon tipo='GERAL' class={cardClasses.icon} />
                </Box>
                <div className={`${classes.cardContent} ${cardClasses.container}`}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                            <Typography color="textSecondary" variant="caption">
                                Nome do Arquivo
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            >
                                {model.nomeArquivo}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography color="textSecondary" variant="caption">
                                Data de Criação do Lote
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {toDateString(model.dataGeracao)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2} lg={3}>
                            <Typography color="textSecondary" variant="caption">
                                Boletos concluídos/Totais
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >

                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2} lg={1}>
                            <Typography color="textSecondary" variant="caption">
                                Quantidade de Boletos
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {toCurrency(model.qteBoletos)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} className={cardClasses.finalGrid}>
                            <Button variant='outlined' color='primary' className={cardClasses.downloadButton} onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                <DownloadIcon tipo='BUTTON' />
                                Baixar lote de Boletos
                            </Button>
                        </Grid>
                        {!hideDetalhes && qtdBoletos && <>
                            <Grid item xs={12}>
                                <DividerSaurus />
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography color="textPrimary" variant="caption">
                                    Boletos Pendentes no Lote
                                </Typography>
                                <Box className={cardClasses.expandedItem}>
                                    <ReloadIcon class={cardClasses.pendenteIcon} />
                                    {qtdBoletos?.pendentes} Boletos
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography color="textPrimary" variant="caption">
                                    Boletos Aceitos no Lote
                                </Typography>
                                <Box className={cardClasses.expandedItem}>
                                    <AddReloadIcon class={cardClasses.aceitoIcon} />
                                    {qtdBoletos?.aceitos} Boletos
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography color="textPrimary" variant="caption">
                                    Boletos Pagos no Lote
                                </Typography>
                                <Box className={cardClasses.expandedItem}>
                                    <RoundConfirmIcon class={cardClasses.pagoIcon} />
                                    {qtdBoletos?.pagos} Boletos
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography color="textPrimary" variant="caption">
                                    Boletos Rejeitados no Lote
                                </Typography>
                                <Box className={cardClasses.expandedItem}>
                                    <CancelarIcon class={cardClasses.rejeitadoIcon} />
                                    {qtdBoletos?.cancelados} Boletos
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography color="textPrimary" variant="caption">
                                    Boletos Cancelados no Lote
                                </Typography>
                                <Box className={cardClasses.expandedItem}>
                                    <CancelarIcon class={cardClasses.canceladoIcon} />
                                    {qtdBoletos?.cancelados} Boletos
                                </Box>
                            </Grid>
                        </>}
                    </Grid>
                </div>
                {/* <Tooltip arrow title="Editar Fatura">
          <div className={classes.cardRightButton} style={{ margin: '0px 0px 0px 10px' }}>
            <EditarIcon tipo="BUTTON" fill={theme.palette.primary.main} />
          </div>
        </Tooltip> */}
            </DefaultCard>
        </>
    );
};