import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ContaBancariaListModel } from 'model/api/gestao/conta-bancaria/conta-bancaria-list-model';
import { ApiListModel } from 'model/api/api-list-model/api-list-model';
import { useSessaoAtual } from 'services/app';


export function useGetContasBancarias() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<ContaBancariaListModel>>();
    const { getEmpresa } = useSessaoAtual();

    const empresaId = getEmpresa().Id;

    const getContasBancarias = useCallback(
        //page size 0 é sem paginação
        (page: number = 1, pageSize: number = 10) =>
            invocarApi({
                method: 'GET',
                url: `/${VariaveisAmbiente.apiVersion}/empresa/${empresaId}/conta-bancaria?PageSize=${pageSize}&Page=${page}`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            }),
        [empresaId, invocarApi],
    );
    return {
        ...outrasPropriedades,
        getContasBancarias,
    };
}