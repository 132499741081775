import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    imgTitulo: {
        width: '36px',
        height: '36px',
        marginBottom: -6,
        marginRight: 6,
        fill: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            width: '36px',
            height: '36px',
            marginBottom: -6,
        },
        [theme.breakpoints.up('md')]: {
            width: '40px',
            height: '40px',
            marginBottom: -6,

        },
        [theme.breakpoints.up('lg')]: {
            width: '50px',
            height: '50px',
            marginBottom: -8,
        }
    },
    logo: {
        position: 'absolute',
        top: '30px',
        right: '25px',
        width: 341,
        height: 62,
        [theme.breakpoints.down('md')]: {
            right: 0,
            left: 0,
            marginRight: 'auto',
            marginLeft: 'auto',
            top: '5px'
        },
        '@media (max-height: 700px)': {
            display: 'none'
        }
    },
    loginCard: {
        boxShadow: '0px 52.9835px 137.757px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '52px 41px',
        borderRadius: '26.4918px',
        zIndex: 3,
        [theme.breakpoints.down('sm')]: {
            padding: '32px 21px'
        },
        '& h4': {
            fontWeight: 500
        }
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            '& h4': {
                fontSize: '1.8rem'
            }
        }
    },
    rootPadding: {
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    forgotPassword: {
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    forgotPasswordContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            '& p': {
                textAlign: 'center'
            }
        }
    },
    manterConectadoContainer: {
        display: 'flex',
        aligmItems: 'center',
        [theme.breakpoints.down('sm')]: {
            '& p': {
                textAlign: 'center'
            }
        }
    },
    checkbox: {
        paddingLeft: 0,
        '& svg': {
            height: 30,
            width: 30,
        }
    },
    cadastroButton: {
        marginTop: theme.spacing(2)
    }
}));