import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    container: {
        paddingBottom: theme.spacing(2)
    },
    infoContainer: {
        [theme.breakpoints.down('sm')]: {
            border: 'none'
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));