import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetUsuarioValidarCadastro() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getUsuarioValidarCadastro = useCallback(
        (email: string, telefone: string, apelido: string) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/usuario/existe?email=${email}&telefone=${telefone}&apelido=${apelido}`,
                method: 'GET',
                enviarTokenUsuario: false,
            })
        },
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getUsuarioValidarCadastro,
    };
}