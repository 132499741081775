export function toCurrency(value: any): string {
  try {
    if(value === undefined || value === null || value === ''){
      throw new Error('')
    }
    const ret = value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    return ret;
  } catch (e) {
    return 'NaN'
  }
}
