import { useCallback, useEffect, useState } from "react";
import { useDefaultPageStyles } from "views/theme/pages-styles"
import { InicialPageSearchProps } from "./components/inicial-page-search/inicial-page-search-props";
import { InicialPageSearchBar } from "./components/inicial-page-search/inicial-page-search-bar";
import { EnumArquivoRegistro } from "model/enums/enum-arquivo-registro";
import { useHistory, useLocation } from "react-router-dom";
import { ArquivoList } from "./components/arquivo-list/arquivo-list";
import { RegistroList } from "./components/registro-list/registro-list";
import { HistoricoList } from "./components/historico-list/historico-list";

export const InicialPage = () => {
    const classes = useDefaultPageStyles();

    const { pathname } = useLocation();
    const { push } = useHistory();

    const [searchProps, setSearchProps] = useState<InicialPageSearchProps>(new InicialPageSearchProps())
    const [selectedPage, setSelectedPage] = useState<EnumArquivoRegistro>(
        pathname.includes('remessa') ? EnumArquivoRegistro.Remessa : pathname.includes('historico') ? EnumArquivoRegistro.Historico : EnumArquivoRegistro.Boleto
    )

    const switchPage = useCallback(() => {
        switch (selectedPage) {
            case EnumArquivoRegistro.Boleto:
                return <ArquivoList searchProps={searchProps} />
            case EnumArquivoRegistro.Historico:
                return <HistoricoList searchProps={searchProps} />
            default:
                return <RegistroList searchProps={searchProps} />
        }
    }, [searchProps, selectedPage])

    useEffect(() => {
        if(pathname === '/inicial'){
            push('/inicial/boletos')
        }
    }, [pathname, push])

    return (
        <div className={classes.root}>
            <InicialPageSearchBar setSearchProps={setSearchProps}
                setSelectedPage={setSelectedPage}
                selectedPage={selectedPage} />
            {switchPage()}
        </div>
    )
}