import { TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { useRef } from 'react';

const moveFocus = (refs: any, index: number) => {
  setTimeout(() => {
    refs.current[index].focus();
  }, 1);
};

export const InputPin = (props: any) => {
  const { lenght, helperText, onChange, onSubmit, ...others } = props;
  const inputRefs = useRef(new Array<HTMLInputElement>());

  const retValue = (): string => {
    let aux = '';
    for (let i = 0; i < inputRefs.current.length; i++) {
      aux += inputRefs.current[i].value;
    }
    return aux;
  };
  const atualizarValue = () => {
    onChange(others.name, retValue());
  };

  const atualizaDisabled = () => {
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (i > 0) {
        if (inputRefs.current[i - 1].value === '') {
          inputRefs.current[i].disabled = true;
        } else {
          inputRefs.current[i].disabled = false;
        }
      } else {
        if (inputRefs.current[i].value === '') {
          inputRefs.current[i + 1].disabled = true;
        } else {
          inputRefs.current[i + 1].disabled = false;
        }
      }
    }
  };

  const renderInputList = () => {
    let inputList: Array<any> = [];
    for (let i = 0; i < props.lenght; i++) {
      inputList.push(
        <TextField
          autoComplete={'off'}
          variant="outlined"
          fullWidth
          disabled={true}
          inputRef={(el: HTMLInputElement) => {
            if (el) {
              inputRefs.current[i] = el;
            }
          }}
          inputProps={{
            style: {
              textAlign: 'center',
            },
          }}
          onKeyUp={(e: any) => {
            atualizaDisabled();
            if (e.target.value !== '') {
              if (i < inputRefs.current.length - 1) moveFocus(inputRefs, i + 1);
            } else {
              if (i > 0 && e.which === 8) moveFocus(inputRefs, i - 1);
            }
            atualizarValue();
          }}
          onPaste={(e) => {
            let clipBoarText = e.clipboardData.getData('Text');
            for (let i = 0; i < inputRefs.current.length; i++) {
              if (clipBoarText[i] !== undefined) {
                inputRefs.current[i].disabled = false;
                inputRefs.current[i].value = clipBoarText[i];
              } else {
                inputRefs.current[i].focus();
              }
            }
            atualizaDisabled();
            atualizarValue();
            moveFocus(inputRefs, retValue().length - 1);
          }}
          onKeyPress={(e) => {
            if (e.which === 13) {
              if (onSubmit !== undefined) onSubmit();
            }
          }}
          onChange={(e) => {
            if (e.target.value.length > 0) {
              e.target.value = e.target.value[0].toUpperCase();
              if (i < props.length - 1) {
                e.target.value = e.target.value[1 + 1].toUpperCase();
              }
            } else {
              e.target.value = e.target.value.toUpperCase();
            }
          }}
          {...others}
        />,
      );
    }
    return inputList;
  };

  useEffect(() => {
    atualizaDisabled();
  }, []);

  useEffect(() => {
    if (props.canFocus === true) {
      inputRefs.current[0].focus();
    }
  }, [props.canFocus]);

  return (
    <>
      <div style={{ display: 'flex', maxWidth: '100%' }}>
        {renderInputList().map((input, index) => {
          return (
            <div
              style={{
                maxWidth: '100%',
                marginRight:
                  index === renderInputList().length - 1 ? '0px' : '10px',
              }}
              key={index}
            >
              {input}
            </div>
          );
        })}
      </div>
    </>
  );
};
