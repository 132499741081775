export enum EnumStatusBoleto {
    SemRemessa = '0',
    Pendente = '1',
    Aceito = '2',
    Autorizada = '3',
    Rejeitada = '4',
    Expirada = '5',
    ProcessandoEstorno = '6',
    Estornada = '7',
    Abortada = '8',
    Todos = '9',
    ErroInterno = '99'
}