import { useCadastros } from "services/app/hooks/cadastros";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { Box, Tooltip } from "@material-ui/core";
import { useStyles } from './dialog-boleto-styles'
import { useCallback, useEffect, useRef, useState } from "react";
import { DefaultFormRefs } from "views/components/form/utils";
import { BoletoFormModel } from "model/api/gestao/boleto/boleto-add-form-model";
import { CadastroBoleto } from "./components/cadastro-boleto/cadastro-boleto";
import { VisualizacaoBoleto } from "./components/visualizacao-boleto/visualizacao-boleto";
import { ImpressoraIcon } from "views/components/icons/impressora-icon";
import { ButtonIcon } from "views/components/controles";
import { ContaBancariaConfiguracaoModel } from "model/api/gestao/conta-bancaria-configuracao/conta-bancaria-configuracao-model";
import { useGetBoletoById } from "data/api/gestao/boleto/get-boleto.by-id";
import { useThemeQueries } from "views/theme";
import { useGetBoletoImprimir } from "data/api/gestao/boleto/get-boleto-imprimir";
import { useShowAviso } from "services/app/hooks/show-aviso";
import { StatusBoletoMock } from "data/mocks/status-boleto-mock";
import { EnumStatusBoleto } from "model/enums/enum-status-boleto";
import { guidEmpty } from "utils/guid-empty";
import { BoletoGetModel } from "model/api/gestao/boleto/boleto-get-model";
import { picker } from "utils/picker";



interface DialogBoletoProps {
    aberto: boolean;
    id?: string;
}

export interface BoletoFormRef extends DefaultFormRefs<BoletoFormModel> {
    carregarDadosContaBancaria: (model: ContaBancariaConfiguracaoModel) => void;
}

export const DialogBoleto = ({
    aberto,
    id,
}: DialogBoletoProps) => {

    const { fecharDialogBoleto } = useCadastros();
    const { showAviso } = useShowAviso();
    const { isMobile } = useThemeQueries();

    const { getBoletoById, carregando: carregandoGet } = useGetBoletoById();
    const { getBoletoImprimir, carregando: carregandoImprimir } = useGetBoletoImprimir();

    const carregando = carregandoGet || carregandoImprimir

    const [boleto, setBoleto] = useState<BoletoGetModel>(new BoletoGetModel())

    const classes = useStyles({ status: boleto.status.toString() as EnumStatusBoleto });

    const formRef = useRef<BoletoFormRef>(null)

    const getBoletoWrapper = useCallback(async (id: string) => {
        try {
            const res = await getBoletoById(id);

            if (res.erro) throw res.erro
            if (!res.resultado?.data) return

            const data = res.resultado.data;

            if(data.dataJurosMora === '0001-01-01T00:00:00'){
                data.dataJurosMora = null;
            }

            setBoleto(res.resultado.data)
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [getBoletoById, showAviso])

    const handleImprimir = useCallback(async () => {
        try {
            const res = await getBoletoImprimir(id || '');

            if (res.erro) throw res.erro

            window.open(res.resultado?.data.url, '_blank')?.print();
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [getBoletoImprimir, id, showAviso])

    useEffect(() => {
        if (id) {
            const boletoFormModel = picker<BoletoFormModel>(boleto, new BoletoFormModel());
            formRef.current?.fillForm(boletoFormModel)
            if (boleto.status.toString() === EnumStatusBoleto.Rejeitada) {
                const mensagemErro = boleto.historico.list[0].ocorrencia;
                showAviso('warning', mensagemErro, undefined, true)
            }
        }
    }, [boleto, id, showAviso])

    useEffect(() => {
        if (id)
            getBoletoWrapper(id);
    }, [getBoletoWrapper, id])


    return <DialogSaurus
        titulo={id ? 'Editar Boleto' : 'Adição de Boleto Manual'}
        tamanho='md'
        isDivider
        isButtonTitleClose
        aberto={aberto}
        fullScreen={isMobile}
        handleClickClose={fecharDialogBoleto}
        endAdornment={id ?
            <Box className={classes.header}>
                {(boleto.id !== guidEmpty()) && <Box className={classes.badgeStatus}>
                    {StatusBoletoMock.find(x => x.Key === boleto.status.toString())?.Value || 'Sem Status'}
                </Box>}
                <Tooltip title='Imprimir'>
                    <Box>
                        <ButtonIcon
                            icon={<ImpressoraIcon tipo='BUTTON' />}
                            onClick={() => {
                                handleImprimir()
                            }}
                        />
                    </Box>
                </Tooltip>
            </Box>
            : undefined}
    >
        {!id && <CadastroBoleto formRef={formRef} carregando={carregando} />}
        {id && <VisualizacaoBoleto formRef={formRef} carregando={carregando} id={id} boleto={boleto} />}
    </DialogSaurus>
}