import { BoletoListModel } from "model/api/gestao/boleto/boleto-list-model";
import { DefaultCard, useDefaultCardStyles } from "../components/default-card";
import { useStyles } from "./card-boleto-styles";
import { StatusBoletoMock } from "data/mocks/status-boleto-mock";
import { Tooltip, Grid, Typography, Box } from '@material-ui/core'
import { toDateString } from "utils/to-date";
import classNames from "classnames";
import { ButtonIcon } from "views/components/controles";
import { ImpressoraIcon } from "views/components/icons/impressora-icon";
import { toCurrency } from "utils/toCurrency";
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";

interface CardBoletoProps {
    model: BoletoListModel;
    onClick: (model: BoletoListModel) => void;
    onClickImprimir: (id: string) => void;
}

// 

// const DialogCardMobile = ({
//     handleClose,
//     model,
//     onClickHistorico,
//     onClickImprimir,
//     onClickVisualizar
// }: Dialinterface DialogCardMobileProps {
//     handleClose: () => void;
//     model: BoletoListModel;
//     onClickVisualizar: (model: BoletoListModel) => void;
//     onClickImprimir: (model: BoletoListModel) => void;
//     onClickHistorico: (model: BoletoListModel) => void;
// }ogCardMobileProps) => {

//     const classes = useStyles({
//         status: model.status
//     });

//     return (
//         <DialogSaurus
//             aberto
//             isButtonTitleClose
//             handleClickClose={handleClose}
//         >
//             <Grid container spacing={2} className={classes.dialogSelecionarMobile}>
//                 <Grid item xs={6}><Button fullWidth variant='contained' color='primary' onClick={() => {
//                     onClickImprimir(model)
//                     handleClose()
//                 }}>
//                     <ImpressoraIcon tipo='BUTTON_PRIMARY' />
//                     Imprimir
//                 </Button>
//                 </Grid>
//                 <Grid item xs={6}>
//                     <Button fullWidth variant='contained' color='primary' onClick={() => {
//                         onClickVisualizar(model)
//                         handleClose()
//                     }}>
//                         <VisualizarBoletoIcon tipo='BUTTON_PRIMARY' />
//                         Visualizar Boleto
//                     </Button>
//                 </Grid>
//             </Grid>
//         </DialogSaurus>
//     )
// }

export const CardBoleto = ({
    model,
    onClick,
    onClickImprimir,
}: CardBoletoProps) => {
    const classes = useDefaultCardStyles({});
    const cardClasses = useStyles({
        status: model.status
    });

    const descricao = StatusBoletoMock.filter(
        (x) => {
            return x.Key === model.status
        },
    )[0]?.Value || '';


    return (
        <>
            <DefaultCard onClick={() => {
                onClick(model)
            }}>
                <Tooltip arrow title={descricao} placement="right">
                    <div
                        className={classNames(classes.tagStatus, cardClasses.tagColor)}
                    ></div>
                </Tooltip>
                <div className={`${classes.cardContent} ${cardClasses.container}`}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={2}>
                            <Typography color="textSecondary" variant="caption">
                                Número do Boleto
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            >
                                {model.nossoNumero}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textSecondary" variant="caption">
                                Data Emissão
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {toDateString(model.dataEmissao)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textSecondary" variant="caption">
                                Data de Vencimento
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {toDateString(model.dataVencimento)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2} className={cardClasses.data}>
                            <Typography color="textSecondary" variant="caption">
                                Valor
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {toCurrency(model.valorTitulo)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography color="textSecondary" variant="caption">
                                CPF/CNPJ: {formatarCPFCNPJ(model.documentoPagador)}
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.nomePagador}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Box className={cardClasses.iconsContainer}>
                    <Tooltip title='Imprimir'>
                        <Box>
                            <ButtonIcon
                                icon={<ImpressoraIcon tipo='BUTTON' />}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onClickImprimir(model.id)
                                }}
                            />
                        </Box>
                    </Tooltip>
                </Box>
            </DefaultCard>
        </>
    );
};