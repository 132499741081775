import { Box, Button, Grid, Typography } from '@material-ui/core';

import {
    useImperativeHandle,
    forwardRef,
} from 'react';
import { CircularLoading, makeUtilClasses } from 'views';
import {
    DefaultFormRefs,
    DefaultFormProps,
} from '../../utils/form-default-props';
import { ContaBancariaFormModel } from 'model/app/forms/master/conta-bancaria-form';
import { useForm, Controller } from 'react-hook-form'
import { EnumBanco } from 'model/enums/enum-banco';
import { BancoMock } from 'data/mocks/banco-mock';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useFormContaBancariaValidation } from './form-conta-bancaria-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { SwitchSaurus } from 'views/components/controles/switches/switch-saurus';
import {useStyles} from './form-conta-bancaria-styles';
import { DividerSaurus } from 'views/components/divider/divider-saurus';
import { stringNumeros } from 'utils/string-numeros';

interface FormContaBancariaProps extends DefaultFormProps<ContaBancariaFormModel> {
    setBanco: React.Dispatch<React.SetStateAction<EnumBanco>>;
    isCadastro?: boolean;
}


export const FormContaBancaria = forwardRef<
    DefaultFormRefs<ContaBancariaFormModel>,
    FormContaBancariaProps
>((props: FormContaBancariaProps, refs) => {

    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const { FormContaBancariaValidation } = useFormContaBancariaValidation();

    const {
        handleSubmit,
        reset,
        setValue,
        control,
        formState: { errors }
    } = useForm<ContaBancariaFormModel>({
        defaultValues: { ...new ContaBancariaFormModel() },
        criteriaMode: 'all',
        mode: 'onBlur',
        resolver: yupResolver(FormContaBancariaValidation)
    });

    const onSubmit = (form: ContaBancariaFormModel) => {
        props.onSubmit(form);
    };

    useImperativeHandle(refs, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: ContaBancariaFormModel) => {
            reset({ ...model });
        },
    }));

    return (
        <>
            <div className={utilClasses.formContainer}>
                {(props.loading && props.showLoading) ? (
                    <CircularLoading tipo="FULLSIZED" />
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={`${props.loading ? utilClasses.controlLoading : ''}`}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name='banco'
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        conteudo={BancoMock}
                                        disabled={props.loading}
                                        label='Banco'
                                        variant="outlined"
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.banco)}
                                        helperText={Boolean(errors.banco?.message) ? errors.banco?.message : undefined}
                                        onChange={(e: any) => {
                                            setValue('banco', e.target.value)
                                            props.setBanco(e.target.value)
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name='agencia'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Agência'
                                        placeholder='Ex: 6874'
                                        tipo='NUMERO'
                                        fullWidth
                                        error={Boolean(errors.agencia)}
                                        helperText={Boolean(errors.agencia?.message) ? errors.agencia?.message : undefined}
                                        {...field}
                                        onChange={(e: any) => {
                                            const value = (e.target.value as string).split('').reduce((prev, curr) => curr === '0' && prev === '' ? '' : prev + curr ,'')
                                            e.target.value = value;
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name='digitoAgencia'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Digito Agência'
                                        placeholder='Ex: 7'
                                        tipo='NUMERO'
                                        fullWidth
                                        limite={1}
                                        error={Boolean(errors.digitoAgencia)}
                                        helperText={Boolean(errors.digitoAgencia?.message) ? errors.digitoAgencia?.message : undefined}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Controller
                                name='conta'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Conta'
                                        placeholder='Ex: 27895'
                                        tipo='NUMERO'
                                        fullWidth
                                        error={Boolean(errors.conta)}
                                        helperText={Boolean(errors.conta?.message) ? errors.conta?.message : undefined}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name='digitoConta'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Digito'
                                        placeholder='Ex: 1'
                                        tipo='NUMERO'
                                        max={1}
                                        fullWidth
                                        error={Boolean(errors.digitoConta)}
                                        helperText={Boolean(errors.digitoConta?.message) ? errors.digitoConta?.message : undefined}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='nomeTitular'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Titular da Conta'
                                        placeholder='Ex: Felipe Borges'
                                        fullWidth
                                        error={Boolean(errors.nomeTitular)}
                                        helperText={Boolean(errors.nomeTitular?.message) ? errors.nomeTitular?.message : undefined}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='documentoTitular'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Documento do Titular'
                                        tipo='CNPJ_CPF'
                                        placeholder='CPF/CNPJ'
                                        fullWidth
                                        error={Boolean(errors.documentoTitular)}
                                        helperText={Boolean(errors.documentoTitular?.message) ? errors.documentoTitular?.message : undefined}
                                        {...field}
                                        onChange={(e: any) => {
                                            e.target.value = stringNumeros(e.target.value);
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.switchContainer}>
                            <Controller
                                name='padrao'
                                control={control}
                                render={({ field }) => (
                                    <Box>
                                        <Typography variant='caption'>Conta Bancária Padrão</Typography>
                                        <SwitchSaurus
                                        variant='LIGHT'
                                        size='small'
                                        checked={field.value}
                                        onChange={() => setValue('padrao', !field.value)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                    </Box>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DividerSaurus color='secondary' height={1}/>
                        </Grid>
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </Grid>
                </form>
            </div>
        </>
    );
});