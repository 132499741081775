import { useEffect, useState, useCallback } from 'react';
import parse from 'html-react-parser';
import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { CircularLoading, makeUtilClasses } from 'views';
import { useStyles } from './politica-page-styles';
import { useToastSaurus } from 'services/app';
import { CancelarIcon } from '../../../components/icons/cancelar-icon';
import { TermosIcon } from '../../../components/icons/termos-icon';
import { useConfirm } from 'material-ui-confirm';
import { useHistory } from 'react-router';
import { useSessaoAtual } from '../../../../services/app/providers/sessao-atual-provider/sessao-atual-provider';
import { isEmpty } from 'lodash';
import { AvancarIcon } from '../../../components/icons/avancar-icon';
import { PoliticaModel } from 'model/api/gestao/politica/politica-model';
import classNames from 'classnames';
import { useGetPoliticaPendente, usePutPoliticaAceitar } from 'data/api/gestao/politica';

const PoliticaPage = () => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { putPoliticaAceitar, carregando: carregandoAceite } =
    usePutPoliticaAceitar();
  const { push: historyPush } = useHistory();
  const confirm = useConfirm();
  const {
    deslogar,
    carregando: carregandoLogin,
    usuario,
    persistirTermos,
    carregarDadosUsuario,
    getEmpresa
  } = useSessaoAtual();
  const { showToast } = useToastSaurus();
  const theme = useTheme();
  const { getPoliticaPendente, carregando: carregandoGet } =
    useGetPoliticaPendente();

  const [politica, setPolitica] = useState<PoliticaModel>();
  const [position, setPosition] = useState<string>('');
  const loading = carregandoAceite || carregandoGet || carregandoLogin;

  const rejeitarTermos = useCallback(() => {
    confirm({
      title: 'Rejeitar Termos de Uso?',
      description:
        'Infelizmente ao rejeitar os termos de uso, você não poderá acessar o sistema. Deseja realmente Rejeitar?',
      cancellationText: 'Recusar e Realizar Logoff',
      cancellationButtonProps: { color: 'secondary' },
      confirmationButtonProps: { variant: 'contained' },
      confirmationText: 'Ler Novamente',
    }).catch(() => {
      deslogar(usuario?.usuarioId || '');
      historyPush('/login')
    });
  }, [confirm, deslogar, historyPush, usuario?.usuarioId]);

  const getTermos = useCallback(async () => {
    try {
      const ret = await getPoliticaPendente(getEmpresa().ContratoId);
      if (ret.erro) throw ret.erro;

      if (isEmpty(ret.resultado?.data)) {
        persistirTermos(true, 200)
        historyPush('/')
        return
      }

      persistirTermos(false, 400)

      setPolitica(ret.resultado?.data[0]);
    } catch (e: any) {
      showToast('error', e.message);
      deslogar(usuario?.usuarioId || '')
      historyPush('/login')
    }
  }, [deslogar, getEmpresa, getPoliticaPendente, historyPush, persistirTermos, showToast, usuario?.usuarioId]);

  const finalizarProcesso = useCallback(
    async () => {
      try {
        const ret = await putPoliticaAceitar({
          aceito: true,
          localicazao: position || '',
          documento: '',
          ip: '',
          nome: '',
          referencia: '',
          termoId: politica?.id || '',
        });

        if (ret.erro) throw ret.erro;

        persistirTermos(true, ret.statusCode)
        if (usuario) {
          await carregarDadosUsuario(usuario);
        }
        historyPush('/')
      } catch (e: Error | any) {
        historyPush('/login')
        showToast('error', e.message);
      }
    },
    [putPoliticaAceitar, position, politica?.id, persistirTermos, usuario, historyPush, carregarDadosUsuario, showToast],
  );

  const aceitarTermosSimples = useCallback(() => {
    finalizarProcesso();
  }, [finalizarProcesso]);

  useEffect(() => {
    if (usuario) {
      if ('geolocation' in navigator) {
        var options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };
        navigator.geolocation.getCurrentPosition(
          function (position) {
            setPosition(
              position?.coords.latitude + ';' + position?.coords.longitude,
            );
            getTermos();
          },
          function (error) {
            setPosition('denied');
            getTermos();
          },
          options,
        );
      }
    }
  }, [getTermos, usuario]);


  return (
    <>
      <div className={utilClasses.contentCenter}>
        {(loading || isEmpty(politica)) ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        {(!loading && !isEmpty(politica)) && <Grid container className={classNames(utilClasses.rootContainer, classes.rootCard)}>
          <Grid item xs={12}>
            <Typography align="center" variant="h3" color="primary">
              <TermosIcon tipo="GERAL" class={classes.imgTitulo} />
              Termos de Uso
            </Typography>
            <Box mt={3}>
              <Typography align="center" variant="body1">
                Leia os termos de uso e política de privacidade para continuar o acesso ao sistema.
              </Typography>
            </Box>
            <>
              <div className={classes.termosDeUso}>
                {parse(politica?.termo || '')}
              </div>
              <Box mt={5}>
                <Grid spacing={2} container>
                  <Grid item xs={12} sm={6}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="outlined"
                      fullWidth={true}
                      disabled={loading}
                      onClick={() => {
                        rejeitarTermos();
                      }}
                    >
                      <CancelarIcon
                        fill={theme.palette.primary.main}
                        tipo="BUTTON"
                      />
                      Recusar e Sair
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth={true}
                      disabled={loading}
                      onClick={() => {
                        aceitarTermosSimples();
                      }}
                    >
                      <AvancarIcon tipo="BUTTON_PRIMARY" />
                      Aceitar e Continuar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          </Grid>
        </Grid>}
      </div>
    </>
  );
};

export default PoliticaPage;
