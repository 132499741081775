import { Button, Grid } from '@material-ui/core';

import {
    useImperativeHandle,
    forwardRef,
} from 'react';
import { CircularLoading, makeUtilClasses } from 'views';
import {
    DefaultFormRefs,
    DefaultFormProps,
} from '../../utils/form-default-props';
import { useForm, Controller } from 'react-hook-form'
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ParametrizacaoBoletoModel } from 'model/app/forms/master/parametrizacao-boleto-model';
import { KeyValueModel } from 'model';
import { useFormParametrizacaoBoletosValidation } from './form-parametrizacao-boletos-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { TpEspecieMock } from 'data/mocks/tp-especie-mock';
import { toDecimal } from 'utils/to-decimal';
import { useConfirmSaurus } from 'services/app/hooks/confirm-saurus';

export const limitesCodigoDefesaConsumidor = {
    juros: 1,
    multa: 2
}


export const FormParametrizacaoBoleto = forwardRef<
    DefaultFormRefs<ParametrizacaoBoletoModel>,
    DefaultFormProps<ParametrizacaoBoletoModel>
>((props: DefaultFormProps<ParametrizacaoBoletoModel>, refs) => {

    const utilClasses = makeUtilClasses();
    const { showConfirm } = useConfirmSaurus()

    const { FormParametrizacaoBoletosValidation } = useFormParametrizacaoBoletosValidation();

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue,
    } = useForm<ParametrizacaoBoletoModel>({
        criteriaMode: 'all',
        defaultValues: { ...new ParametrizacaoBoletoModel() },
        resolver: yupResolver(FormParametrizacaoBoletosValidation),
        mode: 'onChange',
    });

    const onSubmit = (form: ParametrizacaoBoletoModel) => {
        form.percDesconto1 = toDecimal(form.percDesconto1)
        form.percMulta = toDecimal(form.percMulta)
        form.percJuros = toDecimal(form.percJuros)
        if (form.percMulta > limitesCodigoDefesaConsumidor.multa || form.percJuros > limitesCodigoDefesaConsumidor.juros) {
            const avisos = [];
            if (form.percMulta > limitesCodigoDefesaConsumidor.multa) {
                avisos.push(`Multa Atual: ${form.percMulta}% / Limite Estabelecido: ${limitesCodigoDefesaConsumidor.multa}%`)
            }
            if (form.percJuros > limitesCodigoDefesaConsumidor.juros) {
                avisos.push(`Juros Atual: ${form.percJuros}% / Limite Estabelecido: ${limitesCodigoDefesaConsumidor.juros}%`)
            }
            showConfirm({
                title: 'Atenção',
                description: <>
                    O código de defesa do consumidor estabelece alguns limites para percentuais nos boletos.<br /><br/>
                    {avisos.map(msg => <>{msg}<br /></>)}
                    <br/>Tem certeza que deseja prosseguir?
                </>,
                primaryButtonText: 'Voltar e Reajustar',
                secondaryButtonText: 'Ignorar e Salvar'
            }).catch(() => props.onSubmit(form))
            return
        }
        props.onSubmit(form);
    };

    useImperativeHandle(refs, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: ParametrizacaoBoletoModel) => {
            reset({ ...model });
        },
    }));

    return (
        <>
            <div className={utilClasses.formContainer}>
                {(props.loading && props.showLoading) ? (
                    <CircularLoading tipo="FULLSIZED" />
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={`${props.loading ? utilClasses.controlLoading : ''}`}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name='percMulta'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Multa (%)'
                                        placeholder='Ex: 3,50%'
                                        tipo='DECIMAL'
                                        manterMascara
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.percMulta)}
                                        helperText={Boolean(errors.percMulta && errors.percMulta.message) ?
                                            errors.percMulta?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name='percJuros'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Juros (%)'
                                        placeholder='Ex: 1,00%'
                                        tipo='DECIMAL'
                                        manterMascara
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.percJuros)}
                                        helperText={Boolean(errors.percJuros && errors.percJuros.message) ?
                                            errors.percJuros?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name='codigoBeneficiario'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Cód. Beneficiário'
                                        placeholder='Ex: 109'
                                        tipo='NUMERO'
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.codigoBeneficiario)}
                                        helperText={Boolean(errors.codigoBeneficiario && errors.codigoBeneficiario.message) ?
                                            errors.codigoBeneficiario?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Controller
                                name='carteira'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Carteira'
                                        placeholder='Ex: 109'
                                        tipo='NUMERO'
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.carteira)}
                                        helperText={Boolean(errors.carteira && errors.carteira.message) ?
                                            errors.carteira?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name='especie'
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        label='Espécie'
                                        disabled={props.loading}
                                        conteudo={TpEspecieMock}
                                        fullWidth
                                        {...field}
                                        onChange={(e: any) => setValue('especie', e.target.value)}
                                        helperText={Boolean(errors.especie && errors.especie.message) ?
                                            errors.especie?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name='layoutArquivoRetorno'
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        conteudo={[
                                            new KeyValueModel(240, '240'),
                                            new KeyValueModel(400, '400')
                                        ]}
                                        label='Layout Arquivo Retorno'
                                        variant="outlined"
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.layoutArquivoRetorno)}
                                        helperText={Boolean(errors.layoutArquivoRetorno && errors.layoutArquivoRetorno.message) ?
                                            errors.layoutArquivoRetorno?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name='registroOnline'
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        conteudo={[
                                            new KeyValueModel(true, 'Sim'),
                                            new KeyValueModel(false, 'Não')
                                        ]}
                                        label='Registro Online'
                                        variant="outlined"
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.registroOnline)}
                                        helperText={Boolean(errors.registroOnline && errors.registroOnline.message) ?
                                            errors.registroOnline?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name='protesto'
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        conteudo={[
                                            new KeyValueModel(true, 'Sim'),
                                            new KeyValueModel(false, 'Não')
                                        ]}
                                        label='Protesto'
                                        variant="outlined"
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.protesto)}
                                        helperText={Boolean(errors.protesto && errors.protesto.message) ?
                                            errors.protesto?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name='numDiasProtesto'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Dias para Protesto'
                                        placeholder='Ex: 30'
                                        tipo='NUMERO'
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.numDiasProtesto)}
                                        helperText={Boolean(errors.numDiasProtesto && errors.numDiasProtesto.message) ?
                                            errors.numDiasProtesto?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name='percDesconto1'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Desconto (%)'
                                        placeholder='Ex: 10%'
                                        tipo='DECIMAL'
                                        manterMascara
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.percDesconto1)}
                                        helperText={Boolean(errors.percDesconto1 && errors.percDesconto1.message) ?
                                            errors.percDesconto1?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Controller
                                name='numDiasAntesVencimentoDesconto1'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Conceder Desconto de Pagamento'
                                        placeholder='Ex: 10'
                                        tipo='NUMERO'
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.numDiasAntesVencimentoDesconto1)}
                                        helperText={Boolean(errors.numDiasAntesVencimentoDesconto1 && errors.numDiasAntesVencimentoDesconto1.message) ?
                                            errors.numDiasAntesVencimentoDesconto1?.message : '*dias antes do vencimento'}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='ultimoNossoNumero'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Último Nosso Número'
                                        tipo='NUMERO'
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.ultimoNossoNumero)}
                                        helperText={Boolean(errors.ultimoNossoNumero && errors.ultimoNossoNumero.message) ?
                                            errors.ultimoNossoNumero?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='mensagemRecibo'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Mensagem Recibo'
                                        placeholder='Digite aqui a mensagem que será exibida no recibo do pagamento'
                                        rows={4}
                                        multiline
                                        {...field}
                                        error={Boolean(errors.mensagemRecibo)}
                                        helperText={Boolean(errors.mensagemRecibo && errors.mensagemRecibo.message) ?
                                            errors.mensagemRecibo?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='mensagemFichaCompensacao'
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Mensagem da Ficha de Compensação'
                                        placeholder='Digite aqui a mensagem que será exibida na ficha de compensação'
                                        rows={4}
                                        multiline
                                        {...field}
                                        error={Boolean(errors.mensagemFichaCompensacao)}
                                        helperText={Boolean(errors.mensagemFichaCompensacao && errors.mensagemFichaCompensacao.message) ?
                                            errors.mensagemFichaCompensacao?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </Grid>
                </form>
            </div>
        </>
    );
});