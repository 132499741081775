import { ContaBancariaListModel } from "./conta-bancaria-list-model";

export class ContaBancariaVinculoModel extends ContaBancariaListModel{
    constructor(
        public idVinculo: string | null = null
    ) {
        super()
    }
}

export class ContaBancariaVinculoPostModel{
    constructor(
        public id: string = "",
        public contratoId: string = "",
        public empresaId: string = "",
        public contaBancariaPrincipalId: string = "",
        public contaBancariaVinculadaId: string = ""
    ) {}
}