import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { useSessaoAtual } from 'services/app';


export function useDeleteContaBancaria() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const { getEmpresa } = useSessaoAtual();

    const empresaId = getEmpresa().Id;

    const deleteContaBancaria = useCallback(
        (contaBancariaId: string) =>
            invocarApi({
                method: 'DELETE',
                url: `/${VariaveisAmbiente.apiVersion}/empresa/${empresaId}/conta-bancaria/${contaBancariaId}`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            }),
        [empresaId, invocarApi],
    );
    return {
        ...outrasPropriedades,
        deleteContaBancaria,
    };
}