import { BoletoListModel } from "model/api/gestao/boleto/boleto-list-model";
import { Box, Typography } from '@material-ui/core'
import classNames from "classnames";
import { useStyles } from "./info-importacao-styles";
import { toCurrency } from "utils/toCurrency";

interface InfoBoletosProps {
    boletos: BoletoListModel[];
    selecionados: BoletoListModel[];
    children: React.ReactNode;
}

export const InfoImportacao = ({ boletos, children, selecionados }: InfoBoletosProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.listBottomCard}>
            <Box className={classes.items}>
                <Box className={classes.itemInfo}>
                    <Box
                        className={classNames(classes.tagStatus)}
                    ></Box>
                    <Box className={classes.textContainer}>
                        <Typography variant='caption' color='textSecondary'>
                            Quantidade Total de Boletos
                        </Typography>
                        <Typography variant='caption' color='textSecondary'>
                            <span className={classes.textValor}>{boletos.length} Boletos</span>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.items}>
                <Box className={classes.itemInfo}>
                    <Box
                        className={classNames(classes.tagStatus)}
                    ></Box>
                    <Box className={classes.textContainer}>
                        <Typography variant='caption' color='textSecondary'>
                            Valor Total dos Boletos
                        </Typography>
                        <Typography variant='caption' color='textSecondary'>
                            <span className={classes.textValor}>
                                {toCurrency(boletos.reduce<number>((prev, curr) => prev + curr.valorTitulo, 0))}
                            </span>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.items}>
                <Box className={classes.itemInfo}>
                    <Box
                        className={classNames(classes.tagStatus)}
                    ></Box>
                    <Box className={classes.textContainer}>
                        <Typography variant='caption' color='textSecondary'>
                            Quantidade de Boletos Selecionados
                        </Typography>
                        <Typography variant='caption' color='textSecondary'>
                            <span className={classes.textValor}>{selecionados.length} Boletos</span>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.items}>
                <Box className={classes.itemInfo}>
                    <Box
                        className={classNames(classes.tagStatus)}
                    ></Box>
                    <Box className={classes.textContainer}>
                        <Typography variant='caption' color='textSecondary'>
                            Valor Total dos Boletos Selecionados
                        </Typography>
                        <Typography variant='caption' color='textSecondary'>
                            <span className={classes.textValor}>
                                {toCurrency(selecionados.reduce<number>((prev, curr) => prev + curr.valorTitulo, 0))}
                            </span>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.button}>
                {children}
            </Box>
        </Box>
    )
}