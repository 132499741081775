import { KeyValueModel } from "model";
import { EnumTipoFiltroData, EnumTipoFiltroDataRemessa } from "model/enums/enum-tipo-filtro-data";

export const TipoFiltroDataMock: KeyValueModel[] = [
    new KeyValueModel(EnumTipoFiltroData.Vencimento, "Vencimento"),
    new KeyValueModel(EnumTipoFiltroData.Emissao, "Emissão"),
    new KeyValueModel(EnumTipoFiltroData.Entrada, "Entrada"),
    new KeyValueModel(EnumTipoFiltroData.Todos, "Todos")
];

export const TipoFiltroDataRemessaMock: KeyValueModel[] = [
    new KeyValueModel(EnumTipoFiltroDataRemessa.Entrada, 'Entrada'),
    new KeyValueModel(EnumTipoFiltroDataRemessa.Vencimento, 'Vencimento')
];