import { Controller, useForm } from "react-hook-form";
import { InicialPageSearchProps } from "./inicial-page-search-props";
import { Box, Button, Grid } from "@material-ui/core";
import { useSearchBarStyles } from "./serch-bar-styles";
import { useCallback, useEffect, useState } from "react";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { TipoFiltroDataMock } from "data/mocks/tipo-data-mock";
import { StatusBoletoMock } from "data/mocks/status-boleto-mock";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { PesquisaIcon } from "views/components/icons/pesquisa-icon";
import { ButtonSwitch } from "views/components/controles/buttons/button-switch/button-switch";
import { ArquivoRegistroMock } from "data/mocks/arquivo-registro-mock";
import { EnumArquivoRegistro } from "model/enums/enum-arquivo-registro";
import { useHistory } from "react-router-dom";
import { useThemeQueries } from "views/theme";
import { ExpandIcon } from "views/components/icons";
import { useInicialSearchBarValidation } from "./inicial-search-bar-validation";
import { yupResolver } from "@hookform/resolvers/yup";
interface InicialPageSearchBarProps {
    setSearchProps: React.Dispatch<React.SetStateAction<InicialPageSearchProps>>;
    setSelectedPage: React.Dispatch<React.SetStateAction<EnumArquivoRegistro>>;
    selectedPage: EnumArquivoRegistro;
}

export const InicialPageSearchBar = ({
    setSearchProps,
    setSelectedPage,
    selectedPage
}: InicialPageSearchBarProps) => {

    const { isMobile } = useThemeQueries();
    const { InicialSearchBarValidation } = useInicialSearchBarValidation();
    const [openned, setOpenned] = useState<boolean>(!isMobile)
    useEffect(() => {
        setOpenned(!isMobile)
    }, [isMobile])

    const classes = useSearchBarStyles({
        openned,
        padding: 'small',
        selectedPage
    });

    const { push } = useHistory();

    const {
        handleSubmit,
        control,
        formState: {errors},
        setValue,
    } = useForm<InicialPageSearchProps>({
        defaultValues: { ...new InicialPageSearchProps() },
        resolver: yupResolver(InicialSearchBarValidation),
        criteriaMode: 'all',
        mode: 'onSubmit',
    });

    const onSubmit = useCallback((model: InicialPageSearchProps) => {
        setSearchProps({...model})
    }, [setSearchProps])

    return (
        <>
            <Box className={classes.bar}>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} className={classes.formGrid}>

                        {selectedPage === EnumArquivoRegistro.Boleto && <Grid item xs={12} md={3}>
                            <Controller
                                control={control}
                                name="tipoData"
                                render={({ field }) => (
                                    <SelectSaurus
                                        label='Tipo de Data'
                                        conteudo={TipoFiltroDataMock}
                                        fullWidth
                                        {...field}
                                        onChange={(e: any) => {
                                            const valor = TipoFiltroDataMock.find(tipo => tipo.Key === e.target.value)
                                            if (valor) {
                                                setValue('tipoData', valor.Key)
                                            }
                                        }}
                                        error={Boolean(errors.tipoData)}
                                        helperText={Boolean(errors.tipoData && errors.tipoData.message) ? errors.tipoData?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>}

                        <Grid item xs={12} md={selectedPage === EnumArquivoRegistro.Boleto ? 3 : 6}>
                            <Controller
                                control={control}
                                name="dataInicial"
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Data Inicial'
                                        tipo='DATA'
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.dataInicial)}
                                        helperText={Boolean(errors.dataInicial && errors.dataInicial.message) ? errors.dataInicial?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={selectedPage === EnumArquivoRegistro.Boleto ? 3 : 6}>
                            <Controller
                                control={control}
                                name="dataFinal"
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Data Final'
                                        tipo='DATA'
                                        fullWidth
                                        {...field}
                                        error={Boolean(errors.dataFinal)}
                                        helperText={Boolean(errors.dataFinal && errors.dataFinal.message) ? errors.dataFinal?.message : undefined}
                                    />
                                )}
                            />
                        </Grid>

                        {selectedPage === EnumArquivoRegistro.Boleto && <Grid item xs={12} md={3}>
                            <Controller
                                control={control}
                                name="status"
                                render={({ field }) => (
                                    <SelectSaurus
                                        label='Status'
                                        conteudo={StatusBoletoMock}

                                        {...field}
                                        onChange={(e: any) => {
                                            const valor = StatusBoletoMock.find(tipo => tipo.Key === e.target.value)
                                            if (valor) {
                                                setValue('status', valor.Key)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>}

                    </Grid>

                    <Button variant='contained' color='primary' type="submit" className={classes.searchButton}>
                        <PesquisaIcon tipo='BUTTON_PRIMARY' />
                        Pesquisar
                    </Button>

                    <Box className={classes.btnSwitchContainer}>
                        <ButtonSwitch
                            conteudo={ArquivoRegistroMock}
                            defaultSelected={selectedPage}
                            onChange={(valor) => {
                                setSelectedPage(valor)
                                switch (valor) {
                                    case EnumArquivoRegistro.Historico:
                                        push('/inicial/historico');
                                        break;
                                    case EnumArquivoRegistro.Remessa:
                                        push('/inicial/remessa')
                                        break;
                                    default:
                                        push('/inicial/boletos')
                                }
                            }}

                        />
                    </Box>
                </form>
            </Box>
            {isMobile && (
                <Box className={classes.closeOpenBar} onClick={() => setOpenned(prev => !prev)}>
                    {openned ? (
                        <>
                            <ExpandIcon tipo='GERAL' />
                            Fechar Filtros
                        </>
                    ) : (
                        <>
                            <ExpandIcon tipo='GERAL' />
                            Abrir Filtros
                        </>
                    )}
                </Box>
            )}
        </>
    )

}