import { EnumStatusBoleto } from "model/enums/enum-status-boleto";
import { EnumTipoFiltroData } from "model/enums/enum-tipo-filtro-data";
import { descMonth, getCurrentMonth, incMonth } from "utils/get-date";
import { toDateString } from "utils/to-date";

export class InicialPageSearchProps {
    constructor(
        public tipoData: EnumTipoFiltroData = EnumTipoFiltroData.Todos,
        public dataInicial: string = toDateString(descMonth(new Date(getCurrentMonth()), 1), 'yyyy-MM-DD')!,
        public dataFinal: string = toDateString(incMonth(new Date(getCurrentMonth()), 1), 'yyyy-MM-DD')!,
        public status: EnumStatusBoleto = EnumStatusBoleto.Todos,
    ) { }
}