import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';


export class PutUsuarioProps {
    constructor(
        public id: string = "",
        public nome: string = "",
        public email: string = "",
        public imagem: string = "",
        public apelido: string = "",
        public telefone: string = ""
    ) { }
}

export function usePutUsuario() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putUsuario = useCallback(
        (usuarioModel: PutUsuarioProps) =>
            invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/Usuario/meu-usuario`,
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(usuarioModel),
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putUsuario,
    };
}
