import { makeStyles, Theme } from "@material-ui/core";

interface StylesProps{
    color?: 'primary' | 'secondary' | 'divider';
    height?: number;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    divider: {
        height: ({height}) => height || 3,
        background: ({color}) => {
            if(color){
                switch(color){
                    case 'primary':
                        return theme.palette.primary.main;
                    case 'secondary':
                        return theme.palette.secondary.main;
                    default:
                        return theme.palette.divider;
                }
            }
            return theme.palette.divider;
        },
        borderRadius: '2px',
        opacity: '50%'
    },
    dashedDivider: {
        width: '100%',
        height: 1,
        borderTop: `1px dashed ${theme.palette.divider}`
    }
}));
