import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        '& p': {
            fontSize: '12px !important',
            fontWeight: 500,
        }
    },
    valores: {
        [theme.breakpoints.up('lg')]: {
            textAlign: 'right'
        }
    },
    data: {
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center'
        }
    },
    iconsContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: theme.spacing(1),
        borderLeft: `3px solid rgba(0, 0, 0, .1)`,
        [theme.breakpoints.down('md')]: {
            alignItems: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))