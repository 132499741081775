import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    formContainer: {
        margin: `${theme.spacing(2)}px 0`,
    },
    txtCodigo: {
        letterSpacing: '20%'
    }
}));
