import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const NovoDocumento = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 7 2 L 7 48 L 43 48 L 43 47 L 43 14.585938 L 30.414062 2 L 7 2 z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 L 9 4 z M 31 5.4140625 L 39.585938 14 L 31 14 L 31 5.4140625 z M 14 10 L 14 12 L 27 12 L 27 10 L 14 10 z M 14 14 L 14 16 L 27 16 L 27 14 L 14 14 z M 14 18 L 14 20 L 37 20 L 37 18 L 14 18 z M 14 22 L 14 24 L 37 24 L 37 22 L 14 22 z M 14 26 L 14 28 L 25 28 L 25 26 L 14 26 z M 31 28 C 26.593567 28 23 31.593567 23 36 C 23 40.406433 26.593567 44 31 44 C 35.406433 44 39 40.406433 39 36 C 39 31.593567 35.406433 28 31 28 z M 31 30 C 34.325553 30 37 32.674447 37 36 C 37 39.325553 34.325553 42 31 42 C 27.674447 42 25 39.325553 25 36 C 25 32.674447 27.674447 30 31 30 z M 30 32 L 30 35 L 27 35 L 27 37 L 30 37 L 30 40 L 32 40 L 32 37 L 35 37 L 35 35 L 32 35 L 32 32 L 30 32 z" />
            </DefaultIcon>
        </>
    );
};