import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';
import { MeuUsuarioModel } from 'model/api/gestao/meu-usuario/meu-usuario-model';

export function useGetUsuario() {
  const { invocarApi, ...outrasPropriedades } = useApiBase<MeuUsuarioModel>();

  const getMeuUsuario = useCallback(
    () =>
      invocarApi({
        url: `${VariaveisAmbiente.apiVersion}/Usuario/meu-usuario`,
        method: 'GET',
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getMeuUsuario
  };
}