import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { useSessaoAtual } from 'services/app';
import { BoletoGetModel } from 'model/api/gestao/boleto/boleto-get-model';


export function useGetBoletoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<BoletoGetModel>();
    const { getEmpresa } = useSessaoAtual();

    const getBoletoById = useCallback(
        (id: string) =>
            invocarApi({
                method: 'GET',
                url: `/${VariaveisAmbiente.apiVersion}/Empresa/${getEmpresa().Id}/boleto/${id}`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            }),
        [getEmpresa, invocarApi],
    );
    return {
        ...outrasPropriedades,
        getBoletoById,
    };
}