import { Box, Button } from "@material-ui/core"
import { useStyles } from '../contas-bancarias-styles'
import { ContasSearchBar, ContasSearchProps } from "../contas-searchbar/contas-searchbar";
import { useState } from "react";
import { ContasList } from "./components/contas-list/contas-list";
import { NovoIcon } from "views/components/icons";
import { useCadastros } from "services/app/hooks/cadastros";

interface ContasProps{
    aberto: boolean;
}

export const Contas = ({
    aberto
}: ContasProps) => {
    const classes = useStyles();

    const { abrirDialogContaBancaria } = useCadastros();

    const [searchProps, setSearchProps] = useState<ContasSearchProps>(new ContasSearchProps());

    return (
        <Box className={classes.cardConta}>
            <ContasSearchBar
                searchProps={searchProps}
                setSearchProps={setSearchProps}
            />
            <ContasList
                searchProps={searchProps}
                dialogAberto={aberto}
            />
            <Box className={classes.criarButtonContainer}>
                <Button
                    variant='outlined'
                    color='primary'
                    fullWidth
                    size="small"
                    onClick={() => abrirDialogContaBancaria()}
                >
                    <NovoIcon tipo='BUTTON' />
                    Adicionar Nova Conta Bancária
                </Button>
            </Box>
        </Box>
    )
}