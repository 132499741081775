import { useCallback } from 'react';
import { stringNumeros } from 'utils/string-numeros';
import * as Yup from 'yup';


export const useValidationYupFone = () => {
    const telefoneYup = useCallback(() => {
        return (
            Yup.string().nullable().optional().test({
                test: value => stringNumeros(value || '').length >= 10,
                message: 'Telefone Inválido'
            })
        )
    }, [])

    return (
        telefoneYup
    )
}