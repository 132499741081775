import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const AmpulhetaIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 6 1 L 6 5 C 6 6.644531 7.355469 8 9 8 L 11 8 L 11 14.71875 C 11 17.726563 12.496094 20.550781 15 22.21875 L 19.1875 25 L 15 27.78125 C 12.496094 29.449219 11 32.273438 11 35.28125 L 11 42 L 9 42 C 7.355469 42 6 43.355469 6 45 L 6 49 L 44 49 L 44 45 C 44 43.355469 42.644531 42 41 42 L 39 42 L 39 35.28125 C 39 32.273438 37.503906 29.449219 35 27.78125 L 30.8125 25 L 35 22.21875 C 37.503906 20.550781 39 17.726563 39 14.71875 L 39 8 L 41 8 C 42.644531 8 44 6.644531 44 5 L 44 1 Z M 8 3 L 42 3 L 42 5 C 42 5.566406 41.566406 6 41 6 L 9 6 C 8.433594 6 8 5.566406 8 5 Z M 13 8 L 37 8 L 37 14.71875 C 37 17.058594 35.824219 19.234375 33.875 20.53125 L 28.4375 24.15625 L 27.1875 25 L 28.4375 25.84375 L 33.875 29.46875 C 35.824219 30.769531 37 32.941406 37 35.28125 L 37 42 L 33.9375 42 C 33.234375 34.746094 25 32 25 32 C 25 32 16.789063 34.902344 16.0625 42 L 13 42 L 13 35.28125 C 13 32.941406 14.175781 30.769531 16.125 29.46875 L 21.5625 25.84375 L 22.8125 25 L 21.5625 24.15625 L 16.125 20.53125 C 14.175781 19.234375 13 17.058594 13 14.71875 Z M 17 15 C 17 20.089844 25 22 25 22 C 25 22 33 19.984375 33 15 Z M 9 44 L 41 44 C 41.566406 44 42 44.433594 42 45 L 42 47 L 8 47 L 8 45 C 8 44.433594 8.433594 44 9 44 Z" />
            </DefaultIcon>
        </>
    );
};


