export function isFalseUndefinedNull(value: any){
    return value === null || value === undefined || value === false;
}

export function isEmptyCustom(value: any, isUndefined: boolean = true, isNull: boolean = true, isStringEmpty: boolean = true, isZero: boolean = true){
    if(isUndefined && value === undefined){
        return true
    }
    if(isNull && value === null){
        return true
    }
    if(isStringEmpty && value === ''){
        return true
    }
    if(isZero && value === 0){
        return true
    }
    return false
}