import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { RotasMock } from 'data/mocks/rotas-mock';

export const ValidateRoute: React.FC<RouteProps> = ({
  children,
  ...otherProps
}) => {

  const { pathname } = useLocation();

  const retRoute = useCallback(() => {
    const modulo = RotasMock.find(item => pathname.includes(item.rota));
    if (modulo) return children
    return <Redirect to='/inicial/boletos' />

  }, [children, pathname]);
  return <Route {...otherProps}>{retRoute()}</Route>;
};
