import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    indicadorData: {
        '& p': {
            fontSize: '1.5rem',
            color: theme.palette.primary.main,
            fontWeight: 600
        },
        width: '100%',
        padding: theme.spacing(1)
    },
    listContainer: {
        background: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
    },
    listLabels: {
        padding: theme.spacing(0, 2),
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    cardDate: {
        fontWeight: 600,
        color: 'rgb(87 87 87)',
        fontSize: '.9rem',
        padding: theme.spacing(1),
        paddingBottom: 0,
        marginTop: theme.spacing(1)
    }
}));