import { Box, Button } from "@material-ui/core";
import { KeyValueModel } from "model"
import { useStyles } from "./button-switch-styles";
import { useState } from "react";

interface ButtonSwitchProps {
    conteudo: KeyValueModel[];
    onChange: (valor: any) => void;
    defaultSelected?: any;
}

export const ButtonSwitch = ({
    conteudo,
    onChange,
    defaultSelected,
}: ButtonSwitchProps) => {

    const classes = useStyles();

    const [selected, setSelected] = useState(defaultSelected || conteudo[0].Key)


    return (
        <Box className={classes.botoesAgrupados}>
            {conteudo.map(item => {
                const classeBtn = item.Key === selected ? classes.botaoSelecionado : classes.botaoNaoSelecionado
                const variant = item.Key === selected ? 'contained' : 'outlined'
                return (
                    <Button variant={variant} className={classeBtn} onClick={() => {
                        setSelected(item.Key)
                        onChange(item.Key)
                    }}>
                        {item.Value}
                    </Button>
                )
            })}
        </Box>
    )
}