import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ApiListModel } from 'model/api/api-list-model/api-list-model';
import { BoletoHistoricoModel } from 'model/api/gestao/boleto/boleto-historico-model';
import { useSessaoAtual } from 'services/app';


export function useGetBoletosHistoricoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<BoletoHistoricoModel>>();
    const { getEmpresa } = useSessaoAtual();

    const getBoletosHistoricoById = useCallback(
        (boletoId: string, page: number = 1, pageSize: number = 0) =>
            invocarApi({
                method: 'GET',
                url: `/${VariaveisAmbiente.apiVersion}/Empresa/${getEmpresa().Id}/Boleto/${boletoId}/historico?page=${page}&pageSize=${pageSize}`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            }),
        [getEmpresa, invocarApi],
    );
    return {
        ...outrasPropriedades,
        getBoletosHistoricoById,
    };
}