import { Box, Button, Grid, Typography } from '@material-ui/core'
import { makeUtilClasses, useThemeQueries } from 'views/theme'
import { useStyles } from './recuperar-senha-page-styles';
import { PublicPageHeader } from 'views/components/headers';
import { VoltarIcon } from 'views/components/icons';
import { GestaoStorageKeys, useGestaoStorage, useToastSaurus } from 'services/app';
import { useEffect, useRef } from 'react';
import { DefaultFormRefs } from 'views/components/form/utils';
import { RecuperarSenhaFormModel } from 'model/app';
import { useHistory } from 'react-router-dom';
import { FormRecuperarSenha } from 'views/components/form/master/recuperar-senha/form-recuperar-senha'
import { DividerSaurus } from 'views/components/divider/divider-saurus';
import { EnviarEmailIcon } from 'views/components/icons/enviar-email';
import { usePostIdentidadeRedefinirSenha } from 'data/api/gestao/identidade';

export const RecuperarSenhaPage = () => {
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const { showToast } = useToastSaurus();
    const { setRegistro } = useGestaoStorage()
    const { theme } = useThemeQueries();
    const history = useHistory()

    const { postIdentidadeRedefinirSenha, carregando } = usePostIdentidadeRedefinirSenha();

    const recuperarSenhaRefs = useRef<DefaultFormRefs<RecuperarSenhaFormModel>>(null);

    useEffect(() => {
        recuperarSenhaRefs.current?.fillForm(new RecuperarSenhaFormModel(''));
    }, []);

    const recuperarSenha = async (model: RecuperarSenhaFormModel) => {
        try {
            var ret = await postIdentidadeRedefinirSenha(model.email);
            if (ret.erro)
                throw ret.erro;

            showToast("info", "Um e-mail foi enviado para você com um código de redefinição, acesse-o para continuar a redefinição de senha.");

            setRegistro(GestaoStorageKeys.EmailRecuperacaoSenha, model.email, true);
            history.replace('/recuperar-senha/retorno');


        } catch (e: any) {
            showToast("error", e.message);
            recuperarSenhaRefs.current?.resetForm();
        }
    };

    return (
        <Box className={utilClasses.contentCenter}>
            <PublicPageHeader topOpacity='0.8' />
            <Box className={classes.card}>
                <Grid container alignItems="center" direction="column">
                    <Grid item xs={12}>
                        <Typography variant="h3" color="primary" align='center'>
                            Redefinir Senha
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={3}>
                            <Typography variant="body1" color='primary' align='center'>
                                Informe seu email para redefinir sua senha.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.stretch}>
                        <FormRecuperarSenha loading={carregando} showLoading ref={recuperarSenhaRefs} onSubmit={(model) => { recuperarSenha(model); }} />
                        <Box mt={5}>
                            <Grid spacing={2} container justifyContent='flex-end'>
                                <Grid item xs={12}>
                                <DividerSaurus/>
                                </Grid>
                                <Grid item xs={5} md={3}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="outlined"
                                        fullWidth={true}
                                        onClick={() => {
                                            history.push('/login')
                                        }}>
                                        <VoltarIcon fill={theme.palette.primary.main} tipo="BUTTON" />
                                        Voltar
                                    </Button>
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        fullWidth={true}
                                        onClick={() => { recuperarSenhaRefs.current?.submitForm(); }}
                                    >
                                        <EnviarEmailIcon tipo="BUTTON_PRIMARY" />
                                        Enviar E-mail
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>

                    </Grid>

                </Grid>
            </Box>
        </Box>
    )
}