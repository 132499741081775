import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles((theme) => ({
    cardHistorico: {
        padding: theme.spacing(2, 1)
    },
    mobileLabel:{
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}))