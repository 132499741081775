import { Box } from "@material-ui/core";
import { useGetBoletosHistoricoById } from "data/api/gestao/boleto/get-boleto-historico-by-id";
import { ApiListModel } from "model/api/api-list-model/api-list-model";
import { BoletoHistoricoModel } from "model/api/gestao/boleto/boleto-historico-model";
import { useCallback, useEffect, useState } from "react"
import { useShowAviso } from "services/app/hooks/show-aviso";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils";
import { HistoricoListData } from "views/pages/private/inicial/components/historico-list/historico-list-data";

interface AccordionHistoricoBoletoProps {
    id: string,
    historico: ApiListModel<BoletoHistoricoModel>;
}

export const AccordionHistoricoBoleto = ({
    id,
    historico
}: AccordionHistoricoBoletoProps) => {

    const [openned, setOpenned] = useState<boolean>(false);
    const { showAviso } = useShowAviso();
    const { getBoletosHistoricoById, carregando } = useGetBoletosHistoricoById();

    const [ocorrencias, setOcorrencias] = useState<ApiListModel<BoletoHistoricoModel>>(historico);

    const getHistoricoWrapper = useCallback(async (page: number) => {
        try {
            const res = await getBoletosHistoricoById(id, page, 10);

            if (res.erro) throw res.erro;

            setOcorrencias({
                ...res.resultado!.data
            })
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [getBoletosHistoricoById, id, showAviso])

    useEffect(() => {
        setOcorrencias(historico);
    }, [historico])

    return (
        <AccordionSaurus
            labelPrimary="Histórico do Boleto"
            expanded={openned}
            onChange={() => setOpenned(prev => !prev)}
        >
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Box width='100%'>
                <Paginacao
                    currentPage={ocorrencias.pageIndex}
                    pageChanged={(currentPage) => {
                        getHistoricoWrapper(currentPage);
                    }}
                    totalPages={ocorrencias.totalPages}
                    totalRegisters={ocorrencias.totalResults}
                />
                <HistoricoListData
                    carregando={carregando}
                    list={ocorrencias.list}
                    onCardClick={() => { }}
                />
            </Box>
        </AccordionSaurus>
    )
}