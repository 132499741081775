import { PrivatePageHeader } from "views/components/headers";
import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core'
import { useCadastros } from "services/app/hooks/cadastros";
import { ConfiguracaoIcon, NovoIcon } from "views/components/icons";
import { useStyles } from './main-header-styles'
import { useMemo, useRef, useState } from "react";
import { ImportarPDFIcon } from "views/components/icons/importar-pdf-icon";
import { NovoDocumento } from "views/components/icons/novo-documento";
import { useHistory } from "react-router-dom";
import { HomeIcon } from "views/components/icons/home-icon";
import { SairIcon } from "views/components/icons/sair-icon";
import { useSessaoAtual } from "services/app";

export const MainHeader = () => {

    const { abrirDialogImportarArquivo, abrirDialogBoleto } = useCadastros();
    const { deslogar, usuario } = useSessaoAtual();
    const classes = useStyles();

    const [openMenuImport, setOpenMenuImport] = useState<boolean>(false)
    const { location, push } = useHistory()
    const importRef = useRef<HTMLButtonElement>(null)

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (importRef.current && importRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpenMenuImport(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenMenuImport(false);
        }
    }

    const getBotoesHeaders = useMemo(() => {
        const add = (
            <Button onClick={() => setOpenMenuImport(true)} ref={importRef}>
                <Box className={classes.buttonHeader}>
                    <NovoIcon tipo='BUTTON_PRIMARY' />
                    Adicionar
                </Box>
            </Button>
        )

        const config = (
            <Button onClick={() => push('/configuracoes')}>
                <Box className={classes.buttonHeader}>
                    <ConfiguracaoIcon tipo='BUTTON_PRIMARY' />
                    Configurações
                </Box>
            </Button>
        )

        const home = (
            <Button onClick={() => push('/inicial')}>
                <Box className={classes.buttonHeader}>
                    <HomeIcon tipo='BUTTON_PRIMARY' />
                    Inicial
                </Box>
            </Button>
        )

        const sair = (
            <Button onClick={() => deslogar(usuario?.usuarioId || '')}>
                <Box className={classes.buttonHeader}>
                    <SairIcon tipo='BUTTON_PRIMARY' />
                    Sair
                </Box>
            </Button>
        )

        if (location.pathname.includes('/inicial')) {
            return (
                <>
                    {add}
                    {config}
                    {sair}
                </>
            )
        }

        if (location.pathname.includes('/importacao')) {
            return (
                <>
                    {config}
                    {sair}
                </>
            )
        }

        if (location.pathname.includes('/configuracoes') || location.pathname.includes('/remessa')) {
            return (
                <>
                    {home}
                    {sair}
                </>
            )
        }
    }, [classes.buttonHeader, deslogar, location.pathname, push, usuario?.usuarioId])

    return (
        <>
            <PrivatePageHeader
                rightArea={
                    <Box
                        className={classes.buttonContainer}
                    >
                        {getBotoesHeaders}
                    </Box>
                }
            />
            <Popper open={openMenuImport} anchorEl={importRef.current} role={undefined} transition disablePortal style={{ zIndex: 9999 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={openMenuImport} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem className={classes.listItem} onClick={(e) => {
                                        abrirDialogBoleto()
                                        handleClose(e)
                                    }}>
                                        <NovoDocumento tipo="BUTTON" />
                                        Adicionar Manualmente
                                    </MenuItem>
                                    <MenuItem className={classes.listItem} onClick={(e) => {
                                        abrirDialogImportarArquivo()
                                        handleClose(e)
                                    }}>
                                        <ImportarPDFIcon tipo="BUTTON" />
                                        Importar Arquivo
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
