import { useCallback, useEffect, useRef } from "react";
import { BoletoFormModel } from "model/api/gestao/boleto/boleto-add-form-model";
import { useShowAviso } from "services/app/hooks/show-aviso";
import { FormBoleto } from "views/components/form/master/boleto/form-boleto";
import { BoletoFormRef } from "../../dialog-boleto/dialog-boleto";
import { picker } from "utils/picker";
import { Button, Grid } from "@material-ui/core";
import { ConfirmarIcon } from "views/components/icons/confirmar-icon";
import { ImportacaoListModel } from "views/pages/private/importacao/components/importacao-list/importacao-list";

interface ConfirmarBoletoProps {
    onSubmit: (model: ImportacaoListModel) => void;
    model: ImportacaoListModel;
}

export const ConfirmarBoleto = ({
    onSubmit,
    model
}: ConfirmarBoletoProps) => {

    const { showAviso } = useShowAviso();

    const formRef = useRef<BoletoFormRef>(null)

    const handleSubmit = useCallback(async (modelForm: BoletoFormModel) => {
        try {
            const boleto = picker<ImportacaoListModel>(modelForm, model);
            boleto.dataJurosMora = modelForm.dataJurosMora;
            onSubmit(boleto)
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [model, onSubmit, showAviso])

    const fillForm = useCallback(() => {
        const boleto = picker<BoletoFormModel>(model, new BoletoFormModel());
        formRef.current?.fillForm(boleto);
    }, [model])

    useEffect(() => {
        fillForm();
    }, [fillForm])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormBoleto
                    loading={false}
                    showLoading={false}
                    onSubmit={handleSubmit}
                    ref={formRef}
                />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={() => formRef.current?.submitForm()}
                >
                    <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                    Confirmar
                </Button>
            </Grid>
        </Grid>
    )
}