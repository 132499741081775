import { makeStyles, Theme } from '@material-ui/core';

interface StylesProps {
    hide?: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    listBottomCard: {
        boxShadow: '0px 1px 2px 0px #00000040',
        width: '100%',
        display: 'flex',
        alignItems: 'stretch',
        gridGap: theme.spacing(4),
        background: theme.palette.background.default,
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
    },
    items: {
        display: ({ hide }) => hide ? 'none' : 'flex',
        alignItems: 'stretch',
        gridGap: theme.spacing(4),
        flexGrow: 1,
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        '@media screen and ( max-width: 1400px )': {
            gridGap: 0
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start'
        }
    },
    tagStatus: {
        width: "5px",
        borderRadius: "5px",
        margin: theme.spacing(0, 1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    itemInfo: {
        display: 'flex',
        '@media screen and ( max-width: 1400px )': {
            width: '20%',
            margin: theme.spacing(1, 0)
        },
        [theme.breakpoints.down('sm')]: {
            flexBasis: '50%',
            margin: '4px 0'
        }
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center'
    },
    textValor: {
        fontWeight: 500,
        color: theme.palette.text.primary
    },
    button: {
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'stretch'
        }
    },
    showBtn: {
        height: 40,
        '& svg': {
            transform: ({ hide }) => `rotate(${hide ? 180 : 0}deg)`
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}));
