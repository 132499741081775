import { Grid, Typography, TypographyVariant } from "@material-ui/core"
import { DefaultCard } from "../components/default-card"
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { ReactNode } from 'react';
import classNames from "classnames";
import { useStyles } from './card-naoencontrado-styles';

export interface CardNaoEncontratoProps {
    mensagem: string;
    icon: ReactNode;
    fontType?: TypographyVariant;
}

export const CardNaoEncontrado = (props: CardNaoEncontratoProps) => {
    const classes = useDefaultCardStyles({});
    const classesNaoEncontrado = useStyles()

    return (
        <>
            <DefaultCard>
                <div className={classNames(classes.cardContent)}>
                    <div className={classNames(classes.celulaGridFull, classesNaoEncontrado.cardNaoEncontrado,"cardCelula")}>
                        <Grid container alignItems="center" justifyContent="center" >
                            {props.icon}
                            <Typography
                                className={"celula-grid-value"}
                                color="textPrimary"
                                align="center"
                                variant={props.fontType || "h4"}>
                                {props.mensagem}
                            </Typography>
                        </Grid>
                    </div>
                </div>

            </DefaultCard>
        </>
    )
}
