import { EnumRegime } from "model/enums/enum-regime";

export class ConsultaCNPJModel {
    constructor(
        public cnpj: string = '',
        public ie: string = '',
        public razaoSocial: string = '',
        public nomeFantasia: string = '',
        public cnae: string = '',
        public regime: EnumRegime = EnumRegime.NAOSEAPLICA,
        public cep: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public cMunicipio: string = '',
        public uf: string = '',
        public socios: Array<SocioArray> = []
    ) { }
}

export class Socio {
    constructor(
        public socio: Array<SocioArray> = []
    ) { }
}

export class SocioArray {
    constructor(
        public nome: string = '',
        public tipo: string = ''
    ) { }
}