import { Box, Button, Tooltip } from '@material-ui/core'
import { useStyles } from '../contas-bancarias-styles'
import { EnumFiltroContasBancarias } from 'model/enums/enum-filtro-contas-bancarias';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { FiltroContasBancariasMock } from 'data/mocks/filtro-contas-bancarias-mock';
import { OrdenarIcon } from 'views/components/icons/ordenar-icon';

export class ContasSearchProps {
    constructor(
        public query: string = '',
        public tipo: EnumFiltroContasBancarias = EnumFiltroContasBancarias.Agencia,
        public alfabetico: boolean = false
    ) { }
}

interface ContasSearchBarProps {
    searchProps: ContasSearchProps;
    setSearchProps: React.Dispatch<React.SetStateAction<ContasSearchProps>>;
}

export const ContasSearchBar = ({
    searchProps,
    setSearchProps
}: ContasSearchBarProps) => {

    const classes = useStyles();

    return (
        <Box className={classes.searchBar}>
            <Box>
                <TextFieldSaurus
                    value={searchProps.query}
                    onChange={(e: any) => setSearchProps(prev => ({
                        ...prev,
                        query: e.target.value
                    }))}
                    size='small'
                    placeholder='Pesquisar'
                />
                <SelectSaurus
                    value={searchProps.tipo}
                    conteudo={FiltroContasBancariasMock}
                    placeholder='Ordenar'
                    onChange={(e: any) => {
                        setSearchProps(prev => ({
                            ...prev,
                            tipo: e.target.value
                        }))
                    }}
                    size='small'
                />
                <Tooltip title='Ordenar'>
                    <Button
                        className={classes.ordenarButton}
                        variant={searchProps.alfabetico ? 'contained' : 'outlined'}
                        size='small'
                        color='primary'
                        onClick={() => setSearchProps(prev => ({
                            ...prev,
                            alfabetico: !prev.alfabetico
                        }))}>
                        <OrdenarIcon tipo={searchProps.alfabetico ? 'BUTTON_PRIMARY' : 'BUTTON'} />
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    )
}