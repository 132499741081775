export enum EnumTipoFiltroData {
    Vencimento = '0',
    Emissao = '1',
    Entrada = '2',
    Todos = '9'
}

export enum EnumTipoFiltroDataRemessa{
    Entrada = '0',
    Vencimento = '1'
}