import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: theme.spacing(3)
    },
    list: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: theme.spacing(1)
    },
    bottom: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        borderTop: `1px solid ${theme.palette.divider}`
    },
    tagStatus: {
        width: "5px",
        borderRadius: "5px",
        margin: 7,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.warning.light
    },
    itemInfo: {
        display: 'flex'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        flexGrow: 1
    },
    card: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        width: '100%',
        alignItems: 'stretch'
    }
}));