import { Box, Grid } from "@material-ui/core"
import { useStyles } from '../../../contas-bancarias-styles'
import { useCallback, useEffect, useMemo, useState } from "react";
import { ContaBancariaListModel } from "model/api/gestao/conta-bancaria/conta-bancaria-list-model";
import { ContasSearchProps } from "../../../contas-searchbar/contas-searchbar";
import { useCadastros } from "services/app/hooks/cadastros";
import { ContasListData } from "./contas-list-data";
import { useGetContasBancarias } from "data/api/gestao/conta-bancaria/get-contas-bancarias";
import { ApiListModel } from "model/api/api-list-model/api-list-model";
import { Paginacao } from "views/components/paginacao";
import { useEventTools } from "services/app/use-cases/events/event-tools";
import { AppEventEnum } from "model/enums/enum-event-app";
import { CircularLoading } from "views/components";
import { isEmpty } from "lodash";
import { useShowAviso } from "services/app/hooks/show-aviso";

interface ContasListProps {
    searchProps: ContasSearchProps;
    dialogAberto: boolean;
}

export const ContasList = ({
    searchProps,
    dialogAberto
}: ContasListProps) => {
    const classes = useStyles();
    const { showAviso } = useShowAviso();
    const { abrirDialogContaBancaria } = useCadastros();
    const { addHandler, removeHandler } = useEventTools();

    const { getContasBancarias, carregando } = useGetContasBancarias();

    const [queryList, setQueryList] = useState<ApiListModel<ContaBancariaListModel>>({
        list: [],
        pageIndex: 1,
        pageSize: 0,
        totalPages: 0,
        totalResults: 0
    })

    const search = useCallback(async (page: number) => {
        try {
            const res = await getContasBancarias(page, 10);

            if(res.erro) throw res.erro

            if (!res.resultado?.data) {
                throw new Error('Erro ao listar Contas. Tente novamente.')
            }

            if (
                res.resultado?.data.pageIndex > res.resultado?.data.totalPages &&
                res.resultado?.data.totalResults > 0
            ) {
                search(res.resultado.data.totalPages);
                return;
            }

            setQueryList(res.resultado?.data)
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [getContasBancarias, showAviso])


    useEffect(() => {
        if(dialogAberto)
            search(1)
    }, [dialogAberto, search])

    useEffect(() => {
        const searchCallback = ({aberto}: any) => {
            if(!aberto && dialogAberto){
                search(queryList.pageIndex);
            }
        }
        addHandler(AppEventEnum.DialogContaBancaria, searchCallback)
        return () => {
            removeHandler(AppEventEnum.DialogContaBancaria, searchCallback)
        }
    }, [addHandler, dialogAberto, queryList.pageIndex, removeHandler, search])

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryList.totalPages || newPage > 0) {
                search(newPage);
            }
        },
        [queryList.totalPages, search]
    );

    const onCardClick = useCallback((model: ContaBancariaListModel) => {
        abrirDialogContaBancaria(model.id)
    }, [abrirDialogContaBancaria])

    const filteredList = useMemo<ContaBancariaListModel[]>(() => {
        let filtro = queryList.list;

        if(!isEmpty(searchProps.query)){
            filtro = filtro.filter(conta => {
                return conta[searchProps.tipo].toString().toLowerCase().includes(searchProps.query.toLowerCase());
            })

            filtro = filtro.sort((a, b) => {
                const bancoA = a.nomeBanco
                const bancoB = b.nomeBanco

                if (bancoA < bancoB) {
                    return searchProps.alfabetico ? -1 : 1;
                }
                if (bancoA > bancoB) {
                    return searchProps.alfabetico ? 1 : -1;
                }
                return 0;
            })
        }

        return filtro
    }, [queryList.list, searchProps.alfabetico, searchProps.query, searchProps.tipo])

    return (
        <Box className={classes.listContainer}>
            {carregando && <CircularLoading tipo='FULLSIZED'/>}
            <Grid container spacing={2}>
                    <Paginacao
                        pageChanged={pageChanged}
                        totalPages={queryList.totalPages}
                        totalRegisters={queryList.totalResults}
                        currentPage={queryList.pageIndex}
                    />
                <ContasListData
                    onCardClick={onCardClick}
                    carregando={carregando}
                    triggetList={() => search(queryList.pageIndex)}
                    list={filteredList}
                />
            </Grid>
        </Box>
    )
}