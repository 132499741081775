import { DefaultCard, useDefaultCardStyles } from "../components/default-card";
import { useStyles } from "./card-importacao-styles";
import { Tooltip, Grid, Typography, Box, FormControlLabel, Checkbox } from '@material-ui/core'
import { toDateString } from "utils/to-date";
import { ButtonIcon } from "views/components/controles";
import { toCurrency } from "utils/toCurrency";
import { EditarIcon } from "views/components/icons";
import { ImportacaoListModel } from "views/pages/private/importacao/components/importacao-list/importacao-list";

interface CardImportacaoProps {
    model: ImportacaoListModel;
    onClick: (model: ImportacaoListModel) => void;
    selecionado?: boolean;
    onSelect: (model: ImportacaoListModel) => void;
}

export const CardImportacao = ({
    model,
    onClick,
    selecionado,
    onSelect
}: CardImportacaoProps) => {
    const classes = useDefaultCardStyles({});
    const cardClasses = useStyles();

    return (
        <>
            <DefaultCard onClick={() => {
                onClick(model);
            }}>
                <FormControlLabel
                    onClick={(e) => e.stopPropagation()}
                    control={<Checkbox color='secondary' checked={selecionado} />}
                    value={selecionado} onChange={() => {
                        onSelect(model)
                    }}
                    label=''
                />
                <div className={`${classes.cardContent} ${cardClasses.container}`}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={2}>
                            <Typography color="textSecondary" variant="caption">
                                Banco
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            >
                                {model.nomeBanco}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textSecondary" variant="caption">
                                Data Emissão
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {toDateString(model.dataEmissao)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textSecondary" variant="caption">
                                Data de Vencimento
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {toDateString(model.dataVencimento)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2} className={cardClasses.data}>
                            <Typography color="textSecondary" variant="caption">
                                Valor
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {toCurrency(model.valorTitulo)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} className={cardClasses.data}>
                            <Typography color="textSecondary" variant="caption">
                                Pagador
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.nomePagador}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Box className={cardClasses.iconsContainer}>
                    <Tooltip title='Editar Boleto'>
                        <Box>
                            <ButtonIcon
                                icon={<EditarIcon tipo='BUTTON' />}
                                onClick={() => {}}
                            />
                        </Box>
                    </Tooltip>
                </Box>
            </DefaultCard>
        </>
    );
};