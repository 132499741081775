import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center'
    },
    cancelarButton: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.error.dark,
        color: theme.palette.error.dark,
        '& svg': {
            fill: theme.palette.error.dark
        }
    }
}));