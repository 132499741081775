import * as Yup from 'yup';
import { useValidationYupSenha } from '../../../form-validations'
export const useFormLoginValidation = () => {
    const { senhaYup } = useValidationYupSenha()
    const FormLoginYupValidation = Yup.object().shape({
        email: Yup.string().required('O Usuário é obrigatório.'),
        senha: senhaYup(),
    });

    return {
        FormLoginYupValidation
    }
}

