import { Box } from "@material-ui/core";
import { CircularLoading } from "views/components";
import { InicialPageSearchProps } from "../inicial-page-search/inicial-page-search-props";
import { useDefaultPageStyles } from "views/theme/pages-styles";
import { useCallback, useEffect, useState } from "react";
import { useToastSaurus } from "services/app";
import { ApiListModel } from "model/api/api-list-model/api-list-model";
import { Paginacao } from "views/components/paginacao";
import { LoteListModel } from "model/api/gestao/lote/lote-list-model";
import { RegistroListData } from "./registro-list-data";
import { useGetRemessa } from "data/api/gestao/remessa/get-remessa";
import { isEmpty } from "lodash";

interface ArquivoListProps {
    searchProps: InicialPageSearchProps;
}

export const RegistroList = ({
    searchProps
}: ArquivoListProps) => {

    const classes = useDefaultPageStyles();
    const { showToast } = useToastSaurus();

    const { getRemessa, carregando } = useGetRemessa()

    const [queryList, setQueryList] = useState<ApiListModel<LoteListModel>>({
        list: [],
        pageIndex: 1,
        pageSize: 0,
        totalPages: 0,
        totalResults: 0
    })

    

    const search = useCallback(async (page: number) => {
        try {

            const searchParams = new URLSearchParams();

            if(!isEmpty(searchProps.dataInicial)){
                searchParams.append('dInicio', searchProps.dataInicial)
            }

            if(!isEmpty(searchProps.dataFinal)){
                searchParams.append('dFim', searchProps.dataFinal)
            }

            searchParams.append('Page', page.toString())
            searchParams.append('PageSize', '30')

            const res = await getRemessa(searchParams.toString());

            if (res.erro) {
                throw res.erro
            }

            if (!res.resultado?.data) {
                throw new Error('Erro ao listar remessas. Tente novamente.')
            }

            if (
                res.resultado?.data.pageIndex > res.resultado?.data.totalPages &&
                res.resultado?.data.totalResults > 0
            ) {
                search(res.resultado.data.totalPages);
                return;
            }

            setQueryList(res.resultado.data)


        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getRemessa, searchProps.dataFinal, searchProps.dataInicial, showToast])

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryList.totalPages || newPage > 0) {
                search(newPage);
            }
        },
        [queryList.totalPages, search]
    );

    useEffect(() => {
        search(1)
    }, [search])

    const onCardClick = useCallback((model: LoteListModel) => {

    }, [])


    return (
        <Box className={classes.defaultContainer}>
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Box className={classes.list}>
                <Paginacao
                    pageChanged={pageChanged}
                    totalPages={queryList.totalPages}
                    totalRegisters={queryList.totalResults}
                    currentPage={queryList.pageIndex}
                />
                <Box className={classes.listData}>
                    <RegistroListData
                        carregando={carregando}
                        list={queryList.list}
                        onCardClick={onCardClick}
                    />
                </Box>
                {/* <InfoBoletos boletos={queryList.list.reduce<BoletoListModel[]>((prev, curr) => {
                    return [...prev, ...curr.boletos]
                }, [])}>
                    <DownloadLotes/>
                </InfoBoletos> */}
            </Box>
        </Box>
    )
}