import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/use-cases/events/event-tools";
import { AppEventEnum } from "model/enums/enum-event-app";
import { LoteListModel } from "model/api/gestao/lote/lote-list-model";
import { DialogDetalhesLote } from "views/components/dialog/dialog-detalhes-lote/dialog-detalhes-lote";

class DetalhesLoteDialogProps {
    constructor(
        public aberto: boolean = false,
        public model: LoteListModel = new LoteListModel()
    ) { }
}


export const DetalhesLoteDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<DetalhesLoteDialogProps>(
        new DetalhesLoteDialogProps()
    );

    const modalAlterado = useCallback(({ aberto, model }: DetalhesLoteDialogProps) => {
        setModalState({
            aberto,
            model
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogDetalhesLote, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogDetalhesLote, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogDetalhesLote aberto={modalState.aberto} model={modalState.model} />
            )}
        </>
    )
}