export class EmpresaEnderecoFormModel {
    constructor(
        public cep: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public cMunicipio: string = '',
        public uf: string = '',
    ) { }
}