import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ApiListModel } from 'model/api/api-list-model/api-list-model';
import { BoletoHistoricoModel } from 'model/api/gestao/boleto/boleto-historico-model';
import { useSessaoAtual } from 'services/app';


export function useGetBoletosHistorico() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<BoletoHistoricoModel>>();
    const { getEmpresa } = useSessaoAtual();

    const getBoletosHistorico = useCallback(
        (query: string) =>
            invocarApi({
                method: 'GET',
                url: `/${VariaveisAmbiente.apiVersion}/Empresa/${getEmpresa().Id}/boleto/historico?${query}`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            }),
        [getEmpresa, invocarApi],
    );
    return {
        ...outrasPropriedades,
        getBoletosHistorico,
    };
}