import { Grid, Typography, Button, Box } from '@material-ui/core'
import { FormContaBancaria } from 'views/components/form/master/conta-bancaria/form-conta-bancaria'
import { useStyles } from '../conta-bancaria-crud-styles'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EnumBanco } from 'model/enums/enum-banco';
import { BancoLogoMock } from 'data/mocks/banco-logo-mock';
import { BancoMock } from 'data/mocks/banco-mock';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { ContaBancariaFormModel } from 'model/app/forms/master/conta-bancaria-form';
import { useCadastros } from 'services/app/hooks/cadastros';
import { DefaultFormRefs } from 'views/components/form/utils';
import { SalvarIcon } from 'views/components/icons';
import { usePostContaBancaria } from 'data/api/gestao/conta-bancaria/post-conta-bancaria';
import { useShowAviso } from 'services/app/hooks/show-aviso';

export const ContaBancariaCadastro = () => {

    const { showAviso } = useShowAviso();
    const { fecharDialogContaBancaria } = useCadastros();
    const { postContaBancaria, carregando } = usePostContaBancaria();

    const classes = useStyles({tela: 1});

    const handleSubmit = useCallback(async (model: ContaBancariaFormModel) => {
        try {
            model.nomeBanco = BancoMock.find(banco => banco.Key === model.banco)?.Value || '';
            const res = await postContaBancaria(model);

            if(res.erro) throw res.erro

            const nome = BancoMock.find(banco => banco.Key === banco)?.Value || ''

            showAviso('success', `Conta Bancaria ${nome} cadastrada com sucesso!`);
            fecharDialogContaBancaria();
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [fecharDialogContaBancaria, postContaBancaria, showAviso])

    const [banco, setBanco] = useState<EnumBanco>(EnumBanco.Safra)
    const formContaRef = useRef<DefaultFormRefs<ContaBancariaFormModel>>(null)

    const dadosBanco = useMemo(() => {
        return {
            logo: BancoLogoMock.find(logo => logo.Key === banco)?.Value || SemImagem,
            nome: BancoMock.find(banco => banco.Key === banco)?.Value || 'Banco não identificado'
        }
    }, [banco])

    useEffect(() => {
        formContaRef.current?.fillForm(new ContaBancariaFormModel())
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className={classes.dialogTitle}>
                <img
                    src={dadosBanco.logo}
                    alt={dadosBanco.nome}
                />
                <Typography variant='h4'>Cadastrar Nova Conta</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box className={classes.formConta}>
                    <FormContaBancaria
                        loading={carregando}
                        showLoading
                        onSubmit={handleSubmit}
                        setBanco={setBanco}
                        ref={formContaRef}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
                <Button variant='contained' color='primary' onClick={() => formContaRef.current?.submitForm()}>
                    <SalvarIcon tipo='BUTTON_PRIMARY' />
                    Salvar
                </Button>
            </Grid>
        </Grid>
    )
}