import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ApiListModel } from 'model/api/api-list-model/api-list-model';
import { LoteListModel } from 'model/api/gestao/lote/lote-list-model';
import { useSessaoAtual } from 'services/app';


export function useGetRemessa() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<LoteListModel>>();
    const { getEmpresa } = useSessaoAtual();

    const getRemessa = useCallback(
        (query: string) =>
            invocarApi({
                method: 'GET',
                url: `/${VariaveisAmbiente.apiVersion}/Empresa/${getEmpresa().Id}/remessa?${query}`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            }),
        [getEmpresa, invocarApi],
    );
    return {
        ...outrasPropriedades,
        getRemessa,
    };
}