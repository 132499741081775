import { EnumBanco } from "model/enums/enum-banco";

export class ContaBancariaListModel{
    constructor(
        public id: string = '',
        public contratoId: string = '',
        public empresaId: string = '',
        public banco: EnumBanco = EnumBanco.Safra,
        public nomeBanco: string = '',
        public agencia: string = '',
        public digitoAgencia: string = '',
        public conta: string = '',
        public digitoConta: string = '',
        public documentoTitular: string = '',
        public nomeTitular: string = '',
        public tpConta: number = 0,
        public padrao: boolean = false,
    ) {}
}