import { makeStyles, Theme } from "@material-ui/core";
import { hexToRGB } from "utils/colors";

interface StylesProps{
    vinculado?: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    container: {
        '& p': {
            fontSize: '12px !important',
            fontWeight: 500,
        }
    },
    logo: {
        height: 30,
        width: 30,
        alignSelf: 'center',
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    iconsContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: theme.spacing(1),
        borderLeft: `3px solid rgba(0, 0, 0, .1)`,
        [theme.breakpoints.down('md')]: {
            alignItems: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    statusVinculo: {
        marginTop: theme.spacing(1),
        width: 'fit-content',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        color: ({vinculado}) => vinculado ? theme.palette.success.dark : theme.palette.error.dark,
        background: ({vinculado}) => vinculado ? hexToRGB(theme.palette.success.dark, '0.4') : hexToRGB(theme.palette.error.dark, '0.4'),
    }
}))