import { Box } from "@material-ui/core"
import { useCallback, useEffect, useRef, useState } from "react"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { useStyles } from '../accordion-configuracoes-styles'
import { useSessaoAtual } from "services/app"
import { DefaultFormRefs } from "views/components/form/utils"
import { picker } from "utils/picker"
import { FormEmpresaEdit } from "views/components/form/master/empresa-edit/form-empresa-edit"
import { EmpresaEditFormModel } from "model/app/forms/master/empresa-edit-form-model"
import { useGetEmpresaId } from "data/api/gestao/empresa/get-empresa-id"
import { usePutEmpresa } from "data/api/gestao/empresa/put-empresa"
import { EmpresaModel } from "model/api/gestao/master/empresa"
import { useShowAviso } from "services/app/hooks/show-aviso"

export const AccordionEmpresa = () => {

    const classes = useStyles();
    const { showAviso } = useShowAviso();

    const { getEmpresaId, carregando: carregandoGet } = useGetEmpresaId();
    const { putEmpresa, carregando: carregandoPut } = usePutEmpresa();

    const { getEmpresa } = useSessaoAtual();

    const [aberto, setAberto] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const formRef = useRef<DefaultFormRefs<EmpresaEditFormModel>>(null)

    const carregando = loading || carregandoGet || carregandoPut;

    const handleSubmit = useCallback(async (model: EmpresaEditFormModel) => {
        try {
            const empresaModel = picker<EmpresaModel>(model,new EmpresaModel());
            empresaModel.contratoId = getEmpresa().ContratoId;
            const res = await putEmpresa(empresaModel);

            if(res.erro) throw res.erro;

            showAviso('success', 'Dados atualizados')
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [getEmpresa, putEmpresa, showAviso])

    const getEmpresaWrapper = useCallback(async () => {
        try{
            const res = await getEmpresaId(getEmpresa().Id)

            if(res.erro) throw res.erro

            const model = picker<EmpresaEditFormModel>(res.resultado?.data, new EmpresaEditFormModel());

            formRef.current?.fillForm(model)
        }catch(e: any){
            showAviso('error', e.message)
        }
    }, [getEmpresa, getEmpresaId, showAviso])

    useEffect(() => {
        if(aberto)
            getEmpresaWrapper();
    }, [aberto, getEmpresaWrapper])

    return (
        <AccordionSaurus
            labelPrimary="Dados da Empresa"
            expanded={aberto}
            onChange={() => setAberto(prev => !prev)}
        >
            <Box className={classes.usuarioFormContainer}>
                <Box>
                    <FormEmpresaEdit
                        loading={carregando}
                        onSubmit={handleSubmit}
                        setCarregandoExterno={setLoading}
                        showLoading
                        ref={formRef}
                    />
                </Box>
            </Box>
        </AccordionSaurus>
    )
}