import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { AlterarSenhaModel } from 'model/api/gestao/master/alterar-senha';
import { VariaveisAmbiente } from 'config';

export function usePutAlterarSenha() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAlterarSenha = useCallback(
        (senhaModel: AlterarSenhaModel) =>
            invocarApi({
                url: `/${VariaveisAmbiente.apiVersion}/Usuario/meu-usuario/alterar-senha`,
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(senhaModel),
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAlterarSenha,
    };
}
