import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    fecharButton: {
        marginRight: -10,
        width: 38,
        height: 38,
        borderRadius: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        "& svg": {
            padding: 0,
            margin: 0,
            width: 28,
            fill: theme.palette.secondary.main,
            height: 28,
        },
    },
}));
