import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { BoletoFormModel } from 'model/api/gestao/boleto/boleto-add-form-model';
import { useSessaoAtual } from 'services/app';
import { BoletoGetModel } from 'model/api/gestao/boleto/boleto-get-model';

export function usePostBoleto() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<BoletoGetModel>();

    const { getEmpresa } = useSessaoAtual();

    const postBoleto = useCallback(
        (model: BoletoFormModel) =>{
            model.empresaId = getEmpresa().Id;
            return invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/Empresa/${getEmpresa().Id}/boleto/novo-boleto`,
                data: model,
                enviarTokenUsuario: true
            })
        },
        [getEmpresa, invocarApi],
    );
    return {
        ...outrasPropriedades,
        postBoleto,
    };
}
