import { EnumRegime } from "../../../enums/enum-regime";
import { guidEmpty } from 'utils/guid-empty';
export class EmpresaEditFormModel {
  constructor(
    public cpfcnpj: string = "",
    public ierg: string = "",
    public im: string = "",
    public razaoSocial: string = "",
    public nomeFantasia: string = "",
    public dNascimento: Date | string | undefined = undefined,
    public cnae: string = "",
    public regime: EnumRegime = EnumRegime.NAOSEAPLICA,
    public fone: string = "",
    public site: string = "",
    public infAdic: string = "",
    public cep: string = "",
    public logradouro: string = "",
    public numero: string = "",
    public complemento: string = "",
    public bairro: string = "",
    public municipio: string = "",
    public cMunicipio: string = "",
    public uf: string = "",
    public segmento: string = "",
    public segmentoId: string = guidEmpty(),
    public email: string = "",
    public id: string = guidEmpty()
  ) { }
}
