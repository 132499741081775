import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  imgTitulo: {
    width: '36px',
    height: '36px',
    marginBottom: -6,
    marginRight: 6,
    fill: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      width: '36px',
      height: '36px',
      marginBottom: -6
    },
    [theme.breakpoints.up('md')]: {
      width: '40px',
      height: '40px',
      marginBottom: -6
    },
    [theme.breakpoints.up('lg')]: {
      width: '50px',
      height: '50px',
      marginBottom: -8
    }
  },
  card: {
    boxShadow: '0px 52.9835px 137.757px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '52px 41px',
    borderRadius: '26.4918px',
    zIndex: 3,
    background: theme.palette.background.paper,
    minWidth: 682,
    [theme.breakpoints.down('sm')]: {
      padding: '52px 21px',
      minWidth: 'auto',
      '& h3': {
        fontSize: '2rem',
      }
    },
    '& h3': {
      fontWeight: 500,
      fontSize: '2.5rem'
    }
  },
  containerReenviar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: 0,
    '& button': {
      boxShadow: '1px 1px 4px 0px #00000026'
    }
  },
  email: {
    marginTop: theme.spacing(1),
    fontWeight: 500
  }
}));
