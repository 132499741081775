
import { useMemo } from 'react';
import * as Yup from 'yup';
import { useValidationYupSenhaConfirmacao } from '../../../form-validations/yup/confirmar-senha';
import { useValidationYupSenha } from '../../../form-validations/yup/senha';


export const useFormSenhaValidation = () => {

  const { senhaYup } = useValidationYupSenha()
  const { senhaConfirmacaoYup } = useValidationYupSenhaConfirmacao()

  const FormSenhaValidationYup = useMemo(() => {
    return Yup.object().shape({
      senhaAtual: senhaYup(),
      senhaNova: senhaYup(),
      senhaConfirmacao: senhaConfirmacaoYup('senhaNova')
    });
  }, [senhaYup, senhaConfirmacaoYup])

  return {
    FormSenhaValidationYup,
  }
}