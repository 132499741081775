import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { BoletoFormModel } from 'model/api/gestao/boleto/boleto-add-form-model';
import { useSessaoAtual } from 'services/app';

export function useDeleteBoleto() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<BoletoFormModel>();
    const { getEmpresa } = useSessaoAtual();

    const deleteBoleto = useCallback(
        (boletoId: string) =>
            invocarApi({
                method: 'DELETE',
                url: `/${VariaveisAmbiente.apiVersion}/Empresa/${getEmpresa().Id}/boleto/${boletoId}`,
                enviarTokenUsuario: true
            }),
        [getEmpresa, invocarApi],
    );
    return {
        ...outrasPropriedades,
        deleteBoleto,
    };
}
