import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormParametrizacaoBoletosValidation = () => {
    const FormParametrizacaoBoletosValidation = useMemo(() => {
        return (
            Yup.object().shape({
                numDiasProtesto: Yup.string().test({
                    message: 'Informe os Dias de Protesto',
                    test: (value, context) => {
                        if (context.parent.protesto) {
                            return !isEmpty(value)
                        }
                        return true
                    }
                }).typeError('Informe os Dias de Protesto'),
            })
        )
    }, [])

    return {
        FormParametrizacaoBoletosValidation,
    }
}