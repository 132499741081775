import { makeStyles, Theme } from "@material-ui/core";
import { EnumStatusBoleto } from "model/enums/enum-status-boleto";

const retornarCorStatus = (situacao: EnumStatusBoleto, theme: Theme): string => {
    switch (situacao) {
        case EnumStatusBoleto.Aceito:
            return theme.palette.success.light;
        case EnumStatusBoleto.Abortada:
            return theme.palette.error.light;
        case EnumStatusBoleto.ErroInterno:
            return theme.palette.error.main;
        case EnumStatusBoleto.Autorizada:
            return theme.palette.success.main;
        case EnumStatusBoleto.Pendente:
            return theme.palette.warning.light;
        case EnumStatusBoleto.Rejeitada:
            return theme.palette.error.dark;
        case EnumStatusBoleto.ProcessandoEstorno:
            return theme.palette.info.main;
        case EnumStatusBoleto.Estornada:
            return theme.palette.warning.dark;
        case EnumStatusBoleto.Expirada:
            return theme.palette.warning.main;
        default:
            return theme.palette.info.light;
    }
}


interface StylesProps {
    status: EnumStatusBoleto;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    container: {
        '& p': {
            fontSize: '12px !important',
            fontWeight: 500,
        }
    },
    valores: {
        [theme.breakpoints.up('lg')]: {
            textAlign: 'right'
        }
    },
    data: {
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center'
        }
    },
    tagColor: {
        background: ({ status }) => retornarCorStatus(status, theme)
    },
    iconsContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: theme.spacing(1),
        borderLeft: `3px solid rgba(0, 0, 0, .1)`,
        [theme.breakpoints.down('md')]: {
            alignItems: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    dialogSelecionarMobile: {
        '& button': {
            height: 80
        }
    }
}))