import { makeStyles, Theme } from "@material-ui/core";
import { hexToRGB } from "utils/colors";

interface StylesProps{
    color?: 'primary' | 'secondary' | 'error' | 'success' | 'info';
    border?: boolean;
}

export const useButtonIconStyles = makeStyles<Theme, StylesProps>((theme) => ({
    buttonIcon: {
        border: ({border}) => border? '1px solid' : 'unset',
        borderColor: ({color}: StylesProps) => color ? theme.palette[color].dark : undefined,
        '& svg': {
            marginRight: 0,
            fill: ({color}: StylesProps) => color ? theme.palette[color].dark : undefined
        },
        '&:hover': {
            background: ({color}) => color ? hexToRGB(theme.palette[color].dark, '0.1') : undefined
        }
    }
}))