import { EnumBanco } from "model/enums/enum-banco";
import { guidEmpty } from "utils/guid-empty";

export class ContaBancariaFormModel {
    constructor(
        public id: string = guidEmpty(),
        public banco: EnumBanco = EnumBanco.Safra,
        public nomeBanco: string = '',
        public agencia: string = '',
        public digitoAgencia: string = '',
        public conta: string = '',
        public digitoConta: string = '',
        public documentoTitular: string = '',
        public nomeTitular: string = '',
        public tpConta: number = 0,
        public padrao: boolean = false,
    ) { }
}