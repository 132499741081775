import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { Box, Button, Grid, Typography, useTheme } from "@material-ui/core";
import { PublicPageHeader } from "views/components/headers";
import { FormRecuperarSenhaRetorno } from "views/components/form/master/recuperar-senha-retorno/form-recuperar-senha-retorno";
import { FormRecuperarSenhaConfirmar } from "views/components/form/master/recuperar-senha-confirmar/form-recuperar-senha-confirmar";
import { RecuperarSenhaConfirmarFormModel, RecuperarSenhaRetornoFormModel } from "model/app";
import { CircularLoading, makeUtilClasses } from "views";
import { DefaultFormRefs } from "views/components/form/utils/form-default-props";
import { AprovacaoIcon, OkIcon, VoltarIcon } from "views/components/icons";
import { useStyles } from "./recuperar-senha-retorno-page-styles";
import { usePostIdentidadeValidarRedefinicao, usePostIdentidadeAtualizarSenha, IdentidadeValidarRedefinicaoProps, IdentidadeAtualizarSenhaProps, usePostIdentidadeRedefinirSenha } from "data/api/gestao/identidade";
import { GestaoStorageKeys, useGestaoStorage, useToastSaurus } from "services/app";
import { useConfirm } from 'material-ui-confirm';
import { DividerSaurus } from "views/components/divider/divider-saurus";
import { ReloadIcon } from "views/components/icons/reload-icon";
import { AmpulhetaIcon } from "views/components/icons/ampulheta-icon";
import { isEmpty } from "lodash";


const RecuperarSenhaRetornoPage = () => {
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const confirm = useConfirm();
    const { showToast } = useToastSaurus();

    const theme = useTheme();
    const history = useHistory()
    const { getRegistro, delRegistro } = useGestaoStorage();
    const { postIdentidadeValidarRedefinicao, carregando: carregandoValidacao } = usePostIdentidadeValidarRedefinicao();
    const { postIdentidadeAtualizarSenha, carregando: carregandoAtualizacao } = usePostIdentidadeAtualizarSenha();
    const { postIdentidadeRedefinirSenha, carregando: carregandoRedefinir } = usePostIdentidadeRedefinirSenha();

    const [passo, setPasso] = useState(1);
    const [codigoAtual, setCodigo] = useState<string>('');
    const recuperarSenhaRetornoRefs = useRef<DefaultFormRefs<RecuperarSenhaRetornoFormModel>>(null);
    const recuperarSenhaConfirmarRefs = useRef<DefaultFormRefs<RecuperarSenhaConfirmarFormModel>>(null);
    const [podeReenviar, setPodeReenviar] = useState(true);


    const getEmail = useCallback((): string => {
        return getRegistro(GestaoStorageKeys.EmailRecuperacaoSenha, true);
    }, [getRegistro]);

    useEffect(() => {
        const email = getEmail();
        if (isEmpty(email)) {
            showToast("error", "E-mail de recuperação não Identificado");
            history.replace('/recuperar-senha');
        }

        setPasso(1);

    }, [getEmail, showToast, history]);


    useEffect(() => {
        if (passo === 1) {
            recuperarSenhaConfirmarRefs.current?.resetForm();
            recuperarSenhaRetornoRefs.current?.fillForm(new RecuperarSenhaRetornoFormModel(''));
        } else if (passo === 2) {
            recuperarSenhaConfirmarRefs.current?.fillForm(new RecuperarSenhaConfirmarFormModel(getEmail(), '', ''));
        }
    }, [passo, getEmail]);

    const limparStorage = useCallback(() => {
        delRegistro(GestaoStorageKeys.EmailRecuperacaoSenha, true);
    }, [delRegistro]);

    const voltar = () => {
        if (passo === 2) {
            confirm({
                title: 'Cancelar troca de Senha?',
                description: 'Deseja realmente cancelar a troca da senha do email "' + getEmail() + '"',
                cancellationText: 'Voltar',
                confirmationText: 'Cancelar Recuperação de Senha',
            })
                .then(() => {
                    limparStorage();
                    history.replace('/login');
                })

        } else {
            limparStorage();
            history.replace('/recuperar-senha');
        }
    };


    const validarCodigo = async (model: RecuperarSenhaRetornoFormModel) => {
        try {
            var ret = await postIdentidadeValidarRedefinicao(
                new IdentidadeValidarRedefinicaoProps(getEmail(), model.codigo)
            );

            if (ret.erro)
                throw ret.erro;

            setCodigo(model.codigo);
            setPasso(2);

        } catch (e: any) {
            showToast("error", e.message);
        }
    };

    const confirmarSenha = async (model: RecuperarSenhaConfirmarFormModel) => {
        try {
            var ret = await postIdentidadeAtualizarSenha(new IdentidadeAtualizarSenhaProps(model.email, model.senha, model.confirmar, codigoAtual));
            if (ret.erro)
                throw ret.erro;

            showToast("success", "A senha do e-mail '" + model.email + "' foi redefinida com Sucesso!");
            history.replace('/login');

        } catch (e: any) {
            showToast("error", e.message);
        }
    };

    const recuperarSenha = async () => {
        try {
            const email = getEmail();
            var ret = await postIdentidadeRedefinirSenha(email);

            setPodeReenviar(false);
            setTimeout(() => {
                setPodeReenviar(true);
            }, 30000);

            if (ret.erro)
                throw ret.erro;

            showToast("info", "Um e-mail foi reenviado para você com o código de redefinição, acesse-o para continuar a redefinição de senha.");

        } catch (e: any) {
            showToast("error", e.message);
        }
    };

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={utilClasses.contentCenter}>
                <Grid container className={utilClasses.rootContainer}>
                    {
                        carregandoAtualizacao || carregandoValidacao ?
                            <CircularLoading tipo="FULLSIZED" />
                            : null
                    }
                    <Grid item xs={12} className={classes.card}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h3" color="primary" align='center'>
                                    Redefinir Senha
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={3}>
                                    <Typography variant="body1" color='primary' align='center'>
                                        {passo === 1 && ("Insira o código, que foi enviado no seu e-mail.")}
                                        {passo === 2 && ("A nova senha precisa ter 6 caracteres.")}
                                    </Typography>
                                </Box>
                            </Grid>
                            {passo === 1 && (
                                <>
                                    <Grid item xs={12} className={classes.containerReenviar}>
                                        <Button
                                            tabIndex={-1}
                                            disabled={!podeReenviar || carregandoRedefinir}
                                            onClick={() => { recuperarSenha() }}
                                            size='small'
                                        >
                                            {podeReenviar ? <ReloadIcon tipo='BUTTON' /> : <AmpulhetaIcon tipo='BUTTON' />}
                                            <Typography variant="body1" color="primary">
                                                {podeReenviar ? 'Reenviar Código' : 'Aguarde para reenviar o código novamente.'}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            align='center'
                                            color='primary'
                                            className={classes.email}
                                        >{getEmail() || ''}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormRecuperarSenhaRetorno showLoading={false} ref={recuperarSenhaRetornoRefs} loading={carregandoValidacao} onSubmit={(model) => { validarCodigo(model); }} />
                                        <Box mt={1}>
                                            <Grid spacing={2} container justifyContent='flex-end'>
                                                <Grid item xs={12}>
                                                    <DividerSaurus />
                                                </Grid>
                                                <Grid item xs={5} md={3}>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        variant="outlined"
                                                        fullWidth={true}
                                                        disabled={carregandoValidacao}
                                                        onClick={voltar}>
                                                        <VoltarIcon fill={theme.palette.primary.main} tipo="BUTTON" />
                                                        Voltar
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={7} md={7}>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        variant="contained"
                                                        fullWidth={true}
                                                        disabled={carregandoValidacao}
                                                        onClick={() => { recuperarSenhaRetornoRefs.current?.submitForm(); }}
                                                    >
                                                        <AprovacaoIcon tipo="BUTTON_PRIMARY" />
                                                        Confirmar
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                            {passo === 2 && (
                                <Grid item xs={12}>
                                    <FormRecuperarSenhaConfirmar showLoading={false} ref={recuperarSenhaConfirmarRefs} loading={carregandoAtualizacao} onSubmit={(model) => { confirmarSenha(model); }} />
                                    <Box mt={5}>
                                        <Grid spacing={2} container justifyContent='flex-end'>
                                            <Grid item xs={12}>
                                                <DividerSaurus />
                                            </Grid>
                                            <Grid item xs={5} md={3}>
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth={true}
                                                    disabled={carregandoValidacao}
                                                    onClick={voltar}>
                                                    <VoltarIcon fill={theme.palette.primary.main} tipo="BUTTON" />
                                                    Voltar
                                                </Button>
                                            </Grid>
                                            <Grid item xs={7} md={7}>
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    variant="contained"
                                                    fullWidth={true}
                                                    disabled={carregandoValidacao}
                                                    onClick={() => { recuperarSenhaConfirmarRefs.current?.submitForm(); }}
                                                >
                                                    <OkIcon tipo="BUTTON_PRIMARY" />
                                                    Confirmar Alteração
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default RecuperarSenhaRetornoPage;