import { Grid, Typography, Box } from "@material-ui/core"
import { isEmpty } from "lodash"
import { PrivatePageHeaderProps } from "./header-private-page-props"
import { useStyles } from "./header-private-page-styles"
import classNames from "classnames"
import { useSessaoAtual } from "services/app"
import img from 'assets/img/sem-imagem.jpg'
import { useMemo } from "react"
import { useHistory } from "react-router"

export const PrivatePageHeader = (props: PrivatePageHeaderProps) => {
    const classes = useStyles();
    const {usuario} = useSessaoAtual();
    const {location} = useHistory();

    const titulo = useMemo(() => {
        if(location.pathname.includes('/boletos')){
            return usuario?.saudacao || 'Safra'
        }
        if(location.pathname.includes('/configuracoes')){
            return 'Configurações'
        }
        if(location.pathname.includes('/importacao')){
            return 'Adição de Boletos'
        }

        return usuario?.saudacao || 'Safra'
    }, [location.pathname, usuario?.saudacao])

    return (
        <>
            <Grid container className={classes.mainHeaderContainer}>
                <Grid item xs={12} >
                    <Grid container className={classes.topContainer} direction="row" justifyContent="space-between" spacing={1} alignItems="center">
                        <Grid item className={classes.flexContent}>
                            <Grid container spacing={1} alignItems="center">
                                {
                                    props.leftArea &&
                                    (<Grid item className={classes.flexFixed}>
                                        {props.leftArea}
                                    </Grid>)
                                }
                                {
                                    isEmpty(props.middleArea) &&
                                    (<Grid item className={classNames(classes.flexContent, classes.profileContainer)}>
                                        <Box className={classes.imageProfile}>
                                            <img src={!isEmpty(usuario?.imagem) ? usuario!.imagem : img} alt='Imagem de Perfil' />
                                        </Box>
                                        <Typography variant="h4" className={classes.title}>
                                            {titulo}
                                        </Typography>
                                    </Grid>)
                                }
                                {
                                    props.middleArea &&
                                    (<Grid item className={classes.flexContent}>
                                        {props.middleArea}
                                    </Grid>)
                                }
                            </Grid>
                        </Grid>
                        {
                            props.rightArea &&
                            (<Grid item className={classes.flexFixed}>
                                {props.rightArea}
                            </Grid>)
                        }
                    </Grid>
                    {
                        props.bottomArea &&
                        (<Grid className={classes.bottomContainer} item xs={12}>
                            {props.bottomArea}
                        </Grid>)
                    }
                </Grid>
            </Grid>
        </>
    )
}
