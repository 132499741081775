import { forwardRef, ReactNode, useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Fade,
  Grid,
  Divider,
  Box,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { CloseIcon } from 'views/components/icons/close-icon';
import { useStyles } from './dialog-saurus-styles';
import { useThemeQueries } from 'views/theme';

export interface DialogSaurusProps {
  aberto?: boolean;
  children?: ReactNode;
  icone?: ReactNode;
  carregando?: boolean;
  titulo?: string | React.ReactNode;
  colorTitulo?: string;
  centralizarTitulo?: boolean;
  tamanho?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  zIndex?: string;
  isButtonTitleClose?: boolean;
  endAdornment?: JSX.Element;
  fullScreen?: boolean;
  bottomArea?: ReactNode;
  isDivider?: boolean;
  handleClickClose?: () => void;
}
const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Fade timeout={500} ref={ref} {...props} />;
});

export const DialogSaurus = ({
  aberto,
  children,
  icone,
  carregando,
  centralizarTitulo,
  colorTitulo,
  titulo,
  tamanho,
  zIndex,
  isButtonTitleClose,
  endAdornment,
  fullScreen,
  bottomArea,
  isDivider,
  handleClickClose,
}: DialogSaurusProps) => {
  const classes = useStyles();
  const { theme } = useThemeQueries();

  const getTitulo = useCallback(() => {
    if (!titulo) return <div />

    if (typeof titulo === 'string') {
      return (
        <Typography variant="h6">
          <strong style={{ color: colorTitulo ? colorTitulo : theme.palette.primary.main }}>
            {titulo}
          </strong>
        </Typography>
      )
    }

    return titulo
  }, [colorTitulo, theme.palette.primary.main, titulo])

  return (
    <Dialog
      maxWidth={tamanho || 'lg'}
      open={aberto || false}
      fullWidth
      disableScrollLock
      keepMounted
      disableEscapeKeyDown
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      ref={(el: any) => {
        if (el && (zIndex || '').length > 0) {
          el.style.setProperty('z-index', zIndex, 'important');
        }
      }}
    >
      <DialogTitle disableTypography style={{ padding: '16px' }}>
        {isButtonTitleClose ? (
          <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
            {getTitulo()}
            <Box display='flex' gridGap={4} alignItems='center' paddingX={3}>
              {endAdornment}
              <Grid onClick={handleClickClose} className={classes.fecharButton}>
                <CloseIcon tipo="INPUT" />
              </Grid>
            </Box>
          </Grid>
        ) : (
          <Grid
            style={{
              display: 'flex',
              justifyContent: centralizarTitulo ? 'center' : 'space-between',
              alignItems: 'center',
            }}
          >
            {icone ? icone : null}
            {getTitulo()}
            {endAdornment || <></>}
          </Grid>
        )}
        {isDivider &&
          <Divider style={{ background: theme.palette.secondary.main, height: '1px' }} />
        }
      </DialogTitle>
      <DialogContent style={
        fullScreen ? {
          padding: '1rem'
        } : {paddingBottom: theme.spacing(2)}
      }>
        {carregando ? <CircularLoading tipo="FULLSIZED" /> : children}
      </DialogContent>
      {bottomArea && <div>
        {bottomArea}
      </div>}
    </Dialog>
  );
};
