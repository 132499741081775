import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { BoletoFormModel } from 'model/api/gestao/boleto/boleto-add-form-model';
import { useSessaoAtual } from 'services/app';
import { ContaBancariaConfiguracaoModel } from 'model/api/gestao/conta-bancaria-configuracao/conta-bancaria-configuracao-model';

export function usePostContaBancariaConfiguracao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<BoletoFormModel>();
    const { getEmpresa } = useSessaoAtual();

    const empresaId = getEmpresa().Id;

    const postContaBancariaConfiguracao = useCallback(
        (contaBancariaId: string, model: ContaBancariaConfiguracaoModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/empresa/${empresaId}/conta-bancaria/${contaBancariaId}/configuracao-boleto`,
                data: {
                    ...model,
                    empresaId: empresaId,
                },
                enviarTokenUsuario: true
            }),
        [empresaId, invocarApi],
    );
    return {
        ...outrasPropriedades,
        postContaBancariaConfiguracao,
    };
}