import { Box, Button } from "@material-ui/core"
import { useCallback, useRef, useState } from "react"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { useStyles } from '../accordion-configuracoes-styles'
import { useToastSaurus } from "services/app"
import { DefaultFormRefs } from "views/components/form/utils"
import { SalvarEditarIcon } from "views/components/icons"
import { FormSenha } from "views/components/form/master/senha/form-senha"
import { AlterarSenhaFormModel } from "model/app/forms/master/alterar-senha-form-model"
import { usePutAlterarSenha } from "data/api/gestao/usuario/put-alterar-senha"

export const AccordionSenha = () => {

    const classes = useStyles();
    const { showToast } = useToastSaurus();

    const { putAlterarSenha, carregando } = usePutAlterarSenha();

    const [aberto, setAberto] = useState<boolean>(false);

    const formRef = useRef<DefaultFormRefs<AlterarSenhaFormModel>>(null)

    const handleSubmit = useCallback(async (model: AlterarSenhaFormModel) => {
        try {
            const res = await putAlterarSenha(model);

            if(res.erro) throw res.erro

            showToast('success', 'Senha Atualizada')
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [putAlterarSenha, showToast])

    return (
        <AccordionSaurus
            labelPrimary="Alterar Senha"
            expanded={aberto}
            onChange={() => setAberto(prev => !prev)}
        >
            <Box className={classes.usuarioFormContainer}>
                <Box>
                    <FormSenha
                        onSubmit={handleSubmit}
                        showLoading
                        loading={carregando}
                        ref={formRef}
                    />
                    <Box className={classes.buttonContainer}>
                        <Button color='primary' variant='contained' onClick={formRef.current?.submitForm}>
                            <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                            Salvar
                        </Button>
                    </Box>
                </Box>
            </Box>
        </AccordionSaurus>
    )
}