import { makeStyles } from '@material-ui/core';

export const useDefaultPageStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[100],
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 auto',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: '100%',
    flex: 1,
    overflow: 'auto',
    gridGap: theme.spacing(1),
  },
  listData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexGrow: 1,
    overflowY: 'auto',
    paddingBottom: theme.spacing(1)
  },
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  defaultContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: theme.spacing(1),
    paddingBottom: 0
  },
  excelMobile: {
    marginRight: '8px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  acoes: {
    flex: '0 0 auto',
    padding: theme.spacing(2),
  },
  accordionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: 16,
    '&>*': {
      maxWidth: 1200
    }
  }
}));
