import { useCadastros } from "services/app/hooks/cadastros";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { Box } from "@material-ui/core";
import { LoteListModel } from "model/api/gestao/lote/lote-list-model";
import { useDefaultPageStyles } from "views/theme/pages-styles";
import { ArquivoListData } from "views/pages/private/inicial/components/arquivo-list/arquivo-list-data";
import { CardLote } from "views/components/cards/card-lote/card-lote";
import { useCallback, useEffect, useState } from "react";
import { useGetRemessaBoleto } from "data/api/gestao/remessa/get-remessa-boleto";
import { BoletoListModel } from "model/api/gestao/boleto/boleto-list-model";
import { useShowAviso } from "services/app/hooks/show-aviso";

interface DialogDetalhesLoteProps {
    aberto: boolean;
    model: LoteListModel;
}

export interface DetalhesLote{
    aceitos: number;
    rejeitados: number;
    cancelados: number;
    pagos: number;
    pendentes: number;
}

export const DialogDetalhesLote = ({
    aberto,
    model
}: DialogDetalhesLoteProps) => {

    const { fecharDialogDetalhesLote } = useCadastros();
    const classes = useDefaultPageStyles();
    const { showAviso } = useShowAviso();
    const { getRemessaBoleto, carregando } = useGetRemessaBoleto();

    const [boletos, setBoletos] = useState<BoletoListModel[]>([]);

    const getBoletosWrapper = useCallback(async () => {
        try{
            const res = await getRemessaBoleto(model.id);

            if(res.erro) throw res.erro

            setBoletos(res.resultado!.data.list)
        }catch(e: any){
            showAviso('error', e.message)
        }
    }, [getRemessaBoleto, model.id, showAviso])

    useEffect(() => {
        if(aberto){
            getBoletosWrapper();
        }
    }, [aberto, getBoletosWrapper])

    return <DialogSaurus
        tamanho='lg'
        isButtonTitleClose
        titulo="Detalhamento do Lote"
        aberto={aberto}
        handleClickClose={fecharDialogDetalhesLote}
    >
        <Box className={classes.list}>
            <CardLote model={model} onClick={() => {}}/>
            <ArquivoListData
                carregando={carregando}
                list={boletos}
                onCardClick={() => {}}
            />
        </Box>
    </DialogSaurus>
}