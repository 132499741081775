import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ContaBancariaListModel } from 'model/api/gestao/conta-bancaria/conta-bancaria-list-model';
import { useSessaoAtual } from 'services/app';


export function useGetContaBancariaById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ContaBancariaListModel>();
    const { getEmpresa } = useSessaoAtual();

    const empresaId = getEmpresa().Id;

    const getContaBancariaById = useCallback(
        (id: string) =>
            invocarApi({
                method: 'GET',
                url: `/${VariaveisAmbiente.apiVersion}/empresa/${empresaId}/conta-bancaria/${id}`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            }),
        [empresaId, invocarApi],
    );
    return {
        ...outrasPropriedades,
        getContaBancariaById,
    };
}