import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { EmpresaModel } from "model/api/gestao/master/empresa";
import { VariaveisAmbiente } from "config";

export function useGetEmpresaId() {
  const { invocarApi, ...outrasPropriedades } = useApiBase<EmpresaModel>();

  const getEmpresaId = useCallback(
    (empresaId: string) =>
      invocarApi({
        url: `${VariaveisAmbiente.apiVersion}/empresas/${empresaId}`,
        method: "GET",
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getEmpresaId,
  };
}
