import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainHeaderContainer: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: '0px 4px 4px 0px #00000040',
    position: "relative",
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "",
    maxHeight: 75,
    padding: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      boxShadow: 'none',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.grey[300],
    },
  },
  mainHeaderBackground: {
    position: 'fixed',
    zIndex: -1,
    top: 0,
    right: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: 0,

    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  bottomContainer: {
    zIndex: 1,
  },
  title: {
    fontWeight: 600,
    textShadow: "2px 2px 1px rgba(0,0,0,0.05)",
    fontSize: "1.4rem",
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  flexContent: {
    flex: 1,
    overflow: 'hidden',
    height: '100%'
  },
  profileContainer: {
    display: 'flex',
    gridGap: theme.spacing(4),
    alignItems: 'center'
  },
  imageProfile: {
    background: theme.palette.background.paper,
    borderRadius: '50%',
    width: 50,
    height: 50,
    '& img': {
      width: 50,
      height: 50,
      borderRadius: '50%'
    }
  },
  flexFixed: {
    flex: 0,
  },
  publicPageHeaderTopContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    "& svg:nth-child(2)": {
      marginTop: '20px',
    },
  },
  publicPageHeaderRightContainer: {
    position: 'fixed',
    zIndex: -1,
    right: 0,
    top: '50%',
    transform: `translateY(-50%)`,

  },
  publicPageHeaderBottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',

  },
  privatePageHeaderVersionControl: {
    position: 'fixed',
    bottom: 0,
    pointerEvents: "none",
    right: 0,
    opacity: 0.6,
    "& *": {
      float: 'left',
      marginRight: 3,
      color: '#000',

    },
    "& b": {
      fontSize: 10,
      fontWeight: "normal",
    },
    "& i": {
      fontSize: 10,
      fontWeight: "bold",
    }
  }
}));
