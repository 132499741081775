import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/use-cases/events/event-tools";
import { AppEventEnum } from "model/enums/enum-event-app";
import { DialogBoleto } from "views/components/dialog/dialog-boleto/dialog-boleto";
import { useLocation } from "react-router-dom";

class BoletoDialogProps{
    constructor(
        public aberto: boolean = false,
        public id?: string,
    ){}
}

const boletoRoute = `/gerenciar/boleto/`;
const inicialRoute = `/inicial`

export const BoletoDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<BoletoDialogProps>(
        new BoletoDialogProps()
    );

    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes(boletoRoute) && location.pathname.includes(inicialRoute)) {
            const posrota = location.pathname.split(boletoRoute)[1];
            const id = posrota
            if (posrota.length > 0 && (posrota.length === 36 || posrota.includes('adicionar'))) {
                setModalState(prev => ({
                    aberto: true,
                     id: id && id.length === 36 ? id : prev.id
                }));
                return;
            }
        }
    }, [location.pathname]);

    const modalAlterado = useCallback(({ aberto, id}: BoletoDialogProps) => {
        setModalState({
            aberto,
            id,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogBoleto, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogBoleto, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogBoleto aberto={modalState.aberto} id={modalState.id}/>
            )}
        </>
    )
}