import { Box } from "@material-ui/core";
import { CircularLoading } from "views/components";
import { useDefaultPageStyles } from "views/theme/pages-styles";
import { useCallback, useEffect, useState } from "react";
import { BoletoListModel } from "model/api/gestao/boleto/boleto-list-model";
import { ApiListModel } from "model/api/api-list-model/api-list-model";
import { ImportacaoListData } from "./importacao-list-data";
import { ImportacaoSearchBar } from "../importacao-search-bar/importacao-search-bar";
import { ImportacaoSearchProps } from "../importacao-search-bar/importacao-search-props";
import { InfoImportacao } from "../info-importacao/info-importacao";
import { ConfirmarImportacaoButton } from "./components/confirmar-importacao-button/confirmar-importacao-button";
import { useLocation } from "react-router-dom";
import { DialogVisualizarBoleto } from "views/components/dialog/dialog-visualizar-boleto/dialog-visualizar-boleto";
import { isEmpty } from "lodash";
import { useShowAviso } from "services/app/hooks/show-aviso";

export interface LocationProps {
    list: BoletoListModel[];
}

export interface ImportacaoListModel extends BoletoListModel {
    identificador: number;
}

interface DialogBoletoProps {
    model: ImportacaoListModel;
    aberto: boolean;
}

export const ImportacaoList = () => {

    const classes = useDefaultPageStyles();
    const { showAviso } = useShowAviso();
    const { state } = useLocation<LocationProps>()

    const [queryList, setQueryList] = useState<ApiListModel<ImportacaoListModel>>({
        list: [],
        pageIndex: 1,
        pageSize: 0,
        totalPages: 0,
        totalResults: 0
    })
    const [carregando, setCarregando] = useState<boolean>(false);

    const [selected, setSelected] = useState<ImportacaoListModel[]>([])
    const [searchProps, setSearchProps] = useState<ImportacaoSearchProps>(new ImportacaoSearchProps())

    const [dialogBoleto, setDialogBoleto] = useState<DialogBoletoProps>({
        aberto: false,
        model: { identificador: 0, ...new BoletoListModel() }
    })


    const search = useCallback(async (page: number) => {
        try {
            if (!state || state.list.length === 0) return

            setQueryList({
                list: state.list.map((boleto, i) => ({ ...boleto, identificador: i })),
                pageIndex: 1,
                pageSize: state.list.length,
                totalPages: 1,
                totalResults: state.list.length
            })

        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [showAviso, state])

    useEffect(() => {
        search(1)
    }, [search])

    const onSelect = useCallback((model: ImportacaoListModel) => {
        const isSelected = selected.some(boleto => boleto.identificador === model.identificador)
        if (isSelected) {
            const newSelected = selected.filter(boleto => boleto.identificador !== model.identificador)
            setSelected(newSelected)
            return
        }
        setSelected(prev => [...prev, model])
    }, [selected])

    const onCardClick = useCallback((model: ImportacaoListModel) => {
        setDialogBoleto({
            aberto: true,
            model
        })
    }, [])

    const onBoletoChange = useCallback((model: ImportacaoListModel) => {
        const boletos = queryList.list.map(boleto => {
            if (boleto.identificador === model.identificador) {
                return { ...model }
            }

            return { ...boleto }
        })

        setQueryList(prev => ({
            ...prev,
            list: boletos
        }))

        setSelected(prev => {
            return prev.map(boleto => {
                if (boleto.identificador === model.identificador) {
                    return { ...model }
                }
                return { ...boleto }
            })
        })

        setDialogBoleto(prev => ({ ...prev, aberto: false }))

        showAviso('success', 'Informações do boleto alteradas.')
    }, [queryList.list, showAviso])

    return (
        <>
            <ImportacaoSearchBar
                list={queryList.list}
                registros={queryList.totalResults}
                selected={selected}
                setSearchProps={setSearchProps}
                setSelected={setSelected}
            />
            <Box className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo='FULLSIZED' />}
                <Box className={classes.list}>
                    <Box className={classes.listData}>
                        <ImportacaoListData
                            carregando={false}
                            list={queryList.list.filter(boleto => {
                                if (!isEmpty(searchProps.query)) {
                                    return boleto.nomePagador.toLowerCase().includes(searchProps.query.toLowerCase())
                                }
                                return true
                            })}
                            onCardClick={onCardClick}
                            selecionados={selected}
                            onSelect={onSelect}
                        />
                    </Box>
                    <InfoImportacao
                        boletos={queryList.list}
                        selecionados={selected}
                    >
                        <ConfirmarImportacaoButton
                            selecionados={selected}
                            setCarregando={setCarregando}
                        />
                    </InfoImportacao>
                </Box>
            </Box>
            {dialogBoleto.aberto && (
                <DialogVisualizarBoleto
                    aberto={dialogBoleto.aberto}
                    model={dialogBoleto.model}
                    onClose={() => setDialogBoleto(prev => ({ ...prev, aberto: false }))}
                    onSubmit={onBoletoChange}
                />
            )}
        </>
    )
}