import { makeStyles } from "@material-ui/core";

export const useStepperStyles = makeStyles((theme) => ({
  stepper: {
    background: "transparent",
    width: "100%",
    justifyContent: "center",
    paddingLeft: "0",
    paddingRight: "0",
    "& svg": {
      width: "26px",
      height: "26px",
      fill: theme.palette.primary.contrastText,
    },
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      marginTop: theme.spacing(1),
    }
  },
  stepperContent: {
    display: 'block',
    flex: '0 0 auto',
  },
  stepFix: {
    flex: '1 1 100%',
    display: 'block',
    position: 'relative'
  },
  step: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  stepForm: {
    minHeight: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  stepTitleContent: {

  },
  stepTitle: {
    marginTop: 0,
    fontWeight: 500,
    "& svg": {
      width: '40px',
      height: '40px',
      marginBottom: -6,
      marginRight: 8,
      fill: theme.palette.primary.main,
      [theme.breakpoints.up('sm')]: {
        width: '40px',
        height: '40px',
        marginBottom: -6,
      },
      [theme.breakpoints.up('md')]: {
        width: '42px',
        height: '42px',
        marginBottom: -6,

      },
      [theme.breakpoints.up('lg')]: {
        width: '48px',
        height: '48px',
        marginBottom: -8,
      }
    },
  },
  stepButtons: {
    flex: '0 0 auto',
  }
}));
