import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ApiListModel } from 'model/api/api-list-model/api-list-model';
import { BoletoListModel } from 'model/api/gestao/boleto/boleto-list-model';
import { useSessaoAtual } from 'services/app';


export function useGetRemessaBoleto() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<BoletoListModel>>();
    const { getEmpresa } = useSessaoAtual();

    const getRemessaBoleto = useCallback(
        (remessaId: string, page: number = 1, pageSize: number = 0) =>
            invocarApi({
                method: 'GET',
                url: `/${VariaveisAmbiente.apiVersion}/Empresa/${getEmpresa().Id}/remessa/${remessaId}/boletos?pageSize=${pageSize}&page=${page}`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            }),
        [getEmpresa, invocarApi],
    );
    return {
        ...outrasPropriedades,
        getRemessaBoleto,
    };
}