import { EnumBanco } from "model/enums/enum-banco";
import {  } from "model/enums/enum-status-boleto";
import { EnumTpAceite } from "model/enums/enum-tp-aceite";
import { EnumTpCarteira } from "model/enums/enum-tp-carteira";
import { EnumTpDesconto } from "model/enums/enum-tp-desconto";
import { EnumTpDocumento } from "model/enums/enum-tp-documento";
import { EnumTpEspecie } from "model/enums/enum-tp-especie";
import { guidEmpty } from "utils/guid-empty";

export class BoletoFormModel {
  constructor(
    public id: string = guidEmpty(),
    public empresaId: string = '',
    public contaBancariaId: string = '',
    public configContaId: string = '',
    public banco: EnumBanco = EnumBanco.Safra,
    public nomeBanco: string = 'Safra',
    public agencia: string = '',
    public conta: string = '',
    public nomeEmpresa: string = '',
    public tipoDocumentoEmpresa: EnumTpDocumento = EnumTpDocumento.CNPJ,
    public documentoEmpresa: string = '',
    public carteira: EnumTpCarteira = EnumTpCarteira.CobrancaSimples,
    public nossoNumero: number = 0,
    public seuNumero: string = '',
    public dataVencimento: string = '',
    public valorTitulo: number = 0,
    public especie: EnumTpEspecie = EnumTpEspecie.DuplicataMercantil,
    public codigoAceite: EnumTpAceite = EnumTpAceite.Aceito,
    public dataEmissao: string = '',
    public dataJurosMora: string | null = null,
    public valorJurosMoraDia: number = 0,
    public percJurosMoraDia: number = 0,
    public valorMulta: number = 0,
    public percMulta: number = 0,
    public tipoDesconto1: EnumTpDesconto = EnumTpDesconto.SemDesconto,
    public dataDesconto1: string = '',
    public valorDesconto1: number = 0,
    public tipoDesconto2: EnumTpDesconto = EnumTpDesconto.SemDesconto,
    public dataDesconto2: string = '',
    public valorDesconto2: number = 0,
    public tipoDesconto3: EnumTpDesconto = EnumTpDesconto.SemDesconto,
    public dataDesconto3: string = '',
    public valorDesconto3: number = 0,
    public tipoDocumentoPagador: EnumTpDocumento = EnumTpDocumento.CNPJ,
    public documentoPagador: string = '',
    public nomePagador: string = '',
    public enderecoPagador: string = '',
    public numeroPagador: string = '',
    public complementoPagador: string = '',
    public bairroPagador: string = '',
    public cidadePagador: string = '',
    public ufPagador: string = '',
    public cepPagador: string = '',
    public emailPagador: string = '',
    public dddCelularPagador: number = 0,
    public numeroCelularPagador: string = '',
    // public tipoDocumentoSacadorAvalista: EnumTpDocumento = EnumTpDocumento.CNPJ,
    // public documentoSacadorAvalista: string = '',
    // public enderecoSacadorAvalista: string = '',
    // public numeroSacadorAvalista: string = '',
    // public complementoSacadorAvalista: string = '',
    // public bairroSacadorAvalista: string = '',
    // public cidadeSacadorAvalista: string = '',
    // public ufSacadorAvalista: string = '',
    // public cepSacadorAvalista: string = '',
    public chaveNFe: string = '',
    public mensagemRecibo: string = '',
    public mensagemFichaCompensacao: string = '',
  ) { }
}
