import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { ConfirmarBoleto } from "./confirmar-boleto/confirmar-boleto";
import { useThemeQueries } from "views/theme";
import { ImportacaoListModel } from "views/pages/private/importacao/components/importacao-list/importacao-list";



interface DialogVisualizarBoletoProps {
    onSubmit: (model: ImportacaoListModel) => void;
    model: ImportacaoListModel;
    aberto: boolean;
    onClose: () => void;
}


export const DialogVisualizarBoleto = ({
    aberto,
    model,
    onSubmit,
    onClose
}: DialogVisualizarBoletoProps) => {

    const { isMobile } = useThemeQueries();

    return <DialogSaurus
        tamanho='md'
        titulo='Dados do Boleto'
        isDivider
        isButtonTitleClose
        aberto={aberto}
        handleClickClose={onClose}
        fullScreen={isMobile}
    >
        <ConfirmarBoleto
            model={model}
            onSubmit={onSubmit}
        />
    </DialogSaurus>
}