import { Controller, useForm } from "react-hook-form";
import { Box, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { useThemeQueries } from "views/theme";
import { ExpandIcon } from "views/components/icons";
import { ImportacaoSearchProps } from "./importacao-search-props";
import { useSearchBarStyles } from "views/pages/private/inicial/components/inicial-page-search/serch-bar-styles";
import { Paginacao } from "views/components/paginacao";
import { ImportacaoListModel } from "../importacao-list/importacao-list";

interface ImportacaoSearchBarProps {
    setSelected: React.Dispatch<React.SetStateAction<ImportacaoListModel[]>>;
    setSearchProps: React.Dispatch<React.SetStateAction<ImportacaoSearchProps>>;
    selected: ImportacaoListModel[];
    list: ImportacaoListModel[];
    registros: number;
}

export const ImportacaoSearchBar = ({
    setSearchProps,
    setSelected,
    selected,
    list,
    registros,
}: ImportacaoSearchBarProps) => {

    const { isMobile } = useThemeQueries();
    const [openned, setOpenned] = useState<boolean>(!isMobile)
    useEffect(() => {
        setOpenned(!isMobile)
    }, [isMobile])

    const classes = useSearchBarStyles({
        openned,
        padding: 'small'
    });

    const {
        handleSubmit,
        control,
    } = useForm<ImportacaoSearchProps>({
        defaultValues: { ...new ImportacaoSearchProps() },
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = useCallback((model: ImportacaoSearchProps) => {
        setSearchProps(model)
    }, [setSearchProps])

    const todosSelecionados: boolean = (() => {
        return selected.length === registros
    })()

    const CheckboxTodos = useMemo(() => (
        <Grid item xs={12} md={3} className={classes.checkboxContainer}>
            <FormControlLabel
                control={<Checkbox color='secondary' checked={todosSelecionados} />}
                value={todosSelecionados} onChange={() => {
                    setSelected(() => {
                        if (todosSelecionados) {
                            return []
                        } else {
                            return list
                        }
                    })
                }}
                label='Selecionar Todos'
            />
        </Grid>
    ), [classes.checkboxContainer, list, setSelected, todosSelecionados])

    return (
        <>
            <Box className={classes.bar}>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1} justifyContent="space-between">

                        {!isMobile && CheckboxTodos}

                        <Grid item xs={12} md={6} className={classes.textfield}>
                            <Controller
                                control={control}
                                name="query"
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        placeholder='Nome do Pagador...'
                                        searchable
                                        size='small'
                                        onSearch={() => {
                                            handleSubmit(onSubmit)()
                                        }}
                                        fullWidth
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={3} className={classes.registroContainer}>
                            {isMobile && CheckboxTodos}
                            <Box >
                                <Paginacao
                                    currentPage={1}
                                    totalRegisters={registros}
                                    totalPages={1}
                                    pageChanged={() => { }}
                                />
                            </Box>
                        </Grid>

                    </Grid>
                </form>
            </Box>
            {isMobile && (
                <Box className={classes.closeOpenBar} onClick={() => setOpenned(prev => !prev)}>
                    {openned ? (
                        <>
                            <ExpandIcon tipo='GERAL' />
                            Fechar Filtros
                        </>
                    ) : (
                        <>
                            <ExpandIcon tipo='GERAL' />
                            Abrir Filtros
                        </>
                    )}
                </Box>
            )}
        </>
    )

}