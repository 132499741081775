import { useCallback, FocusEvent, useState } from "react";
import { useGetSegmentos } from "data/api/gestao/segmentos";
import { SegmentoModel } from "model/api/gestao/master/segmento";
import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from "../autocomplete-saurus/autocomplete-saurus";

export interface AutocompleteSegmentosProps {
  label?: string;
  loadingExterno?: boolean;
  name?: string;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  placeholder?: string;
  allowSubmit?: boolean;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
}

export const AutocompleteSegmento = ({
  onChange,
  loadingExterno,
  ...props
}: AutocompleteSegmentosProps) => {
  const { getSegmentos, carregando: carregandoSegmentos } = useGetSegmentos();
  const [segmentosState, setSegmentosState] = useState<Array<SegmentoModel>>(
    new Array<SegmentoModel>()
  );

  const getSegmentosWrapper = useCallback(
    async (termo: string) => {
      try {
        const ret = await getSegmentos(termo);
        if (ret.erro) throw ret.erro;
        let segmentos: Array<SegmentoModel> = new Array<SegmentoModel>();
        if (ret.resultado?.data?.list?.length > 0) {
          segmentos = ret.resultado?.data?.list as Array<SegmentoModel>;
        }
        setSegmentosState(segmentos);
      } catch (e: any) { }
    },
    [getSegmentos]
  );

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange]
  );
  const onPesquisa = useCallback(
    (termo: string) => {
      getSegmentosWrapper(termo);
    },
    [getSegmentosWrapper]
  );
  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      name={props.name}
      permiteNovo
      loading={(carregandoSegmentos)}
      onPesquisa={onPesquisa}
      onChange={onChangeWrapper}
      opcoes={segmentosState}
      optionLabelKey="descricao"
      optionValueKey="descricao"
      onBlur={props.onBlur}
      value={props.value}
      allowSubmit={props.allowSubmit}
      noOptionsText={"Segmento não Localizado"}
      textoNovoItem=""
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
    />
  );
};
