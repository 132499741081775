import { guidEmpty } from 'utils/guid-empty';
import { EnumRegime } from 'model/enums/enum-regime'


export class EmpresaModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public cpfcnpj: string = '',
        public ierg: string = '',
        public im: string = '',
        public razaoSocial: string = '',
        public nomeFantasia: string = '',
        public dNascimento: string | undefined | Date = undefined,
        public cnae: string = '',
        public regime: EnumRegime = EnumRegime.NAOSEAPLICA,
        public fone: string = '',
        public site: string = '',
        public infAdic: string = '',
        public cep: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public cMunicipio: string = '',
        public uf: string = '',
        public cuf: string = '',
        public segmento: string = '',
        public segmentoId: string = guidEmpty(),
        public email: string = '',
    ) { }
}

