import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    switchContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& .MuiTypography-caption': {
            color: theme.palette.primary.main,
            fontSize: '14px'
        }
    }
}))