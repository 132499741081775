import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ContaBancariaConfiguracaoModel } from 'model/api/gestao/conta-bancaria-configuracao/conta-bancaria-configuracao-model';
import { ApiListModel } from 'model/api/api-list-model/api-list-model';
import { useSessaoAtual } from 'services/app';


export function useGetContaBancariaConfiguracao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<ContaBancariaConfiguracaoModel>>();
    const { getEmpresa } = useSessaoAtual();

    const empresaId = getEmpresa().Id;

    const getContaBancariaConfiguracao = useCallback(
        (contaBancariaId: string, pageSize: number = 0) =>
            invocarApi({
                method: 'GET',
                url: `/${VariaveisAmbiente.apiVersion}/empresa/${empresaId}/conta-bancaria/${contaBancariaId}/configuracao-boleto?pageSize=${pageSize}`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            }),
        [empresaId, invocarApi],
    );
    return {
        ...outrasPropriedades,
        getContaBancariaConfiguracao,
    };
}