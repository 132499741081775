import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { BoletoFormModel } from 'model/api/gestao/boleto/boleto-add-form-model';
import { useSessaoAtual } from 'services/app';
import { BoletoGetModel } from 'model/api/gestao/boleto/boleto-get-model';

export function usePutBoleto() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<BoletoGetModel>();
    const { getEmpresa } = useSessaoAtual();

    const putBoleto = useCallback(
        (model: BoletoFormModel) =>
            invocarApi({
                method: 'PUT',
                url: `/${VariaveisAmbiente.apiVersion}/Empresa/${getEmpresa().Id}/boleto/atualizar`,
                data: {
                    ...model,
                    contratoId: getEmpresa().ContratoId
                },
                enviarTokenUsuario: true
            }),
        [getEmpresa, invocarApi],
    );
    return {
        ...outrasPropriedades,
        putBoleto,
    };
}
