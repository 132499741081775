import { makeStyles, Theme } from "@material-ui/core";
import { EnumTelaContaBancaria } from "model/enums/enum-tela-conta-bancaria";

interface StylesProps{
    tela: EnumTelaContaBancaria;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '& img': {
            width: 50,
            height: 50,
            borderRadius: theme.shape.borderRadius
        },
        '& h4': {
            fontWeight: 500,
            fontSize: '1.2rem',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)'
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        aligmItens: 'center'
    },
    formConta: {
        display: ({tela}) => tela === EnumTelaContaBancaria.CONTA ? 'block' : 'none'
    },
    formParametrizacao: {
        display: ({tela}) => tela === EnumTelaContaBancaria.PARAMETRIZACAO ? 'block' : 'none'
    }
}))