import { useValidationYupEmail, useValidationYupFone } from 'views/components/form-validations';
import * as Yup from 'yup';

export const useFormUsuarioValidation = () => {

    const {emailYup} = useValidationYupEmail();
    const foneYup = useValidationYupFone();

    const FormUsuarioYupValidation = Yup.object().shape({
        nome: Yup.string().required('Nome de usuário é obrigatório.'),
        email: emailYup(),
        telefone: foneYup().required('Informe o Telefone'),
        apelido: Yup.string().required('Apelido é obrigatório.').min(3, 'Seu apelido deve conter no mínimo 3 caracteres.')
    });

    return {
        FormUsuarioYupValidation
    }
}