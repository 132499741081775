import { Button, Grid, Typography } from '@material-ui/core';

import {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useFormEmpresaValidation } from './form-empresa-edit-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { UFMock } from 'data/mocks/uf-mock';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useConsultaCEP } from 'data/api/wsmaster/consultar-cep';
import { RegimeMock } from 'data/mocks/regime-mock';
import { EmpresaEditFormModel } from 'model/app/forms/master/empresa-edit-form-model';
import { SegmentoModel } from 'model/api/gestao/master/segmento';
import { picker } from 'utils/picker';
import { guidEmpty } from '../../../../../utils/guid-empty';
import { AutocompleteSegmento } from '../../../controles/autocompletes/autocomplete-segmento/autocomplete-segmento';
import { toDate, toDateString } from '../../../../../utils/to-date';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validarCPFCNPJ } from 'utils/cpfcnpj-validate';
import { useConsultaCnpj } from 'data/api/wsmaster';
import { DividerSaurus } from 'views/components/divider/divider-saurus';
import { useStyles } from './form-empresa-edit-styles'
import { SalvarEditarIcon } from 'views/components/icons';
import { useShowAviso } from 'services/app/hooks/show-aviso';

export interface FormEmpresaEditProps
  extends DefaultFormProps<EmpresaEditFormModel> {
  setCarregandoExterno(carregando: boolean): void;
  paraFiscal?: boolean;
}

export const FormEmpresaEdit = forwardRef<
  DefaultFormRefs<EmpresaEditFormModel>,
  FormEmpresaEditProps
>((props: FormEmpresaEditProps, refs) => {
  const txtRazaoSocial = useRef<HTMLInputElement>();
  const txtNro = useRef<HTMLInputElement>();
  const txtLogradouro = useRef<HTMLInputElement>();
  const { FormEmpresaValidationYup, FormEmpresaValidationYupParaFiscal } =
    useFormEmpresaValidation();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState({
    modeloForm: new EmpresaEditFormModel(''),
  });
  const { showAviso } = useShowAviso();
  const { consultaCEP, carregando: carregandoCep } = useConsultaCEP();
  const { isMobile } = useThemeQueries();
  const { consultarCNPJ, carregando: carregandoCNPJ } = useConsultaCnpj();
  const carregando = carregandoCNPJ || carregandoCep
  useEffect(() => {
    if (props.setCarregandoExterno) props.setCarregandoExterno(carregando);
  }, [props, carregando]);

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue,
    getValues,
  } = useForm<EmpresaEditFormModel>({
    defaultValues: {
      ...model.modeloForm,
    },
    resolver: yupResolver(
      props.paraFiscal
        ? FormEmpresaValidationYupParaFiscal
        : FormEmpresaValidationYup,
    ),
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
  });

  const onSubmit = (form: EmpresaEditFormModel) => {
    form.dNascimento = toDate(form.dNascimento);
    props.onSubmit(form, model.modeloForm);
  };

  useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ modeloForm: new EmpresaEditFormModel('', '') });
      reset();
      if (!isMobile) txtRazaoSocial?.current?.focus();
    },
    fillForm: (model: EmpresaEditFormModel) => {
      setModel({ modeloForm: model });
      reset({ ...model });
      if (!isMobile) txtRazaoSocial?.current?.focus();
    },
  }));



  return (
    <>
      <div className={utilClasses.formContainer}>
        {(props.loading && props.showLoading) || carregando ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${props.loading ? utilClasses.controlLoading : ''}`}
        >
          <Grid container spacing={4} className={classes.container}>
            <Grid item xs={12} md={6} className={classes.infoContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="cpfcnpj"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={props.loading}
                        autoComplete='off'
                        tipo="CNPJ_CPF"
                        readOnly
                        searchable
                        onSearch={async (value) => {
                          try {
                            if (!validarCPFCNPJ(value)) {
                              showAviso('error', 'CPF ou CNPJ inválido')
                            }
                            if (value.length > 11) {
                              let res = await consultarCNPJ(value)
                              setValue('razaoSocial', res.razaoSocial)
                              setValue('nomeFantasia', res.nomeFantasia)
                              setValue('ierg', res.ie)
                              setValue('regime', res.regime)
                              setValue('cnae', res.cnae)
                              setValue('cep', res.cep)
                              setValue('logradouro', res.logradouro)
                              setValue('numero', res.numero)
                              setValue('complemento', res.complemento)
                              setValue('bairro', res.bairro)
                              setValue('municipio', res.municipio)
                              setValue('cMunicipio', res.cMunicipio)
                              setValue('uf', UFMock.find(uf => uf.Value === res.uf)?.Value!)
                            } else {
                              showAviso('info', 'Busca disponível apenas para CNPJ');
                              return
                            }
                          }
                          catch (erro: any) {
                            return showAviso('error', erro.message)
                          }
                        }}
                        fullWidth
                        label="CPF / CNPJ"
                        placeholder=""
                        error={Boolean(errors.cpfcnpj && errors.cpfcnpj.message)}
                        helperText={
                          touchedFields.cpfcnpj || errors.cpfcnpj
                            ? errors.cpfcnpj?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('cpfcnpj')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="razaoSocial"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={props.loading}
                        fullWidth
                        autoComplete='off'
                        label="Nome Completo / Razão Social"
                        placeholder=""
                        error={Boolean(
                          errors.razaoSocial && errors.razaoSocial.message,
                        )}
                        helperText={
                          touchedFields.razaoSocial || errors.razaoSocial
                            ? errors.razaoSocial?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('razaoSocial')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="nomeFantasia"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={props.loading}
                        fullWidth
                        autoComplete='off'
                        label="Nome Fantasia"
                        placeholder=""
                        error={Boolean(
                          errors.nomeFantasia && errors.nomeFantasia.message,
                        )}
                        helperText={
                          touchedFields.nomeFantasia || errors.nomeFantasia
                            ? errors.nomeFantasia?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('nomeFantasia')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="cnae"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={props.loading}
                        fullWidth
                        autoComplete='new-password'
                        variant="outlined"
                        placeholder=""
                        label="CNAE"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(errors.cnae && errors.cnae.message)}
                        helperText={
                          touchedFields.cnae || errors.cnae
                            ? errors.cnae?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('cnae')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="regime"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={props.loading}
                        conteudo={RegimeMock}
                        fullWidth
                        variant="outlined"
                        label={'Regime Atual'}
                        {...field}
                        onChange={(event) => {
                          if (event) {
                            setValue(
                              'regime',
                              RegimeMock.filter(
                                (item) => item.Key === event.target.value,
                              )[0]?.Key,
                            );
                          }
                        }}
                        value={getValues('regime')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="ierg"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="TEXTO"
                        disabled={props.loading}
                        fullWidth={true}
                        autoComplete='off'
                        showStartAdornment={false}
                        allowSubmit={false}
                        label="IERG"
                        placeholder=""
                        error={Boolean(errors.ierg && errors.ierg.message)}
                        helperText={
                          touchedFields.ierg || errors.ierg
                            ? errors.ierg?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('ierg')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="segmento"
                    control={control}
                    render={({ field }) => (
                      <AutocompleteSegmento
                        disabled={props.loading}
                        loadingExterno={props.loading}
                        label="Segmento"
                        error={Boolean(errors.segmento && errors.segmento.message)}
                        helperText={
                          touchedFields.segmento || errors.segmento
                            ? errors.segmento?.message
                            : undefined
                        }
                        {...field}
                        onChange={(retorno) => {
                          if (retorno.isNewVal) {
                            setValue('segmento', retorno.value);
                            setValue('segmentoId', guidEmpty());
                          } else if (!retorno.isString) {
                            const segmento = picker<SegmentoModel>(
                              retorno.value,
                              new SegmentoModel(),
                            );
                            setValue('segmento', segmento.descricao);
                            setValue('segmentoId', segmento.id);
                          } else {
                            setValue('segmento', '');
                          }
                        }}
                        value={getValues('segmento')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="fone"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo={'TELEFONE'}
                        disabled={props.loading}
                        fullWidth
                        label="Telefone"
                        placeholder=""
                        error={Boolean(errors.fone && errors.fone.message)}
                        helperText={
                          touchedFields.fone || errors.fone
                            ? errors.fone?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('fone')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="EMAIL"
                        disabled={props.loading}
                        fullWidth={true}
                        showStartAdornment={false}
                        allowSubmit={false}
                        autoComplete={'off'}
                        label="E-mail"
                        placeholder="usuario@empresa.com.br"
                        error={Boolean(errors.email && errors.email.message)}
                        helperText={
                          touchedFields.email || errors.email
                            ? errors.email?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('email')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="dNascimento"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="DATA"
                        disabled={props.loading}
                        fullWidth
                        autoComplete='new-password'
                        variant="outlined"
                        label="Data Nascimento/Início Atividades"
                        InputLabelProps={{ shrink: true }}
                        placeholder=""
                        error={Boolean(
                          errors.dNascimento && errors.dNascimento.message,
                        )}
                        helperText={
                          touchedFields.dNascimento || errors.dNascimento
                            ? errors.dNascimento?.message
                            : undefined
                        }
                        {...field}
                        value={toDateString(getValues('dNascimento'), 'yyyy-MM-DD')}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="site"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="URL"
                        autoComplete='new-password'
                        disabled={props.loading}
                        fullWidth={true}
                        showStartAdornment={true}
                        allowSubmit={false}
                        label="Site"
                        placeholder=""
                        error={Boolean(errors.site && errors.site.message)}
                        helperText={
                          touchedFields.site || errors.site
                            ? errors.site?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('site')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="infAdic"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={props.loading}
                        fullWidth={true}
                        label="Informação Adicional"
                        placeholder=""
                        error={Boolean(errors.infAdic && errors.infAdic.message)}
                        helperText={
                          touchedFields.infAdic || errors.infAdic
                            ? errors.infAdic?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('infAdic')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h4'>Endereço</Typography>
                </Grid>
                <Grid item xs={12}>
                  <DividerSaurus />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="cep"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="CEP"
                        disabled={props.loading}
                        fullWidth
                        searchable
                        autoComplete={'off'}
                        label="CEP"
                        placeholder=""
                        onSearch={async (value: string) => {
                          try {
                            let res = await consultaCEP(value);

                            setValue('cep', res.CEP);
                            setValue('bairro', res.Bairro);
                            setValue('cMunicipio', res.CMun);
                            setValue('logradouro', res.Logradouro);
                            setValue('uf', res.UF);
                            setValue('municipio', res.Municipio);
                            if (!isMobile) txtNro.current?.focus();
                          } catch (e: any) {
                            showAviso('error', e.message);
                            if (!isMobile) txtLogradouro.current?.focus();
                          }
                        }}
                        error={Boolean(errors.cep && errors.cep.message)}
                        helperText={
                          touchedFields.cep || errors.cep
                            ? errors.cep?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('cep')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="logradouro"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        inputRef={txtLogradouro}
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Endereço"
                        InputLabelProps={{ shrink: true }}
                        placeholder=""
                        error={Boolean(
                          errors.logradouro && errors.logradouro.message,
                        )}
                        helperText={
                          touchedFields.logradouro || errors.logradouro
                            ? errors.logradouro?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('logradouro')}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Controller
                    name="numero"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="NUMERO"
                        inputRef={txtNro}
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Número"
                        InputLabelProps={{ shrink: true }}
                        placeholder=""
                        error={Boolean(errors.numero && errors.numero.message)}
                        helperText={
                          touchedFields.numero || errors.numero
                            ? errors.numero?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('numero')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="bairro"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Bairro"
                        InputLabelProps={{ shrink: true }}
                        placeholder=""
                        error={Boolean(errors.bairro && errors.bairro.message)}
                        helperText={
                          touchedFields.bairro || errors.bairro
                            ? errors.bairro?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('bairro')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="uf"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={props.loading}
                        conteudo={UFMock}
                        fullWidth
                        variant="outlined"
                        label={'UF'}
                        allowSubmit
                        select
                        {...field}
                        value={UFMock.find(uf => uf.Value === getValues('uf'))?.Key}
                        onChange={(event) => {
                          if (event) {
                            const item = UFMock.filter(
                              (item) => item.Key === event.target.value,
                            )[0];
                            if (item) {
                              setValue('uf', item.Value);
                            }
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="complemento"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Complemento"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Ex: Apartamento 111"
                        error={Boolean(
                          errors.complemento && errors.complemento.message,
                        )}
                        helperText={
                          touchedFields.complemento || errors.complemento
                            ? errors.complemento?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('complemento')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Controller
                    name="municipio"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Município"
                        InputLabelProps={{ shrink: true }}
                        placeholder=""
                        error={Boolean(
                          errors.municipio && errors.municipio.message,
                        )}
                        helperText={
                          touchedFields.municipio || errors.municipio
                            ? errors.municipio?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('municipio')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Controller
                    name="cMunicipio"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo={'NUMERO'}
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Cód. Município"
                        InputLabelProps={{ shrink: true }}
                        placeholder=""
                        error={Boolean(
                          errors.cMunicipio && errors.cMunicipio.message,
                        )}
                        helperText={
                          touchedFields.cMunicipio || errors.cMunicipio
                            ? errors.cMunicipio?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('cMunicipio')?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer} onClick={handleSubmit(onSubmit)}>
                  <Button variant='contained' color='primary' fullWidth>
                    <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
