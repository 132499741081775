import { EnumRegime } from '../../../enums/enum-regime';
export class EmpresaFormModel {
    constructor(
        public cpfcnpj: string = '',
        public razaoSocial: string = '',
        public nomeFantasia: string = '',
        public fone: string = '',
        public cnae: string = '',
        public regime: EnumRegime = EnumRegime.NAOSEAPLICA,
        public ierg: string = ''
    ) { }
}


