import { DefaultCard, useDefaultCardStyles } from "../components/default-card";
import { useStyles } from "../card-boleto/card-boleto-styles";
import { Grid, Typography } from '@material-ui/core'
import { toCurrency } from "utils/toCurrency";
import { BoletoHistoricoModel } from "model/api/gestao/boleto/boleto-historico-model";
import { useStyles as useHistoricoStyles } from './card-historico-boleto-model'
import { EnumStatusBoleto } from "model/enums/enum-status-boleto";

interface CardHistoricoBoletoProps {
    model: BoletoHistoricoModel;
}


export const CardHistoricoBoleto = ({
    model,
}: CardHistoricoBoletoProps) => {
    const classes = useDefaultCardStyles({});
    const cardClasses = useStyles({
        status: EnumStatusBoleto.Aceito
    });
    const historicoClasses = useHistoricoStyles();

    // const descricao = StatusBoletoMock.filter(
    //     (x) => x.Key === model.situacao,
    // )[0].Value;

    return (
        <>
            <DefaultCard>
                {/* <Tooltip arrow title={descricao} placement="right">
                    <div
                        className={classNames(classes.tagStatus, cardClasses.tagColor)}
                    ></div>
                </Tooltip> */}
                <div className={`${classes.cardContent} ${cardClasses.container} ${historicoClasses.cardHistorico}`}>
                    <Grid container spacing={2}>
                        <Grid item md={2} xs={6}>
                            <Typography color="textSecondary" variant="caption" className={historicoClasses.mobileLabel}>
                                Nosso Número
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.nossoNumero}
                            </Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography color="textSecondary" variant="caption" className={historicoClasses.mobileLabel}>
                                Pagador
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.nomePagador}
                            </Typography>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Typography color="textSecondary" variant="caption" className={historicoClasses.mobileLabel}>
                                Ocorrência
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.ocorrencia}
                            </Typography>
                        </Grid>
                        <Grid item md={1} xs={6}>
                            <Typography color="textSecondary" variant="caption" className={historicoClasses.mobileLabel}>
                                Valor
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {toCurrency(model.valorTitulo)}
                            </Typography>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Typography color="textSecondary" variant="caption" className={historicoClasses.mobileLabel}>
                                Nome do Arquivo
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.nomeArquivo}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </DefaultCard>
        </>
    );
};