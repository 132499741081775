import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { EmpresaModel } from "../../../../model/api/gestao/master/empresa";
import { VariaveisAmbiente } from "config";

export function usePutEmpresa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putEmpresa = useCallback(
    (empresaModel: EmpresaModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.apiVersion}/empresas/atualizar`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(empresaModel),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    putEmpresa,
  };
}
