import { Box, Button } from "@material-ui/core"
import { useCallback, useEffect, useRef, useState } from "react"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { useStyles } from '../accordion-configuracoes-styles'
import { useSessaoAtual } from "services/app"
import { FormUsuario } from "views/components/form/master/usuario/form-usuario"
import { UsuarioFormModel } from "model/app/forms/master/usuario-form"
import { DefaultFormRefs } from "views/components/form/utils"
import { SalvarEditarIcon } from "views/components/icons"
import { usePutUsuario } from "data/api/gestao/usuario/put-usuario"
import { EnumRetornoApiBase } from "data/api/base/api-base-response"
import { usePostImagemBase64 } from "data/api/imagem/post-imagem"
import { imagemForUpload } from "utils/imagem-for-upload"
import { useShowAviso } from "services/app/hooks/show-aviso"
import { useGetUsuario } from "data/api/gestao/usuario/get-usuario"

export const AccordionDados = () => {

    const classes = useStyles();
    const { showAviso } = useShowAviso();
    const { putUsuario, carregando: carregandoPut } = usePutUsuario();
    const { getMeuUsuario, carregando: carregandoGet } = useGetUsuario();
    const { postImagemBase64, carregando: carregandoImg } = usePostImagemBase64();
    const { usuario, refreshUser, carregando: carregandoRefresh } = useSessaoAtual();

    const carregando = carregandoPut || carregandoImg || carregandoRefresh || carregandoGet;

    const [aberto, setAberto] = useState<boolean>(true);

    const formRef = useRef<DefaultFormRefs<UsuarioFormModel>>(null)

    const carregarTela = useCallback(async () => {
        try {
            const res = await getMeuUsuario();
            if (res.erro) throw res.erro;

            if (!res.resultado) return

            const data = res.resultado.data

            const model = new UsuarioFormModel(usuario?.jti, data?.nome, data?.email || undefined, usuario?.imagem,
                data.apelido || undefined, data.telefone || undefined)
            formRef.current?.fillForm(model)
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [getMeuUsuario, showAviso, usuario?.imagem, usuario?.jti])

    const handleSubmit = useCallback(async (model: UsuarioFormModel) => {
        try {
            let imagem = model.imagem;

            let imgUpload = imagemForUpload(model.imagem);
            if (imgUpload.length > 0) {
                const retImagem = await postImagemBase64(
                    imgUpload,
                    'perfil/',
                    usuario?.jti || '',
                );
                if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
                    throw new Error('Erro ao enviar a Imagem selecionada.');
                }
                if (retImagem.resultado?.data.data.status === 2) {
                    throw new Error(
                        'Erro ao enviar a Imagem selecionada.Detalhe: ' +
                        retImagem.resultado?.data?.data?.retorno,
                    );
                }
                imagem =
                    retImagem.resultado?.data?.data?.url_blob +
                    '?timestamp=' +
                    new Date().getTime();
            }

            model = { ...model, imagem }

            const res = await putUsuario(model)

            if (res.erro) throw res.erro

            await refreshUser(usuario?.originalToken || '')

            showAviso('success', 'Usuário Atualizado')

            carregarTela()
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [carregarTela, postImagemBase64, putUsuario, refreshUser, showAviso, usuario?.jti, usuario?.originalToken])

    useEffect(() => {
        if (aberto) {
            carregarTela()
        }
    }, [aberto, carregarTela, usuario])

    return (
        <AccordionSaurus
            labelPrimary="Meus Dados"
            expanded={aberto}
            onChange={() => setAberto(prev => !prev)}
        >
            <Box className={classes.usuarioFormContainer}>
                <Box>
                    <FormUsuario
                        onSubmit={handleSubmit}
                        showLoading
                        loading={carregando}
                        ref={formRef}
                    />
                    <Box className={classes.buttonContainer}>
                        <Button color='primary' variant='contained' onClick={() => formRef.current?.submitForm()}>
                            <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                            Salvar
                        </Button>
                    </Box>
                </Box>
            </Box>
        </AccordionSaurus>
    )
}