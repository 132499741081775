import * as Yup from 'yup';

export const useInicialSearchBarValidation = () => {
    const InicialSearchBarValidation = Yup.object().shape({
        dataInicial: Yup.string().test({
            message: 'Data Inicial não pode ser maior que a Data Final',
            test: (value, context) => {
                return new Date(value || '') <= new Date(context.parent.dataFinal)
            }
        }),
    });
    return {
        InicialSearchBarValidation
    }
}

