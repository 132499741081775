import { Button, Grid } from '@material-ui/core';
import { useRef } from 'react';
import { ImagemInput, TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
    DefaultFormRefs,
    DefaultFormProps,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormUsuarioValidation } from './form-usuario-validation';
import { UsuarioFormModel } from 'model/app/forms/master/usuario-form';
import { useStyles } from './form-usuario-styles';
import UsuarioSemImagem from 'assets/img/usuario-sem-imagem.png'

export const FormUsuario = React.forwardRef<
    DefaultFormRefs<UsuarioFormModel>,
    DefaultFormProps<UsuarioFormModel>
>((props: DefaultFormProps<UsuarioFormModel>, refs) => {
    const txtEmail = useRef<HTMLInputElement>();
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const { isMobile } = useThemeQueries();
    const { FormUsuarioYupValidation } = useFormUsuarioValidation();

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        getValues,
        setValue
    } = useForm<UsuarioFormModel>({
        resolver: yupResolver(FormUsuarioYupValidation),
        criteriaMode: 'all',
        mode: 'onSubmit' && 'onTouched',
    });

    const onSubmit = (form: UsuarioFormModel) => {
        props.onSubmit(form);
    };

    React.useImperativeHandle(refs, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset()
        },
        fillForm: (model: UsuarioFormModel) => {
            reset({ ...model });
            if (!isMobile) txtEmail?.current?.focus();
        },
    }));

    return (
        <>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <CircularLoading tipo="FULLSIZED" />
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
                        }`}
                >
                    <Controller
                        name="imagem"
                        control={control}
                        render={({ field }) => (
                            <ImagemInput
                                loadErrorImage={UsuarioSemImagem}
                                imageRadius="100%"
                                width={isMobile ? '180px' : '180px'}
                                height={isMobile ? '180px' : '180px'}
                                accept="image/*"
                                className={classes.image}
                                error={Boolean(
                                    errors.imagem && errors.imagem.message,
                                )}
                                helperText={
                                    errors.imagem
                                        ? errors.imagem.message
                                        : undefined
                                }
                                {...field}
                                onChange={({ base64 }: any) => {
                                    setValue('imagem', base64);
                                }}
                                onBlur={() => {
                                    setValue('imagem', '');
                                }}
                                value={getValues('imagem')}
                            />)}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="EMAIL"
                                        disabled={props.loading}
                                        fullWidth={true}
                                        inputRef={txtEmail}
                                        allowSubmit={false}
                                        autoComplete={'off'}
                                        label="E-Mail"
                                        placeholder="Insira o seu e-mail"
                                        error={Boolean(errors.email && errors.email.message)}
                                        helperText={
                                            errors.email
                                                ? errors.email?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="nome"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        allowSubmit={true}
                                        disabled={props.loading}
                                        fullWidth={true}
                                        autoComplete={'off'}
                                        label="Nome de Exibição"
                                        error={Boolean(errors.nome && errors.nome.message)}
                                        helperText={
                                            errors.nome
                                                ? errors.nome?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="apelido"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        disabled={props.loading}
                                        fullWidth={true}
                                        showStartAdornment={true}
                                        allowSubmit={false}
                                        autoComplete={'off'}
                                        label="Apelido (Nome de usuário)"
                                        placeholder="Ex: joao11"
                                        error={Boolean(errors.apelido && errors.apelido.message)}
                                        helperText={
                                            errors.apelido
                                                ? errors.apelido?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="telefone"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TELEFONE'}
                                        disabled={props.loading}
                                        fullWidth={true}
                                        showStartAdornment={true}
                                        allowSubmit={false}
                                        label="Telefone"
                                        placeholder=""
                                        error={Boolean(errors.telefone && errors.telefone.message)}
                                        helperText={
                                            errors.telefone
                                                ? errors.telefone?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});