import { AppEventEnum } from "model/enums/enum-event-app";
import { useEventTools } from "../use-cases/events/event-tools";
import { useCallback } from "react";
import { LoteListModel } from "model/api/gestao/lote/lote-list-model";
import { ShowConfirmProps } from "./confirm-saurus";
import { useHistory, useLocation } from "react-router-dom";

const importarArquivoRoute = `/gerenciar/importar-arquivo`
const boletoRoute = `/gerenciar/boleto/`
const contaRoute = `/conta-bancaria/`

export const useCadastros = () => {
    const { callEvent } = useEventTools();
    const location = useLocation();
    const { push } = useHistory();

    //Dialog Importar Arquivo
    const abrirDialogImportarArquivo = useCallback(
        () => {
            const route = (location.pathname[location.pathname.length - 1] === '/' ?
                location.pathname.slice(0, location.pathname.length - 1) : location.pathname).replace('/gerenciar', '')

            callEvent(AppEventEnum.DialogImportarArquivo, {
                aberto: true
            });
            push(route + importarArquivoRoute)
        },
        [callEvent, location.pathname, push]
    );

    const fecharDialogImportarArquivo = useCallback((changeRoute: boolean = true) => {
        const route = location.pathname.replace(importarArquivoRoute, '')
        callEvent(AppEventEnum.DialogImportarArquivo, {
            aberto: false
        });
        if(changeRoute){
            push(route)
        }
    }, [callEvent, location.pathname, push]);

    //Dialog Adição Manual Boleto
    const abrirDialogBoleto = useCallback(
        (id?: string) => {
            const route = (location.pathname[location.pathname.length - 1] === '/' ?
                location.pathname.slice(0, location.pathname.length - 1) : location.pathname).replace('/gerenciar', '')
            callEvent(AppEventEnum.DialogBoleto, {
                aberto: true,
                id,
            });
            push(route + boletoRoute + (id || 'adicionar'))
        },
        [callEvent, location.pathname, push]
    );

    const fecharDialogBoleto = useCallback((changeRoute: boolean = true) => {
        const route = location.pathname.split(boletoRoute)[0]
        callEvent(AppEventEnum.DialogBoleto, {
            aberto: false,
        });
        if(changeRoute){
            push(route)
        }
    }, [callEvent, location.pathname, push]);

    //Dialog Adição Manual Boleto
    const abrirDialogDetalhesLote = useCallback(
        (model: LoteListModel) => {
            callEvent(AppEventEnum.DialogDetalhesLote, {
                aberto: true,
                model
            });
        },
        [callEvent]
    );

    const fecharDialogDetalhesLote = useCallback(() => {
        callEvent(AppEventEnum.DialogDetalhesLote, {
            aberto: false,
            model: new LoteListModel(),
        });
    }, [callEvent]);

    //Dialog Aviso
    const abrirDialogAviso = useCallback(
        (
            mode: 'success' | 'error' | 'info' | 'warning',
            text: string,
        ) => {
            callEvent(AppEventEnum.DialogAviso, {
                aberto: true,
                mode,
                text
            })
        },
        [callEvent]
    )

    const fecharDialogAviso = useCallback(
        () => {
            callEvent(AppEventEnum.DialogAviso, {
                aberto: false,
                mode: 'success',
                text: ''
            })
        },
        [callEvent]
    )

    //Dialog Confirm
    const abrirDialogConfirm = useCallback(
        (
            {
                primaryButtonText,
                secondaryButtonText,
                title,
                description,
                primaryButtonColor,
                secondaryButtonColor
            }: ShowConfirmProps
        ) => {
            callEvent(AppEventEnum.DialogConfirm, {
                aberto: true,
                primaryButtonText,
                secondaryButtonText,
                title,
                description,
                primaryButtonColor,
                secondaryButtonColor
            })
        },
        [callEvent]
    )

    const fecharDialogConfirm = useCallback(
        () => {
            callEvent(AppEventEnum.DialogConfirm, {
                aberto: false,
                primaryButtonText: '',
                secondaryButtonText: '',
                title: '',
                description: '',
                primaryButtonColor: '',
                secondaryButtonColor: ''
            })
        },
        [callEvent]
    )

    //Dialog Conta Bancaria
    const abrirDialogContaBancaria = useCallback(
        (
            id?: string,
        ) => {
            const route = (location.pathname[location.pathname.length - 1] === '/' ?
                location.pathname.slice(0, location.pathname.length - 1) : location.pathname).replace('/conta-bancaria', '')
            callEvent(AppEventEnum.DialogContaBancaria, {
                aberto: true,
                id: id
            })
            push(route + contaRoute + (id || 'adicionar'))

        },
        [callEvent, location.pathname, push]
    )

    const fecharDialogContaBancaria = useCallback(
        (changeRoute: boolean = true) => {
            const route = location.pathname.split(contaRoute)[0]
            callEvent(AppEventEnum.DialogContaBancaria, {
                aberto: false,
            })
            if(changeRoute){
                push(route)
            }
        },
        [callEvent, location.pathname, push]
    )

    //Dialog Conta Bancaria
    const abrirDialogHistoricoBoleto = useCallback(
        (
            id: string,
        ) => {
            callEvent(AppEventEnum.DialogHistoricoBoleto, {
                aberto: true,
                id: id
            })
        },
        [callEvent]
    )

    const fecharDialogHistoricoBoleto = useCallback(
        () => {
            callEvent(AppEventEnum.DialogHistoricoBoleto, {
                aberto: false,
            })
        },
        [callEvent]
    )

    return {
        abrirDialogImportarArquivo,
        fecharDialogImportarArquivo,

        abrirDialogBoleto,
        fecharDialogBoleto,

        abrirDialogDetalhesLote,
        fecharDialogDetalhesLote,

        abrirDialogAviso,
        fecharDialogAviso,

        abrirDialogConfirm,
        fecharDialogConfirm,

        abrirDialogContaBancaria,
        fecharDialogContaBancaria,

        abrirDialogHistoricoBoleto,
        fecharDialogHistoricoBoleto
    };
};
