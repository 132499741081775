import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Fade,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./accordion-saurus-styles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { ExpandBoldRoundIcon, ExpandIcon } from "views/components/icons";
import { useCallback } from "react";
import classNames from "classnames";
import { useThemeQueries } from "views/theme";
interface AccordionSaurusProps extends AccordionProps {
  tituloChildren?: React.ReactNode;
  expandChildren?: React.ReactNode;
  labelPrimary?: string;
  labelSecondary?: React.ReactNode;
  colorDivider?: string;
  heightDivider?: number;
  noIconRotation?: boolean;
  noPaperRoot?: boolean;
  noPaddingContent?: boolean;
  tipoExpand?: "padrao" | "bold";
  colorExpand?: string;
  expandSize?: number;
  showDivider?: boolean
}

export const AccordionSaurus = ({
  tituloChildren,
  expandChildren,
  labelPrimary,
  labelSecondary,
  colorDivider,
  showDivider,
  heightDivider,
  noPaperRoot,
  noPaddingContent,
  tipoExpand = 'bold',
  colorExpand,
  expandSize,
  ...props
}: AccordionSaurusProps) => {
  const classes = useStyles();
  const { theme } = useThemeQueries();

  const selectExpand = useCallback(() => {
    switch (tipoExpand) {
      case "bold":
        return (
          <ExpandBoldRoundIcon
            style={{ width: 25, height: 25, marginLeft: 15, fill: colorExpand ?? theme.palette.secondary.main }}
          />
        );
      default:
        return (
          <ExpandIcon
            style={{ width: 25, height: 25, marginLeft: 15, fill: colorExpand ?? theme.palette.secondary.main }}
          />
        );
    }
  }, [tipoExpand, colorExpand, theme.palette.secondary.main]);

  // const accProps = props;
  // delete accProps.expandChildren;
  // delete accProps.titulo;

  return (
    <>
      <Accordion
        {...props}
        classes={{
          root: classNames(noPaperRoot
            ? classes.rootAccordionNoPaper
            : classes.rootAccordion,
            classes.iconWithoutRotation
          ),
          expanded: classes.expanded,
        }}
      >
        <AccordionSummary
          classes={{
            root: noPaddingContent
              ? classes.accordionContentNoPadding
              : classes.accordionContent,
            content: noPaddingContent
              ? classes.accordionSumaryContentNoPadding
              : classes.accordionSumaryContent,
          }}
          expandIcon={expandChildren ? <>{expandChildren}</> : <>{selectExpand()}</>}
        >
          <Grid container spacing={2}>
            <Grid
              className={classes.containerTitle}
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              {tituloChildren && <>{tituloChildren}</>}
              {labelPrimary && (
                <Typography display="inline" className={classes.title}>
                  {labelPrimary}
                </Typography>
              )}
              {labelSecondary}
            </Grid>
            <FormControlLabel
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              label=""
              control={<></>}
            />
          </Grid>
        </AccordionSummary>
        {showDivider && (
          <Fade in timeout={2000}>
            <Grid xs={12} style={{ padding: '0px 25px', marginTop: '-22px' }}>
              <div className={classes.divider} />
            </Grid>
          </Fade>
        )}
        <AccordionDetails
          classes={{
            root: noPaddingContent
              ? classes.accordionContentNoPadding
              : classes.accordionContent,
          }}
        >
          {props.children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
