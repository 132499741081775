import { FormBoleto } from "views/components/form/master/boleto/form-boleto";
import { BoletoFormRef } from "../../dialog-boleto";
import { Button, Grid } from "@material-ui/core";
import { useCallback } from "react";
import { useShowAviso } from "services/app/hooks/show-aviso";
import { usePutBoleto } from "data/api/gestao/boleto/put-boleto";
import { BoletoFormModel } from "model/api/gestao/boleto/boleto-add-form-model";
import { LixoIcon, SalvarEditarIcon } from "views/components/icons";
import { ButtonIcon } from "views/components/controles";
import { useDeleteBoleto } from "data/api/gestao/boleto/delete-boleto";
import { useCadastros } from "services/app/hooks/cadastros";
import { useConfirmSaurus } from "services/app/hooks/confirm-saurus";
import { useThemeQueries } from "views/theme";
import { AccordionHistoricoBoleto } from "./components/accordion-historico";
import { BoletoGetModel } from "model/api/gestao/boleto/boleto-get-model";
import { EnumStatusBoleto } from "model/enums/enum-status-boleto";

interface VisualizacaoBoletoProps {
    formRef: React.RefObject<BoletoFormRef>;
    carregando: boolean;
    id: string;
    boleto: BoletoGetModel;
}

export const VisualizacaoBoleto = ({
    formRef,
    carregando,
    id,
    boleto,
}: VisualizacaoBoletoProps) => {

    const { showAviso } = useShowAviso();
    const { showConfirm } = useConfirmSaurus();
    const { fecharDialogBoleto } = useCadastros();
    const { theme } = useThemeQueries();

    const { putBoleto, carregando: carregandoPut } = usePutBoleto()
    const { deleteBoleto, carregando: carregandoDelete } = useDeleteBoleto();

    const loading = carregando || carregandoPut || carregandoDelete;

    const handleSubmit = useCallback(async (model: BoletoFormModel) => {
        try {
            const res = await putBoleto(model);

            if (res.erro) throw res.erro

            const data = res.resultado?.data;

            if (data?.status.toString() === EnumStatusBoleto.Rejeitada) {
                showAviso('warning', data.historico.list[0].ocorrencia);
                return
            }

            showAviso('success', 'Boleto alterado com sucesso!')
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [putBoleto, showAviso])

    const removeBoleto = useCallback(() => {
        const remove = async () => {
            try {
                const res = await deleteBoleto(id)

                if (res.erro) throw res.erro

                showAviso('success', 'Boleto Excluído.');

                fecharDialogBoleto();
            } catch (e: any) {
                showAviso(e.message, 'error')
            }
        }

        showConfirm({
            title: 'Tem certeza que deseja excluir este Boleto?',
            description: 'Esta ação não poderá ser desfeita.',
            primaryButtonText: 'Excluir',
            secondaryButtonText: 'Cancelar',
            secondaryButtonColor: theme.palette.grey[700]
        }).then(() => remove())
    }, [deleteBoleto, fecharDialogBoleto, id, showAviso, showConfirm, theme.palette.grey])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormBoleto
                    loading={loading}
                    showLoading
                    onSubmit={handleSubmit}
                    ref={formRef}
                />
            </Grid>
            <Grid item xs={12}>
                <AccordionHistoricoBoleto
                    id={id}
                    historico={boleto.historico}
                />
            </Grid>
            <Grid item xs={12} container justifyContent='space-between' alignItems="center">
                <ButtonIcon
                    icon={<LixoIcon tipo='BUTTON' />}
                    color="error"
                    onClick={removeBoleto}
                    size='small'
                    tooltipMessage="Excluir Boleto"
                />
                <Button variant='contained' color='primary' size='small' onClick={() => formRef.current?.submitForm()}>
                    <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                    {boleto.status.toString() === EnumStatusBoleto.Rejeitada ? 'Salvar e Reemitir' : 'Alterar'}
                </Button>
            </Grid>
        </Grid>
    )
}