import { Theme, makeStyles } from "@material-ui/core";

interface StylesProps{
    mode: 'success' | 'error' | 'warning' | 'info'
}

const returnColor = (mode: 'success' | 'error' | 'warning' | 'info', theme: Theme) => {
    switch(mode){
        case 'error':
            return theme.palette.error.dark;
        case 'success':
            return theme.palette.success.dark;
        case 'warning':
            return theme.palette.warning.dark;
        case 'info':
            return theme.palette.info.dark;
    }
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gridGap: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        zIndex: 1303,
        position: 'relative'
    },
    icon: {
        fill: ({mode}) => returnColor(mode, theme),
        width: 92,
        height: 92
    },
    text: {
        color: ({mode}) => returnColor(mode, theme),
        fontWeight: 600
    },
    fecharBox: {
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        zIndex: 1301
    }
}));