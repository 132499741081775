import { Box } from "@material-ui/core"
import { useState } from "react"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { useStyles } from '../accordion-configuracoes-styles'
import { Contas } from "./components/contas-origem/contas"

export const AccordionContasBancarias = () => {

    const classes = useStyles();
    const [aberto, setAberto] = useState<boolean>(false);

    return (
        <AccordionSaurus
            labelPrimary="Contas Bancárias"
            expanded={aberto}
            onChange={() => setAberto(prev => !prev)}
        >
            <Box className={classes.contasBancarias}>
                <Contas aberto={aberto} />
            </Box>
        </AccordionSaurus>
    )
}