import { makeStyles, Theme } from "@material-ui/core";

interface StylesProps{
    expanded?: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    container: {
        '& p': {
            fontSize: '12px !important',
            fontWeight: 500,
        }
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    icon: {
        width: 30,
        height: 30
    },
    finalGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between'
        }
    },
    setaIcon: {
        transform: ({expanded}) => expanded ? 'rotate(0deg)' : 'rotate(180deg)',
        fill: theme.palette.secondary.main,
        width: 25,
        height: 25
    },
    downloadButton: {
        height: 40
    },
    expandedButton: {
        cursor: 'pointer'
    },
    expandedItem: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2)
    },
    pendenteIcon: {
        height: 42,
        fill: theme.palette.warning.main
    },
    aceitoIcon: {
        height: 42,
        fill: theme.palette.info.light
    },
    pagoIcon: {
        height: 42,
        fill: theme.palette.success.main
    },
    canceladoIcon: {
        height: 42,
        fill: theme.palette.error.main
    },
    rejeitadoIcon: {
        height: 42,
        fill: theme.palette.error.dark
    },
    expandCard: {
        animation: `$growAnim 500ms`,
        [theme.breakpoints.down('sm')]: {
            animation: `$growAnimMobile 500ms`,
        },
        paddingBottom: theme.spacing(1)
    },
    shrinkCard: {
        animation: `$shrinkAnim 500ms`,
        [theme.breakpoints.down('sm')]: {
            animation: `$shrinkAnimMobile 500ms`,
        },
        paddingBottom: theme.spacing(1)
    },
    "@keyframes growAnim": {
        "0%": {
            height: 0
        },
        "100%": {
            height: 90,
        }
    },
    "@keyframes shrinkAnim": {
        "0%": {
            height: 90,
        },
        "100%": {
            height: 0
        }
    },
    "@keyframes growAnimMobile": {
        "0%": {
            height: 0
        },
        "100%": {
            height: 162,
        }
    },
    "@keyframes shrinkAnimMobile": {
        "0%": {
            height: 162,
        },
        "100%": {
            height: 0
        }
    }
}))