import { useCallback, useEffect, useRef, useState } from "react";
import { CircularLoading } from "views/components/utils"
import { Grid } from '@material-ui/core'
import { InformacaoIcon } from "views/components/icons";
import { useGetContaBancariaVinculo } from "data/api/gestao/conta-bancaria-vinculo/get-conta-bancaria-vinculo";
import { ContaBancariaVinculoModel, ContaBancariaVinculoPostModel } from "model/api/gestao/conta-bancaria/conta-bancaria-vinculo-model";
import { CardContaBancaria } from "views/components/cards/card-conta-bancaria/card-conta-bancaria";
import { usePostContaBancariaVinculo } from "data/api/gestao/conta-bancaria-vinculo/post-conta-bancaria-vinculo";
import { useDeleteContaBancariaVinculo } from "data/api/gestao/conta-bancaria-vinculo/delete-conta-bancaria-vinculo";
import { guidEmpty } from "utils/guid-empty";
import { CardNaoEncontrado } from "views/components/cards";
import { useConfirmSaurus } from "services/app/hooks/confirm-saurus";
import { useThemeQueries } from "views/theme";
import { useShowAviso } from "services/app/hooks/show-aviso";
import { isEmptyOrGuid } from "utils/is-guid-empty";

interface ContasVinculadasProps {
    id: string;
}

export const ContasVinculadas = ({
    id
}: ContasVinculadasProps) => {
    const { showAviso } = useShowAviso();
    const { showConfirm } = useConfirmSaurus();
    const { theme } = useThemeQueries();

    const { getContasBancariasVinculo, carregando: carregandoGet } = useGetContaBancariaVinculo();
    const { postContaBancariaVinculo, carregando: carregandoPost } = usePostContaBancariaVinculo();
    const { deleteContaBancariaVinculo, carregando: carregandoDelete } = useDeleteContaBancariaVinculo();
 
    const [ list, setList ] = useState<ContaBancariaVinculoModel[]>([])
    const beforeList = useRef<ContaBancariaVinculoModel[]>([])

    const carregando = carregandoGet || carregandoDelete || carregandoPost

    const getContasVinculadasWrapper = useCallback(async () => {
        try {
            const res = await getContasBancariasVinculo(id)

            if(res.erro) throw res.erro

            if(res.resultado){
                beforeList.current = res.resultado.data.list;
                setList(res.resultado.data.list)
            }


        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [getContasBancariasVinculo, id, showAviso])

    const handleSubmit = useCallback(async (model: ContaBancariaVinculoModel) => {
        try{
            if(!isEmptyOrGuid(model.idVinculo)){
                const res = await deleteContaBancariaVinculo(id, model.idVinculo!);

                if(res.erro) throw res.erro

                showAviso('success', `Conta Bancaria ${model.conta} desvinculada com sucesso.`)

                await getContasVinculadasWrapper();
                return
            }

            const value = new ContaBancariaVinculoPostModel(guidEmpty(), '', '', id, model.id);
            const res = await postContaBancariaVinculo(id, value);

            if(res.erro) throw res.erro

            showAviso('success', `Conta Bancaria ${model.conta} vinculada com sucesso.`)

            await getContasVinculadasWrapper();

        }catch(e: any){
            showAviso('error', e.message)
        }
    }, [deleteContaBancariaVinculo, getContasVinculadasWrapper, id, postContaBancariaVinculo, showAviso])

    const confirmSubmit = useCallback((model: ContaBancariaVinculoModel) => {
        showConfirm({
            primaryButtonText: !isEmptyOrGuid(model.idVinculo) ? 'Desvincular' : 'Vincular',
            secondaryButtonText: 'Cancelar',
            title: !isEmptyOrGuid(model.idVinculo) ? 'Tem certeza que deseja desvincular esta conta?' : 'Deseja vincular esta conta?',
            primaryButtonColor: !isEmptyOrGuid(model.idVinculo) ? theme.palette.error.dark : undefined,
            secondaryButtonColor: theme.palette.grey[700],

        }).then(() => handleSubmit(model))
    }, [handleSubmit, showConfirm, theme.palette.error.dark, theme.palette.grey])

    useEffect(() => {
        getContasVinculadasWrapper()
    }, [getContasVinculadasWrapper])

    return (
        <>
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {list.length === 0 && !carregando && (
                        <CardNaoEncontrado
                            icon={<InformacaoIcon tipo='GERAL' />}
                            mensagem="Nenhuma Conta Vinculada."
                        />
                    )}
                    {list.map(conta => {
                        return (
                            <CardContaBancaria
                                model={conta}
                                onClick={confirmSubmit}
                                onDelete={() => {}}
                                vincularMode
                                idVinculo={conta.idVinculo}
                            />
                        )
                    })}
                </Grid>
            </Grid>
        </>
    )
}