import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export class IdentidadeValidarRedefinicaoProps{
    constructor(
        public email: string = '',
        public redefinirCodigo: string = '',
        public telefone: string = '',
    ){}
}

export function usePostIdentidadeValidarRedefinicao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeValidarRedefinicao = useCallback(
        (redefinicaoProps: IdentidadeValidarRedefinicaoProps) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Auth/validar-redefinir-senha`,
                method: 'POST',
                data: redefinicaoProps,
                enviarTokenUsuario: false
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeValidarRedefinicao,
    };
}
