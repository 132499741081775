import { Box, Typography, Button } from "@material-ui/core";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { InformacaoIcon } from "views/components/icons";
import { ShowConfirmProps } from "services/app/hooks/confirm-saurus";
import { useStyles } from "./dialog-confirm-styles";
import { useEventTools } from "services/app/use-cases/events/event-tools";
import { useCallback } from "react";
import { AppEventEnum } from "model/enums/enum-event-app";

export interface DialogConfirmProps extends ShowConfirmProps{
    aberto: boolean;
}

export const DialogConfirm = ({
    primaryButtonText,
    secondaryButtonText,
    title,
    primaryButtonColor,
    secondaryButtonColor,
    description,
    aberto
}: DialogConfirmProps) => {

    const classes = useStyles({
        primary: primaryButtonColor,
        secondary: secondaryButtonColor
    });

    const {callEvent} = useEventTools();

    const callConfirm = useCallback(() => {
        callEvent(AppEventEnum.ConfirmEvent, {})
    }, [callEvent])

    const callCancel = useCallback(() => {
        callEvent(AppEventEnum.CancelEvent, {})
    }, [callEvent])

    return <DialogSaurus
        tamanho='xs'
        aberto={aberto}
    >
        <Box className={classes.container}>
            <InformacaoIcon tipo='GERAL' class={classes.icon} />
            <Typography className={classes.title} align='center'>
                {title}
            </Typography>
            <Typography className={classes.text} align='center'>
                {description}
            </Typography>
            <Box className={classes.buttonContainer}>
                <Button className={classes.buttonPrimary} onClick={callConfirm}>
                    {primaryButtonText}
                </Button>
                <Button className={classes.buttonSecondary} onClick={callCancel}>
                    {secondaryButtonText}
                </Button>
            </Box>
        </Box>
    </DialogSaurus>
}