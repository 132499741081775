import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    menuItem: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'block',
        lineHeight: '40px',
        [theme.breakpoints.up("sm")]: {
            lineHeight: '30px',
        },
    },
    disabledSelect: {

    }
}));
