import { Button, Grid } from '@material-ui/core';
import { useRef, useState } from 'react';
import { useStyles } from './form-empresa-styles';
import { useFormEmpresaValidation } from './form-empresa-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmpresaFormModel } from 'model/app/forms/master/empresa-form';
import { DividerSaurus } from 'views/components/divider/divider-saurus';

export interface FormEmpresaProps extends DefaultFormProps<EmpresaFormModel> {
  setCarregandoExterno(carregando: boolean): void;
}

export const FormEmpresa = React.forwardRef<
  DefaultFormRefs<EmpresaFormModel>,
  FormEmpresaProps
>((props: FormEmpresaProps, refs) => {
  const txtRazaoSocial = useRef<HTMLInputElement>();
  const { FormEmpresaValidationYup } = useFormEmpresaValidation();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState({ modeloForm: new EmpresaFormModel('') });
  const { isMobile } = useThemeQueries();


  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    getValues,
  } = useForm<EmpresaFormModel>({
    defaultValues: { ...model.modeloForm },
    resolver: yupResolver(FormEmpresaValidationYup),
    criteriaMode: 'all',
    mode: 'onSubmit' && 'onTouched',
  });

  const onSubmit = (form: EmpresaFormModel) => {
    props.onSubmit(form, model.modeloForm);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ modeloForm: new EmpresaFormModel('', '') });
      reset();
      if (!isMobile) txtRazaoSocial?.current?.focus();
    },
    fillForm: (model: EmpresaFormModel) => {
      setModel({ modeloForm: model });
      reset({ ...model });
      if (!isMobile) txtRazaoSocial?.current?.focus();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading && (
          <CircularLoading tipo="FULLSIZED" />
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${props.loading && utilClasses.controlLoading
            }`}
        >
          <Grid container spacing={2}>
            <>
              <Grid item xs={12}>
                <Controller
                  name="cpfcnpj"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="CNPJ_CPF"
                      disabled={true}
                      fullWidth={true}
                      showStartAdornment={true}
                      allowSubmit={false}
                      autoComplete={'off'}
                      label="CPF / CNPJ"
                      placeholder=""
                      error={Boolean(
                        errors.cpfcnpj && errors.cpfcnpj.message,
                      )}
                      helperText={
                        touchedFields.cpfcnpj || errors.cpfcnpj
                          ? errors.cpfcnpj?.message
                          : undefined
                      }
                      {...field}
                      value={getValues('cpfcnpj')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="razaoSocial"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="TEXTO"
                      disabled={props.loading}
                      fullWidth={true}
                      showStartAdornment={true}
                      allowSubmit={false}
                      inputRef={txtRazaoSocial}
                      autoComplete={'off'}
                      label="Nome Completo / Razão Social"
                      placeholder=""
                      error={Boolean(
                        errors.razaoSocial && errors.razaoSocial.message,
                      )}
                      helperText={
                        touchedFields.razaoSocial || errors.razaoSocial
                          ? errors.razaoSocial?.message
                          : undefined
                      }
                      {...field}
                      value={getValues('razaoSocial')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="nomeFantasia"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="TEXTO"
                      disabled={props.loading}
                      fullWidth={true}
                      showStartAdornment={true}
                      allowSubmit={false}
                      autoComplete={'off'}
                      label="Nome Fantasia"
                      placeholder=""
                      error={Boolean(
                        errors.nomeFantasia && errors.nomeFantasia.message,
                      )}
                      helperText={
                        touchedFields.nomeFantasia || errors.nomeFantasia
                          ? errors.nomeFantasia?.message
                          : undefined
                      }
                      {...field}
                      value={getValues('nomeFantasia')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <DividerSaurus />
              </Grid>
            </>
          </Grid>
          <input name="cnae" type="hidden" value={getValues('cnae')}></input>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
