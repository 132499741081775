import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

    root: {
        position: 'relative',
        height: 'calc(100% - 8px)',
        overflow: 'auto',
        paddingBottom: theme.spacing(1),
    },
    stepperOuterContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    },
    rootContainer: {
        flex: '1 1 100%',
        display: 'block',
    },
    divForm: {
        boxShadow: '0px 4px 8px 0px #00000033',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '41px',
        borderRadius: '26.4918px',
        background: theme.palette.background.paper,
        zIndex: 3,
        [theme.breakpoints.down('sm')]: {
            padding: '52px 21px',
            boxShadow: 'none',
            background: 'transparent',
        },
        '& h4': {
            fontWeight: 500
        }
    }

}));