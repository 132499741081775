import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePostIdentidadeRedefinirSenha() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeRedefinirSenha = useCallback(
        (email: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Auth/redefinir-senha`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    email
                },
                enviarTokenUsuario: false
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeRedefinirSenha,
    };
}
