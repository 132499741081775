import { isEmpty } from 'lodash';
import { EnumTpDesconto } from 'model/enums/enum-tp-desconto';
import { useMemo } from 'react';
import { toDecimal } from 'utils/to-decimal';
import { useValidationYupDocumento } from 'views/components/form-validations';
import * as Yup from 'yup';


export const useFormBoletoValidation = () => {

    const { documentoYup } = useValidationYupDocumento(false)


    const accordionMockValidation = useMemo(() => ([
        {
            accordion: 'beneficiario',
            fields: [
                'banco',
                'agencia',
                'conta',
                'nomeEmpresa',
                'documentoEmpresa',
                'carteira',
            ]
        },
        {
            accordion: 'boleto',
            fields: [
                'nossoNumero',
                'especie',
                'codigoAceite',
                'dataEmissao',
                'valorTitulo',
                'dataVencimento',
                'valorMulta',
                'dataJurosMora',
                'valorJurosMoraDia',
                'dataDesconto1',
                'tipoDesconto1',
                'valorDesconto1',
                'mensagemRecibo',
                'mensagemFichaCompensacao'
            ]
        },
        // {
        //     accordion: 'beneficiarioFinal',
        //     fields: [
        //         'documentoSacadorAvalista',
        //         'cepSacadorAvalista',
        //         'bairroSacadorAvalista',
        //         'enderecoSacadorAvalista',
        //         'numeroSacadorAvalista',
        //         'complementoSacadorAvalista',
        //         'ufSacadorAvalista',
        //         'cidadeSacadorAvalista',
        //     ]
        // },
        {
            accordion: 'pagador',
            fields: [
                'documentoPagador',
                'nomePagador',
                'cepPagador',
                'bairroPagador',
                'enderecoPagador',
                'numeroPagador',
                'complementoPagador',
                'ufPagador',
                'cidadePagador',
                'emailPagador',
                'numeroCelularPagador',
            ]
        },
    ]), [])

    const FormBoletoValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                banco: Yup.number().required('Informe o banco.').typeError('Informe o banco'),
                agencia: Yup.string().required('Informe a agência.').typeError('Informe a agência'),
                conta: Yup.string().required('Informe a conta.').typeError('Informe a conta'),
                nomeEmpresa: Yup.string().required('Informe o nome do beneficiário.').typeError('nforme o nome do beneficiário.'),
                documentoEmpresa: documentoYup(),
                carteira: Yup.number().required('Informe a carteira').typeError('Informe a carteira'),
                nossoNumero: Yup.number().required('Informe o Nosso Número').typeError('Informe o Nosso Número'),
                dataVencimento: Yup.string().required('Informe a data de vencimento do boleto.').typeError('Informe a data de vencimento do boleto.'),
                valorTitulo: Yup.string().required('Informe o valor do título.').typeError('Informe o valor do título.').test({
                    test: (value) => toDecimal(value) > 0,
                    message: 'Necessário um valor'
                }),
                especie: Yup.string().required('Campo é obrigatório.').typeError('Campo é obrigatório.'),
                codigoAceite: Yup.string().required('Informação obrigatória.').typeError('Informação obrigatória.'),
                dataEmissao: Yup.string().required('Informe a data de emissão do boleto.').typeError('Informe a data de emissão do boleto.'),
                dataJurosMora: Yup.string().test({
                    message: 'Informe a data de Juros e Mora.',
                    test: (value, context) => {
                        if (toDecimal(context.parent.percJurosMoraDia) > 0 && isEmpty(value)) {
                            return false
                        }
                        return true
                    }
                }).nullable(true),
                percJurosMoraDia: Yup.string().required('Informe o percentual do juros mora.').typeError('Informe o percentual do juros mora.').test({
                    message: 'Percentual máximo excedido',
                    test: (value) => {
                        if (value && value.length > 5) {
                            return false
                        }
                        return true
                    }
                }),
                percMulta: Yup.string().required('Informe o percentual da multa.').typeError('Informe o percentual da multa.').test({
                    message: 'Percentual máximo excedido',
                    test: (value) => {
                        if (value && value.length > 5) {
                            return false
                        }
                        return true
                    }
                }),
                tipoDesconto1: Yup.number().required('Informe o tipo de desconto').typeError('Informe o tipo de desconto'),
                dataDesconto1: Yup.string().typeError('Informe a data do desconto').test({
                    message: 'Informe a data do desconto',
                    test: (value, context) => {
                        if (context.parent.tipoDesconto1 !== EnumTpDesconto.SemDesconto && !value) {
                            return false
                        }
                        return true
                    }
                }),
                valorDesconto1: Yup.string().required('Informe o valor do desconto.').typeError('Informe o valor do desconto.'),
                documentoPagador: documentoYup(),
                nomePagador: Yup.string().required('Informe o nome do pagador.').typeError('Informe o nome do pagador.'),
                enderecoPagador: Yup.string().required('Informe o endereço do pagador').typeError('Informe o endereço do pagador'),
                bairroPagador: Yup.string().required('Informe o bairro do pagador').typeError('Informe o bairro do pagador'),
                cidadePagador: Yup.string().required('Informe a cidade do pagador').typeError('Informe a cidade do pagador'),
                ufPagador: Yup.string().required('Informe a UF').typeError('Informa a UF'),
                cepPagador: Yup.string().required('Informe o CEP do pagador').typeError('Informe a agência')
            })
        )
    }, [documentoYup])

    return {
        FormBoletoValidationYup,
        accordionMockValidation
    }
}