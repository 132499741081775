import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    image: {
        flexBasis: '25%',
    }
}));