import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/use-cases/events/event-tools";
import { AppEventEnum } from "model/enums/enum-event-app";
import { DialogConfirm, DialogConfirmProps } from "views/components/dialog/dialog-confirm/dialog-confirm";


export const ConfirmDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<DialogConfirmProps>(
        {
            aberto: false,
            primaryButtonText: '',
            secondaryButtonText: '',
            title: '',
            description: '',
        }
    );

    const modalAlterado = useCallback(({
        aberto,
        primaryButtonText,
        secondaryButtonText,
        title,
        description,
        primaryButtonColor,
        secondaryButtonColor
    }: DialogConfirmProps) => {
        setModalState({
            aberto,
            primaryButtonText,
            secondaryButtonText,
            title,
            description,
            primaryButtonColor,
            secondaryButtonColor
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogConfirm, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogConfirm, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogConfirm
                    aberto={modalState.aberto}
                    primaryButtonText={modalState.primaryButtonText}
                    secondaryButtonText={modalState.secondaryButtonText}
                    title={modalState.title}
                    description={modalState.description}
                    primaryButtonColor={modalState.primaryButtonColor}
                    secondaryButtonColor={modalState.secondaryButtonColor}
                />
            )}
        </>
    )
}