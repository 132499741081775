import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { BoletoFormModel } from 'model/api/gestao/boleto/boleto-add-form-model';
import { useSessaoAtual } from 'services/app';
import { ContaBancariaFormModel } from 'model/app/forms/master/conta-bancaria-form';

export function usePostContaBancaria() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<BoletoFormModel>();
    const { getEmpresa } = useSessaoAtual();

    const empresaId = getEmpresa().Id;

    const postContaBancaria = useCallback(
        (model: ContaBancariaFormModel) =>
            invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/empresa/${empresaId}/conta-bancaria`,
                data: {...model, empresaId: empresaId},
                enviarTokenUsuario: true
            }),
        [empresaId, invocarApi],
    );
    return {
        ...outrasPropriedades,
        postContaBancaria,
    };
}