import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { BoletoFormModel } from 'model/api/gestao/boleto/boleto-add-form-model';
import { useSessaoAtual } from 'services/app';

export function usePostAdicionarBoletos() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<BoletoFormModel[]>();
    const { getEmpresa } = useSessaoAtual();

    const postAdicionarBoletos = useCallback(
        (model: BoletoFormModel[]) => {
            model = model.map(x => ({
                ...x,
                contratoId: getEmpresa().ContratoId
            }))
            return invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/Empresa/${getEmpresa().Id}/boleto/adicao-boletos`,
                data: model,
                enviarTokenUsuario: true
            })
        },
        [getEmpresa, invocarApi],
    );
    return {
        ...outrasPropriedades,
        postAdicionarBoletos,
    };
}
