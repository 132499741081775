import { Button, Grid, Typography, Box } from '@material-ui/core'
import { FormContaBancaria } from 'views/components/form/master/conta-bancaria/form-conta-bancaria'
import { useStyles } from '../conta-bancaria-crud-styles'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EnumBanco } from 'model/enums/enum-banco';
import { BancoLogoMock } from 'data/mocks/banco-logo-mock';
import { BancoMock } from 'data/mocks/banco-mock';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { ContaBancariaFormModel } from 'model/app/forms/master/conta-bancaria-form';
import { DefaultFormRefs } from 'views/components/form/utils';
import { EditarIcon, SalvarEditarIcon, VoltarIcon } from 'views/components/icons';
import { FormParametrizacaoBoleto } from 'views/components/form/master/parametrizacao-boletos/form-parametrizacao-boletos';
import { ParametrizacaoBoletoModel } from 'model/app/forms/master/parametrizacao-boleto-model';
import { DialogContaBancariaList } from 'views/components/dialog/dialog-conta-bancaria-list/dialog-conta-bancaria-list';
import { EnumTelaContaBancaria } from 'model/enums/enum-tela-conta-bancaria';
import { useGetContaBancariaById } from 'data/api/gestao/conta-bancaria/get-conta-bancaria-by-id';
import { CircularLoading } from 'views/components/utils';
import { usePostContaBancariaConfiguracao } from 'data/api/gestao/conta-bancaria-configuracao/post-conta-bancaria-configuracao';
import { usePutContaBancariaConfiguracao } from 'data/api/gestao/conta-bancaria-configuracao/put-conta-bancaria-configuracao';
import { usePutContaBancaria } from 'data/api/gestao/conta-bancaria/put-conta-bancaria';
import { isEmpty } from 'lodash';
import { useGetContaBancariaConfiguracao } from 'data/api/gestao/conta-bancaria-configuracao/get-conta-bancaria-configuracao';
import { useShowAviso } from 'services/app/hooks/show-aviso';
import { useSessaoAtual } from 'services/app';
import { guidEmpty } from 'utils/guid-empty';
import { picker } from 'utils/picker';
import { EnumTpEspecie } from 'model/enums/enum-tp-especie';

interface ContaBancariaEdicaoProps {
    id: string;
}

export const ContaBancariaEdicao = ({
    id
}: ContaBancariaEdicaoProps) => {
    const { showAviso } = useShowAviso();
    const { getEmpresa } = useSessaoAtual();

    const { getContaBancariaById, carregando: carregandoGet } = useGetContaBancariaById();
    const { getContaBancariaConfiguracao, carregando: carregandoGetConfig } = useGetContaBancariaConfiguracao();
    const { postContaBancariaConfiguracao, carregando: carregandoPostConfig } = usePostContaBancariaConfiguracao();
    const { putContaBancariaConfiguracao, carregando: carregandoPutConfig } = usePutContaBancariaConfiguracao();
    const { putContaBancaria, carregando: carrengadoPut } = usePutContaBancaria();

    const carregando = carregandoGet || carregandoPostConfig || carregandoPutConfig || carrengadoPut || carregandoGetConfig;

    const [banco, setBanco] = useState<EnumBanco>(EnumBanco.Safra)
    const [tela, setTela] = useState<EnumTelaContaBancaria>(EnumTelaContaBancaria.CONTA)
    const [dialogContas, setDialogContas] = useState<boolean>(false)

    const classes = useStyles({
        tela
    });

    const formContaRef = useRef<DefaultFormRefs<ContaBancariaFormModel>>(null)
    const formParametrizacaoRef = useRef<DefaultFormRefs<ParametrizacaoBoletoModel>>(null)

    const dadosBanco = useMemo(() => {
        return {
            logo: BancoLogoMock.find(logo => logo.Key === banco)?.Value || SemImagem,
            nome: BancoMock.find(banco => banco.Key === banco)?.Value || 'Banco não identificado'
        }
    }, [banco])

    const fillFormConta = useCallback((model: ContaBancariaFormModel) => {
        setBanco(model.banco)
        formContaRef.current?.fillForm(model)
    }, [])

    const getContaBancaria = useCallback(async () => {
        try {
            const res = await getContaBancariaById(id);

            if (res.erro) throw res.erro
            if (!res.resultado?.data) throw new Error('Erro ao carregar Conta.');

            fillFormConta(res.resultado.data)
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [fillFormConta, getContaBancariaById, id, showAviso])

    const getContaBancariaConfiguracaoWrapper = useCallback(async () => {
        try {
            const res = await getContaBancariaConfiguracao(id);

            if (res.erro) throw res.erro
            if (!res.resultado?.data) throw new Error('Erro ao carregar Configuração da conta.');
            if (res.resultado.data.list.length > 0)
                formParametrizacaoRef.current?.fillForm({
                    ...res.resultado.data.list[0],
                    especie: res.resultado.data.list[0].especie?.toString()[0] as EnumTpEspecie,
                    layoutArquivoRetorno: res.resultado.data.list[0].layoutArquivoRetorno?.toString().split(' ')[0] as '240' | '300'
                });
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [getContaBancariaConfiguracao, id, showAviso])

    const handleSubmit = useCallback(async (model: ContaBancariaFormModel) => {
        try {

            const res = await putContaBancaria(model);

            if (res.erro) throw res.erro;

            const nome = BancoMock.find(banco => banco.Key === banco)?.Value || ''

            showAviso('success', `Conta Bancaria ${nome} alterada com sucesso!`);
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [putContaBancaria, showAviso])

    const handleSubmitParametrizacao = useCallback(async (model: ParametrizacaoBoletoModel) => {
        try {
            model = picker<ParametrizacaoBoletoModel>(model, new ParametrizacaoBoletoModel())
            if (isEmpty(model.id) || model.id === guidEmpty()) {
                model.contratoId = getEmpresa().ContratoId;
                model.contaBancariaId = id;
                model.id = guidEmpty();
                model.empresaId = getEmpresa().Id;

                const res = await postContaBancariaConfiguracao(id, model)

                if (res.erro) throw res.erro

                showAviso('success', `Configuração de Boletos atualizada.`);
                await getContaBancariaConfiguracaoWrapper();
                setTela(1)
                return
            }

            const res = await putContaBancariaConfiguracao(id, model);

            if (res.erro) throw res.erro

            showAviso('success', `Configuração de Boletos atualizada.`);
            setTela(1)
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [getContaBancariaConfiguracaoWrapper, getEmpresa, id, postContaBancariaConfiguracao, putContaBancariaConfiguracao, showAviso])

    useEffect(() => {
        getContaBancaria().then(() => {
            getContaBancariaConfiguracaoWrapper();
        });
    }, [getContaBancaria, getContaBancariaConfiguracaoWrapper])

    return (
        <>
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.dialogTitle}>
                    <img
                        src={dadosBanco.logo}
                        alt={dadosBanco.nome}
                    />
                    <Typography variant='h4'>
                        {tela === EnumTelaContaBancaria.CONTA ? 'Editar dados da Conta' : 'Configuração do Boleto'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.formConta}>
                        <FormContaBancaria
                            loading={carregando}
                            showLoading={false}
                            onSubmit={handleSubmit}
                            setBanco={setBanco}
                            ref={formContaRef}
                        />
                    </Box>

                    <Box className={classes.formParametrizacao}>
                        <FormParametrizacaoBoleto
                            loading={carregando}
                            showLoading={false}
                            onSubmit={handleSubmitParametrizacao}
                            ref={formParametrizacaoRef}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant='outlined'
                        color='primary'
                        fullWidth
                        size='small'
                        onClick={() => setTela(prev => prev === 1 ? 2 : 1)}>
                        {tela === EnumTelaContaBancaria.CONTA ? (
                            <>
                                <EditarIcon tipo='BUTTON' />
                                Alterar Configuração do Boleto
                            </>
                        ) : (
                            <>
                                <VoltarIcon tipo='BUTTON' />
                                Voltar para Conta
                            </>
                        )}
                    </Button>
                </Grid>
                {tela === EnumTelaContaBancaria.CONTA && <Grid item xs={12}>
                    <Button
                        variant='outlined'
                        color='primary'
                        fullWidth
                        size='small'
                        onClick={() => setDialogContas(true)}
                    >
                        <EditarIcon tipo='BUTTON' />
                        Escolher Contas Vinculadas
                    </Button>
                </Grid>}
                <Grid item xs={12} className={classes.buttonContainer}>
                    <Button variant='contained' color='primary' onClick={tela === EnumTelaContaBancaria.CONTA ? formContaRef.current?.submitForm : formParametrizacaoRef.current?.submitForm}>
                        <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                        {tela === EnumTelaContaBancaria.CONTA ? 'Salvar' : 'Salvar Configuração'}
                    </Button>
                </Grid>
            </Grid>
            <DialogContaBancariaList
                id={id}
                aberto={dialogContas}
                handleClose={() => setDialogContas(false)}
            />
        </>
    )
}