import { Box } from "@material-ui/core";
import { InicialPageSearchProps } from "../inicial-page-search/inicial-page-search-props";
import { useDefaultPageStyles } from "views/theme/pages-styles";
import { ArquivoListData } from "./arquivo-list-data";
import { useCallback, useEffect, useState } from "react";
import { BoletoListModel } from "model/api/gestao/boleto/boleto-list-model";
import { ApiListModel } from "model/api/api-list-model/api-list-model";
import { Paginacao } from "views/components/paginacao";
import { InfoBoletos } from "../info-boletos/info-boletos";
import { useCadastros } from "services/app/hooks/cadastros";
import { useGetBoletos } from "data/api/gestao/boleto/get-boletos";
import { isFalseUndefinedNull } from "utils/check-type";
import { AppEventEnum } from "model/enums/enum-event-app";
import { useEventTools } from "services/app/use-cases/events/event-tools";
import { useShowAviso } from "services/app/hooks/show-aviso";
import { EnumStatusBoleto } from "model/enums/enum-status-boleto";

interface ArquivoListProps {
    searchProps: InicialPageSearchProps;
}

export const ArquivoList = ({
    searchProps
}: ArquivoListProps) => {

    const classes = useDefaultPageStyles();
    const { showAviso } = useShowAviso();
    const { abrirDialogBoleto } = useCadastros();
    const { addHandler, removeHandler } = useEventTools();

    const { getBoletos, carregando } = useGetBoletos();

    const [queryList, setQueryList] = useState<ApiListModel<BoletoListModel>>({
        list: [],
        pageIndex: 1,
        pageSize: 0,
        totalPages: 0,
        totalResults: 0
    })


    const search = useCallback(async (page: number) => {
        try {
            const query = new URLSearchParams();
            if (!isFalseUndefinedNull(searchProps.status)) {
                query.append('tpStatus', searchProps.status.toString())
            }

            if (!isFalseUndefinedNull(searchProps.tipoData)) {
                query.append('tpData', searchProps.tipoData.toString())
            }

            if (!isFalseUndefinedNull(searchProps.dataInicial)) {
                query.append('dInicial', searchProps.dataInicial)
            }

            if (!isFalseUndefinedNull(searchProps.dataFinal)) {
                query.append('dFinal', searchProps.dataFinal)
            }

            query.append('Page', page.toString())
            query.append('PageSize', '30')

            const res = await getBoletos(query.toString());

            if (res.erro) {
                throw res.erro
            }

            if (!res.resultado?.data) {
                throw new Error('Erro ao listar boletos. Tente novamente.')
            }

            if (
                res.resultado?.data.pageIndex > res.resultado?.data.totalPages &&
                res.resultado?.data.totalResults > 0
            ) {
                search(res.resultado.data.totalPages);
                return;
            }

            setQueryList({
                ...res.resultado.data,
                list: res.resultado.data.list.map(boleto => ({...boleto, status: boleto.status.toString()[0] as EnumStatusBoleto}))
            })


        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [getBoletos, searchProps.dataFinal, searchProps.dataInicial, searchProps.status, searchProps.tipoData, showAviso])

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryList.totalPages || newPage > 0) {
                search(newPage);
            }
        },
        [queryList.totalPages, search]
    );

    useEffect(() => {
        search(1)
    }, [search])

    useEffect(() => {
        const searchCallback = ({ aberto }: any) => {
            if (!aberto) {
                search(queryList.pageIndex);
            }
        }
        addHandler(AppEventEnum.DialogBoleto, searchCallback)
        return () => {
            removeHandler(AppEventEnum.DialogBoleto, searchCallback)
        }
    }, [addHandler, queryList.pageIndex, removeHandler, search])

    const onCardClick = useCallback((model: BoletoListModel) => {
        abrirDialogBoleto(model.id)
    }, [abrirDialogBoleto])


    return (
        <>
            <Box className={classes.defaultContainer}>
                <Box className={classes.list}>
                    <Paginacao
                        pageChanged={pageChanged}
                        totalPages={queryList.totalPages}
                        totalRegisters={queryList.totalResults}
                        currentPage={queryList.pageIndex}
                    />
                    <Box className={classes.listData}>
                        <ArquivoListData
                            carregando={carregando}
                            list={queryList.list}
                            onCardClick={onCardClick}
                        />
                    </Box>
                    <InfoBoletos boletos={queryList.list}>
                        <></>
                    </InfoBoletos>
                </Box>
            </Box>
        </>
    )
}