import { Button, Grid } from '@material-ui/core';
import { useRef, useState, useEffect } from 'react';
import { useStyles } from './form-empresa-styles';
import { useFormEmpresaValidation } from './form-empresa-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
    DefaultFormRefs,
    DefaultFormProps,
} from '../../utils/form-default-props';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useConsultaCEP } from 'data/api/wsmaster/consultar-cep';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToastSaurus } from 'services/app';
import { UFMock } from 'data/mocks/uf-mock';
import { EmpresaEnderecoFormModel } from 'model/app/forms/master/empresa-endereco-form';
import { DividerSaurus } from 'views/components/divider/divider-saurus';

export interface FormEmpresaProps extends DefaultFormProps<EmpresaEnderecoFormModel> {
    setCarregandoExterno(carregando: boolean): void;
}

export const FormEnderecoEmpresa = React.forwardRef<
    DefaultFormRefs<EmpresaEnderecoFormModel>,
    FormEmpresaProps
>((props: FormEmpresaProps, refs) => {
    const txtRazaoSocial = useRef<HTMLInputElement>();
    const txtNro = useRef<HTMLInputElement>();
    const txtLogradouro = useRef<HTMLInputElement>();
    const { FormEmpresaValidationYup } = useFormEmpresaValidation();
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const [model, setModel] = useState({ modeloForm: new EmpresaEnderecoFormModel('') });
    const { showToast } = useToastSaurus();
    const { consultaCEP, carregando: carregandoCep } = useConsultaCEP();
    const { isMobile } = useThemeQueries();

    useEffect(() => {
        if (props.setCarregandoExterno) props.setCarregandoExterno(carregandoCep);
    }, [props, carregandoCep]);

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        getValues,
        setValue,
    } = useForm<EmpresaEnderecoFormModel>({
        defaultValues: { ...model.modeloForm },
        resolver: yupResolver(FormEmpresaValidationYup),
        criteriaMode: 'all',
        mode: 'onSubmit' && 'onTouched',
    });

    const onSubmit = (form: EmpresaEnderecoFormModel) => {
        props.onSubmit(form, model.modeloForm);
    };

    React.useImperativeHandle(refs, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
            setModel({ modeloForm: new EmpresaEnderecoFormModel('', '') });
            reset();
            if (!isMobile) txtRazaoSocial?.current?.focus();
        },
        fillForm: (model: EmpresaEnderecoFormModel) => {
            setModel({ modeloForm: model });
            reset({ ...model });
            if (!isMobile) txtRazaoSocial?.current?.focus();
        },
    }));

    return (
        <>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading && (
                    <CircularLoading tipo="FULLSIZED" />
                )}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={`${classes.formContainer} ${props.loading && utilClasses.controlLoading
                        }`}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="cep"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="CEP"
                                        disabled={props.loading}
                                        fullWidth={true}
                                        allowSubmit={false}
                                        searchable
                                        autoComplete={'off'}
                                        label="CEP"
                                        placeholder=""
                                        onSearch={async (value: string) => {
                                            try {
                                                let res = await consultaCEP(value);

                                                setValue('cep', res.CEP);
                                                setValue('bairro', res.Bairro);
                                                setValue('logradouro', res.Logradouro);
                                                setValue('uf', res.UF);
                                                setValue('municipio', res.Municipio);
                                                setValue('cMunicipio', res.CMun?.toString());
                                                if (!isMobile) txtNro.current?.focus();
                                            } catch (e: any) {
                                                showToast('error', e.message);
                                                if (!isMobile) txtLogradouro.current?.focus();
                                            }
                                        }}
                                        error={Boolean(errors.cep && errors.cep.message)}
                                        helperText={
                                            touchedFields.cep || errors.cep
                                                ? errors.cep?.message
                                                : undefined
                                        }
                                        {...field}
                                        value={getValues('cep')}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Controller
                                name="logradouro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        inputRef={txtLogradouro}
                                        disabled={props.loading}
                                        fullWidth
                                        variant="outlined"
                                        label="Endereço"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder=""
                                        error={Boolean(
                                            errors.logradouro && errors.logradouro.message,
                                        )}
                                        helperText={
                                            touchedFields.logradouro || errors.logradouro
                                                ? errors.logradouro?.message
                                                : undefined
                                        }
                                        {...field}
                                        value={getValues('logradouro')}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Controller
                                name="numero"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        inputRef={txtNro}
                                        disabled={props.loading}
                                        fullWidth
                                        variant="outlined"
                                        label="Nº"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder=""
                                        error={Boolean(errors.numero && errors.numero.message)}
                                        helperText={
                                            touchedFields.numero || errors.numero
                                                ? errors.numero?.message
                                                : undefined
                                        }
                                        {...field}
                                        value={getValues('numero')}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="complemento"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        variant="outlined"
                                        label="Complemento"
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="Ex: Apartamento 111"
                                        error={Boolean(
                                            errors.complemento && errors.complemento.message,
                                        )}
                                        helperText={
                                            touchedFields.complemento || errors.complemento
                                                ? errors.complemento?.message
                                                : undefined
                                        }
                                        {...field}
                                        value={getValues('complemento')}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="bairro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        variant="outlined"
                                        label="Bairro"
                                        InputLabelProps={{ shrink: true }}
                                        error={Boolean(errors.bairro && errors.bairro.message)}
                                        helperText={
                                            touchedFields.bairro || errors.bairro
                                                ? errors.bairro?.message
                                                : undefined
                                        }
                                        {...field}
                                        value={getValues('bairro')}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={8}>
                            <Controller
                                name="municipio"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        variant="outlined"
                                        label="Município"
                                        InputLabelProps={{ shrink: true }}
                                        error={Boolean(
                                            errors.municipio && errors.municipio.message,
                                        )}
                                        helperText={
                                            touchedFields.municipio || errors.municipio
                                                ? errors.municipio?.message
                                                : undefined
                                        }
                                        {...field}
                                        value={getValues('municipio')}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name="uf"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        disabled={props.loading}
                                        conteudo={UFMock}
                                        fullWidth
                                        variant="outlined"
                                        label={'UF'}
                                        {...field}
                                        value={UFMock.find(uf => uf.Value === getValues('uf'))?.Key}
                                        onChange={(event) => {
                                            if (event) {
                                                const item = UFMock.filter(
                                                    (item) => item.Key === event.target.value,
                                                )[0];
                                                if (item) {
                                                    setValue('uf', item.Value);
                                                }
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DividerSaurus />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});
