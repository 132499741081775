import { useCadastros } from "services/app/hooks/cadastros";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { Box, Button, Typography } from "@material-ui/core";
import { useStyles } from './dialog-importar-arquivo-styles'
import { useCallback, useState } from "react";
import { DragDropInput, FileBase64 } from "views/components/controles/inputs/drag-drop-input/drag-drop-input";
import { ConfirmarIcon } from "views/components/icons/confirmar-icon";
import { CardArquivoSelecionado } from "./components/card-arquivo-selecionado";
import { useShowAviso } from "services/app/hooks/show-aviso";
import { useHistory } from "react-router-dom";
import { usePostImportacaoArquivo } from "data/api/gestao/importacao-arquivo/post-importacao-arquivo";
import { ImportacaoArquivoModel } from "model/api/gestao/importacao-arquivo/importacao-arquivo-model";
import { CircularLoading } from "views/components/utils";
import { isEmpty } from "lodash";
import { ImportacaoArquivoPostModel } from "model/api/gestao/importacao-arquivo/importacao-arquivo-post-model";
import { EnumTipoArquivo } from "model/enums/enum-tipo-arquivo";
import { TipoArquivoMock } from "data/mocks/tipo-arquivo-mock";

interface DialogImportarArquivoProps {
    aberto: boolean;
}

export const DialogImportarArquivo = ({
    aberto
}: DialogImportarArquivoProps) => {

    const { fecharDialogImportarArquivo } = useCadastros();
    const classes = useStyles();
    const { showAviso } = useShowAviso();
    const { push } = useHistory();

    const {postImportacaoArquivo, carregando } = usePostImportacaoArquivo();

    const [files, setFiles] = useState<FileBase64[]>([])

    
    const handleSubmit = useCallback(async () => {
        try {
            if(isEmpty(files)){
                throw new Error('Nenhum arquivo selecionado.')
            }
            
            const model: ImportacaoArquivoModel[] = files.map(file => {
                const fileSplit = file.file.name.split('.')
                const extensao = fileSplit[fileSplit.length - 1]
                let tipoArquivo: EnumTipoArquivo;
                switch(extensao){
                    case 'xml':
                        tipoArquivo = EnumTipoArquivo.NFe;
                        break;
                    case 'ret':
                        tipoArquivo = EnumTipoArquivo.Retorno;
                        break;
                    default:
                        tipoArquivo = EnumTipoArquivo.Remessa;
                }

                const base64 = file.base64.split(',')[1] || ''
                return new ImportacaoArquivoModel(tipoArquivo, file.file.name, base64)
            })

            const contemNFeRemessa = Boolean(model.find(arq => arq.tpArquivo === EnumTipoArquivo.NFe || arq.tpArquivo === EnumTipoArquivo.Remessa))

            const res = await postImportacaoArquivo(new ImportacaoArquivoPostModel(model))

            if(res.erro) throw res.erro

            if(isEmpty(res.resultado?.data) && contemNFeRemessa){
                throw new Error('Não foram detectados boletos nos arquivos selecionados.')
            }

            const labelArquivos = model.reduce<string>((prev, curr, i) => {
                const tipo = TipoArquivoMock.find(x => x.Key === curr.tpArquivo)?.Value || '';
                const prefixo = prev === '' || prev.includes(tipo) ? '' : i === model.length - 1 ? ' e ' : ', '
                return `${prev}${prefixo}${prev.includes(tipo) ? '' : tipo}`
            } , '')

            showAviso('success', `Arquivos de ${labelArquivos} processados com sucesso.`)

            if(contemNFeRemessa){
                push({
                    pathname: '/importacao',
                    state: {
                        list: res.resultado!.data
                    }
                })
            }

            fecharDialogImportarArquivo(false);
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [fecharDialogImportarArquivo, files, postImportacaoArquivo, push, showAviso])

    return <DialogSaurus
        tamanho='xs'
        isButtonTitleClose
        aberto={aberto}
        handleClickClose={fecharDialogImportarArquivo}
        bottomArea={<Box className={classes.bottom}>
            {carregando && <CircularLoading tipo='FULLSIZED'/>}
            <Box className={classes.itemInfo}>
                <Box
                    className={classes.tagStatus}
                ></Box>
                <Box className={classes.textContainer}>
                    <Typography variant='caption' color='textSecondary'>
                        Quantidade de Boletos Selecionados
                    </Typography>
                    <Typography variant='caption' color='textPrimary'>
                        {files.length} Boletos
                    </Typography>
                </Box>
            </Box>
            <Button variant='contained' color='primary' size='small' onClick={() => handleSubmit()}>
                <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                Confirmar
            </Button>
        </Box>}
    >
        <Box className={classes.container}>
            <DragDropInput setFiles={setFiles} extensoes={['xml', 'ret', 'rem']} onDragOverlay />
            <Box className={classes.list}>
                {files.map(file => (
                    <CardArquivoSelecionado file={file.file} setFile={setFiles}/>
                ))}
            </Box>
        </Box>
    </DialogSaurus>
}