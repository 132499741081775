import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetPoliticaValidar() {

    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPoliticaValidar = useCallback(
        (contratoId: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/termo-privacidade/${contratoId}/validar-termo`,
                method: 'GET',
                enviarTokenUsuario: true,
            })
        ,
        [invocarApi],
    );

    return {
        ...outrasPropriedades,
        getPoliticaValidar,
    };

}
