import { DefaultCard, useDefaultCardStyles } from "../components/default-card";
import { useStyles } from "./card-conta-bancaria-styles";
import { Tooltip, Grid, Typography, Box } from '@material-ui/core'
import { ButtonIcon } from "views/components/controles";
import { ContaBancariaListModel } from "model/api/gestao/conta-bancaria/conta-bancaria-list-model";
import { useMemo } from "react";
import { BancoLogoMock } from "data/mocks/banco-logo-mock";
import SemImagem from 'assets/img/sem-imagem.jpg'
import { CancelarIcon, LixoIcon, NovoIcon } from "views/components/icons";
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { ContaBancariaVinculoModel } from "model/api/gestao/conta-bancaria/conta-bancaria-vinculo-model";
import { isEmptyOrGuid } from "utils/is-guid-empty";

interface CardContaBancariaProps {
    model: ContaBancariaListModel | ContaBancariaVinculoModel;
    onClick: (model: any) => void;
    onDelete: (id: string) => void;
    vincularMode?: boolean;
    idVinculo?: string | null;
}

export const CardContaBancaria = ({
    model,
    onClick,
    onDelete,
    vincularMode,
    idVinculo
}: CardContaBancariaProps) => {
    const classes = useDefaultCardStyles({});
    const cardClasses = useStyles({vinculado: !isEmptyOrGuid(idVinculo)});
    const dadosBanco = useMemo(() => {
        return {
            logo: BancoLogoMock.find(logo => logo.Key === model.banco)?.Value || SemImagem,
        }
    }, [model.banco])

    return (
        <>
            <DefaultCard onClick={() => {
                if (!vincularMode) {
                    onClick(model)
                }
            }}>
                <Tooltip title={model.nomeBanco}>
                    <img src={dadosBanco.logo} alt={model.nomeBanco} className={cardClasses.logo} />
                </Tooltip>
                <div className={`${classes.cardContent} ${cardClasses.container}`}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={2}>
                            <Typography color="textSecondary" variant="caption">
                                Banco
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            >
                                {model.nomeBanco}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textSecondary" variant="caption">
                                Agência
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.agencia}-{model.digitoConta}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textSecondary" variant="caption">
                                Conta
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.conta}-{model.digitoConta}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3} lg={vincularMode ? 2 : 4}>
                            <Typography color="textSecondary" variant="caption">
                                Titular da Conta
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.nomeTitular}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Typography color="textSecondary" variant="caption">
                                Documento Titular
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {formatarCPFCNPJ(model.documentoTitular)}
                            </Typography>
                        </Grid>
                        {vincularMode && <Grid item xs={6} md={2}>
                            <Box className={cardClasses.statusVinculo}>
                                <Typography>{!isEmptyOrGuid(idVinculo) ? 'Vinculado' : 'Não Vinculado'}</Typography>
                            </Box>
                        </Grid>}
                    </Grid>
                </div>
                <Box className={cardClasses.iconsContainer}>
                    {!vincularMode ? <Tooltip title='Excluir Conta'>
                        <Box>
                            <ButtonIcon
                                icon={<LixoIcon tipo='BUTTON' />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete(model.id)
                                }}
                            />
                        </Box>
                    </Tooltip> : <Tooltip title={!isEmptyOrGuid(idVinculo) ? 'Desvincular Conta' : 'Vincular Conta'}>
                        {!isEmptyOrGuid(idVinculo) ? (
                            <Box>
                                <ButtonIcon
                                    icon={<CancelarIcon tipo='BUTTON' />}
                                    color="error"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onClick(model as ContaBancariaVinculoModel)
                                    }}
                                />
                            </Box>
                        ) : (
                            <Box>
                                <ButtonIcon
                                    icon={<NovoIcon tipo='BUTTON' />}
                                    color="success"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onClick(model as ContaBancariaVinculoModel)
                                    }}
                                />
                            </Box>
                        )}
                    </Tooltip>}
                </Box>
            </DefaultCard>
        </>
    );
};