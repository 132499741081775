import { useMemo } from 'react';
import * as Yup from 'yup';
import {
    useValidationYupSenhaConfirmacao,
    useValidationYupEmail,
    useValidationYupNome,
    useValidationYupSenha,
    useValidationYupFone
} from '../../../form-validations'


export const useFormContratoUsuarioValidation = () => {

    const { nomeYup } = useValidationYupNome()
    const { emailYup } = useValidationYupEmail()
    const { senhaYup } = useValidationYupSenha()
    const { senhaConfirmacaoYup } = useValidationYupSenhaConfirmacao()
    const foneYup = useValidationYupFone();
    const FormContratoUsuarioValidationYup = useMemo(() => {
        return Yup.object().shape({
            saudacao: nomeYup(),
            email: emailYup(),
            senha: senhaYup(),
            confirmar: senhaConfirmacaoYup('senha'),
            apelido: Yup.string().required('Apelido é obrigatório.').min(3, 'Seu apelido deve conter no mínimo 3 caracteres.'),
            fone: foneYup().required('Telefone é obrigatório'),
        });
    }, [nomeYup, emailYup, senhaYup, senhaConfirmacaoYup, foneYup])

    return {
        FormContratoUsuarioValidationYup
    }
}

