import { KeyValueModel } from "model";
import { EnumTpEspecie } from "model/enums/enum-tp-especie";

export const TpEspecieMock: KeyValueModel[] = [
    new KeyValueModel(EnumTpEspecie.CartaoCredito, "Cartão de Crédito"),
    new KeyValueModel(EnumTpEspecie.DuplicataMercantil, "Duplicata Mercantil"),
    new KeyValueModel(EnumTpEspecie.DuplicataServicos, "Duplicata Serviços"),
    new KeyValueModel(EnumTpEspecie.NotaPromissoria, "Nota Promissoria"),
    new KeyValueModel(EnumTpEspecie.NotaSeguro, "Nota Seguro"),
    new KeyValueModel(EnumTpEspecie.Recibo, "Recibo"),
];