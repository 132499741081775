import { Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses } from 'views';
import React, { useCallback, useRef, useState } from 'react';
import {
    DefaultFormProps,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { BoletoFormModel } from 'model/api/gestao/boleto/boleto-add-form-model';
import { isEmpty, isEqual } from 'lodash';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useFormBoletoValidation } from './form-boleto-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { BoletoFormRef } from 'views/components/dialog/dialog-boleto/dialog-boleto';
import { ContaBancariaConfiguracaoModel } from 'model/api/gestao/conta-bancaria-configuracao/conta-bancaria-configuracao-model';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useConsultaCEP, useConsultaCnpj } from 'data/api/wsmaster';
import { useToastSaurus } from 'services/app';
import { BancoMock } from 'data/mocks/banco-mock';
import { DialogContaBancariaList } from 'views/components/dialog/dialog-conta-bancaria-list/dialog-conta-bancaria-list';
import { ContaBancariaListModel } from 'model/api/gestao/conta-bancaria/conta-bancaria-list-model';
import { useGetContaBancariaConfiguracao } from 'data/api/gestao/conta-bancaria-configuracao/get-conta-bancaria-configuracao';
import { UFMock } from 'data/mocks/uf-mock';
import { TpEspecieMock } from 'data/mocks/tp-especie-mock';
import { EnumTpDocumento } from 'model/enums/enum-tp-documento';
import { TpAceiteMock } from 'data/mocks/tp-aceite-mock';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { EnumTpAceite } from 'model/enums/enum-tp-aceite';
import { EnumTpEspecie } from 'model/enums/enum-tp-especie';
import { TpDescontoMock } from 'data/mocks/tp-desconto-mock';
import { EnumTpDesconto } from 'model/enums/enum-tp-desconto';
import { stringNumeros } from 'utils/string-numeros';
import { toDateString } from 'utils/to-date';
import { useShowAviso } from 'services/app/hooks/show-aviso';
import { limitesCodigoDefesaConsumidor } from '../parametrizacao-boletos/form-parametrizacao-boletos';
import { useConfirmSaurus } from 'services/app/hooks/confirm-saurus';
import { useStyles } from './form-boleto-styles'

interface BoletoFormProps extends DefaultFormProps<BoletoFormModel> {
    preencherConfigAuto?: boolean;
}

export const FormBoleto = React.forwardRef<
    BoletoFormRef,
    BoletoFormProps
>(({ preencherConfigAuto = false, ...props }: BoletoFormProps, refs) => {
    const utilClasses = makeUtilClasses();
    const { FormBoletoValidationYup, accordionMockValidation } = useFormBoletoValidation();
    const { consultarCNPJ, carregando: carregandoCNPJ } = useConsultaCnpj();
    const { consultaCEP, carregando: carregandoCEP } = useConsultaCEP();
    const { getContaBancariaConfiguracao, carregando: carregandoConfig } = useGetContaBancariaConfiguracao();
    const { showToast } = useToastSaurus();
    const { showAviso } = useShowAviso();
    const { showConfirm } = useConfirmSaurus();
    const classes = useStyles();

    const txtNroPagador = useRef<HTMLInputElement>();

    const [loadingConfig, setLoadingConfig] = useState<boolean>(false);

    const loading = props.loading || carregandoCEP || carregandoCNPJ || carregandoConfig || loadingConfig

    const [accordion, setAccordion] = useState({
        beneficiario: true,
        boleto: false,
        beneficiarioFinal: false,
        pagador: false
    })
    const [dialogContas, setDialogContas] = useState<boolean>(false)
    const [, setRender] = useState<boolean>(false)

    const mostrarMensagemImport = useRef<boolean>(preencherConfigAuto ? false : true);

    // const [percJurosMulta, setPercJurosMulta] = useState({
    //     jurosMora: '',
    //     multa: ''
    // })

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue,
        getValues,
        trigger
    } = useForm<BoletoFormModel>({
        defaultValues: { ...new BoletoFormModel() },
        criteriaMode: 'all',
        mode: 'onChange',
        resolver: yupResolver(FormBoletoValidationYup)
    });

    const onSubmit = (form: BoletoFormModel) => {
        form.tipoDocumentoEmpresa = form.documentoEmpresa.length === 11 ? EnumTpDocumento.CPF : EnumTpDocumento.CNPJ;
        form.tipoDocumentoPagador = form.documentoPagador.length === 11 ? EnumTpDocumento.CPF : EnumTpDocumento.CNPJ;
        // if (form.documentoSacadorAvalista) {
        //     form.tipoDocumentoSacadorAvalista = form.documentoSacadorAvalista.length === 11 ? EnumTpDocumento.CPF : EnumTpDocumento.CNPJ;
        // }
        // const model = picker<BoletoFormModel>(form, new BoletoFormModel());
        form.valorDesconto1 = toDecimal(form.valorDesconto1)
        form.valorJurosMoraDia = toDecimal(form.valorJurosMoraDia)
        form.valorMulta = toDecimal(form.valorMulta)
        form.valorTitulo = toDecimal(form.valorTitulo)
        form.tipoDesconto1 = Number(form.tipoDesconto1) as unknown as EnumTpDesconto;
        form.tipoDesconto2 = Number(form.tipoDesconto2) as unknown as EnumTpDesconto;
        form.tipoDesconto3 = Number(form.tipoDesconto3) as unknown as EnumTpDesconto;
        form.valorDesconto2 = form.valorDesconto2 || 0;
        form.valorDesconto3 = form.valorDesconto3 || 0;
        form.cepPagador = stringNumeros(form.cepPagador);

        form.dataJurosMora = !isEmpty(form.dataJurosMora) ? form.dataJurosMora : null

        const percMulta = form.percMulta
        const percJuros = form.percJurosMoraDia

        if (percMulta > limitesCodigoDefesaConsumidor.multa || percJuros > limitesCodigoDefesaConsumidor.juros) {
            const avisos = [];
            if (percMulta > limitesCodigoDefesaConsumidor.multa) {
                avisos.push(`Multa Atual: ${percMulta}% / Limite Estabelecido: ${limitesCodigoDefesaConsumidor.multa}%`)
            }
            if (percJuros > limitesCodigoDefesaConsumidor.juros) {
                avisos.push(`Juros Atual: ${percJuros}% / Limite Estabelecido: ${limitesCodigoDefesaConsumidor.juros}%`)
            }
            showConfirm({
                title: 'Atenção',
                description: <>
                    O código de defesa do consumidor estabelece alguns limites para percentuais nos boletos.<br /><br />
                    {avisos.map(msg => <>{msg}<br /></>)}
                    <br />Tem certeza que deseja prosseguir?
                </>,
                primaryButtonText: 'Voltar e Reajustar',
                secondaryButtonText: 'Ignorar e Salvar'
            }).catch(() => props.onSubmit(form))
            return
        }

        props.onSubmit(form);
    };

    React.useImperativeHandle(refs, () => ({
        submitForm: () => {
            handleSubmit(onSubmit, (errors) => {
                const arr = Object.keys(errors);

                setAccordion(() => {
                    const accordions = {
                        beneficiario: false,
                        boleto: false,
                        beneficiarioFinal: false,
                        pagador: false,
                    }
                    arr.forEach(item => {
                        const accordion = accordionMockValidation.reduce<string>((prev, curr) => {
                            if (!isEmpty(prev)) return prev
                            if (curr.fields.includes(item)) {
                                return curr.accordion;
                            }
                            return prev
                        }, '') as 'beneficiario' | 'boleto' | 'beneficiarioFinal' | 'pagador'

                        if (!isEmpty(accordion) && !accordions[accordion]) {
                            accordions[accordion] = true
                        }

                    })

                    return accordions
                })
            })();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: BoletoFormModel) => {
            if (!isEqual(model, new BoletoFormModel())) {
                model.dataDesconto1 = !isEmpty(model.dataDesconto1) ? (toDateString(model.dataDesconto1, 'YYYY-MM-DD') || '') : ''
                model.dataVencimento = !isEmpty(model.dataVencimento) ? (toDateString(model.dataVencimento, 'YYYY-MM-DD') || '') : ''
                model.dataJurosMora = !isEmpty(model.dataJurosMora) ? (toDateString(model.dataJurosMora || '', 'YYYY-MM-DD') || '') : ''
                model.dataEmissao = !isEmpty(model.dataEmissao) ? (toDateString(model.dataEmissao, 'YYYY-MM-DD') || '') : ''
                model.tipoDesconto1 = model.tipoDesconto1 as EnumTpDesconto;
                model.tipoDesconto2 = model.tipoDesconto2 as EnumTpDesconto;
                model.tipoDesconto3 = model.tipoDesconto3 as EnumTpDesconto;

                const multa = toDecimalString((toDecimal(model.valorMulta) / toDecimal(model.valorTitulo)) * 100);
                const jurosMora = toDecimalString((toDecimal(model.valorJurosMoraDia) / toDecimal(model.valorTitulo)) * 100);


                setValue('percMulta', toDecimal(multa));
                setValue('percJurosMoraDia', toDecimal(jurosMora))
                // setPercJurosMulta({
                //     jurosMora,
                //     multa
                // })
            } else {
                model.dataEmissao = toDateString(new Date(), 'YYYY-MM-DD') || '';
            }
            reset({ ...model });
        },
        carregarDadosContaBancaria: (model: ContaBancariaConfiguracaoModel) => {
            //
        }
    }));

    // const calcValores = useCallback((value: any, name: 'descontos' | 'outrasDeducoes' | 'multa' | 'acrescimos' | 'valorDocumento') => {
    //     setValue(name, value)
    //     const valorDocumento = toDecimal(getValues('valorDocumento'));

    //     const descontos = toDecimal(getValues('descontos'));
    //     const outrasDeducoes = toDecimal(getValues('outrasDeducoes'))

    //     const multa = toDecimal(getValues('multa'))
    //     const acrescimos = toDecimal(getValues('acrescimos'))

    //     const valorCobrado = ((multa + acrescimos) + valorDocumento) - (descontos + outrasDeducoes)
    //     setValue('valorCobrado', valorCobrado)
    // }, [getValues, setValue])

    const onSelectConta = useCallback(async (model: ContaBancariaListModel, showError: boolean = true) => {
        try {
            const res = await getContaBancariaConfiguracao(model.id);
            setLoadingConfig(false);
            if (res.erro) throw res.erro
            if (isEmpty(res.resultado?.data.list)) {
                throw new Error(showError ? 'Não foram encontradas configurações nesta conta.' : '');
            }

            const configModel = res.resultado!.data.list[0]

            setValue('contaBancariaId', model.id)
            setValue('configContaId', configModel.id)
            setValue('agencia', model.agencia.toString())
            setValue('banco', model.banco)
            setValue('nomeBanco', model.nomeBanco)
            setValue('conta', `${model.conta}${model.digitoConta}`);
            setValue('nomeEmpresa', model.nomeTitular)
            setValue('documentoEmpresa', model.documentoTitular)
            if (model.documentoTitular.length === 11) {
                setValue('tipoDocumentoEmpresa', EnumTpDocumento.CPF)
            } else {
                setValue('tipoDocumentoEmpresa', EnumTpDocumento.CNPJ)
            }
            // setValue('digito', model.digitoConta)
            setValue('carteira', configModel.carteira)
            setValue('valorDesconto1', configModel.percDesconto1)
            setValue('mensagemFichaCompensacao', configModel.mensagemFichaCompensacao)
            setValue('mensagemRecibo', configModel.mensagemRecibo)
            setValue('percMulta', configModel.percMulta);
            setValue('percJurosMoraDia', configModel.percJuros)

            // setPercJurosMulta({
            //     jurosMora: toDecimalString(configModel.percMulta),
            //     multa: toDecimalString(configModel.percJuros)
            // })

            setDialogContas(false)
            setRender(prev => !prev)

            if (mostrarMensagemImport.current) {
                showToast('success', 'Informações importadas com sucesso.')
            }

            mostrarMensagemImport.current = true;

            if (!isEmpty(errors)) {
                trigger();
            }
        } catch (e: any) {
            if (e.message)
                showAviso('error', e.message)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getContaBancariaConfiguracao, getValues, setValue, showAviso, showToast, trigger])

    return (
        <>
            {(props.loading && props.showLoading) || loading ? (
                <CircularLoading tipo="FULLSIZED" />
            ) : null}
            <div className={utilClasses.formContainer}>
                <form
                    onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AccordionSaurus
                                labelPrimary='Dados do Beneficiário'
                                expanded={accordion.beneficiario}
                                onChange={() => setAccordion(prev => ({
                                    ...prev,
                                    beneficiario: !prev.beneficiario
                                }))}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            fullWidth
                                            size='small'
                                            onClick={() => setDialogContas(true)}
                                        >
                                            Importar Dados a partir de Conta Cadastrada
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name="documentoEmpresa"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo={'CNPJ_CPF'}
                                                    fullWidth
                                                    searchable
                                                    onSearch={async (value) => {
                                                        try {
                                                            if (value.length > 11) {
                                                                let res = await consultarCNPJ(value)

                                                                setValue('nomeEmpresa', res.razaoSocial)
                                                            } else {
                                                                showAviso('info', 'Busca disponível apenas para CNPJ');
                                                                return
                                                            }
                                                        }
                                                        catch (erro: any) {
                                                            return showAviso('error', erro.message)
                                                        }
                                                    }}
                                                    variant="outlined"
                                                    label="CNPJ/CPF"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    autoComplete="off"
                                                    error={Boolean(
                                                        errors.documentoEmpresa && errors.documentoEmpresa.message
                                                    )}
                                                    helperText={
                                                        errors?.documentoEmpresa
                                                            ? errors?.documentoEmpresa?.message
                                                            : undefined
                                                    }
                                                    disabled={loading}
                                                    {...field}
                                                    onChange={(e) => setValue('documentoEmpresa', stringNumeros(e.target.value))}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            control={control}
                                            name='nomeEmpresa'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Nome da Empresa'
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.nomeEmpresa)}
                                                    helperText={!isEmpty(errors.nomeEmpresa) && !isEmpty(errors.nomeEmpresa.message) ?
                                                        errors.nomeEmpresa.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            control={control}
                                            name='banco'
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    label='Banco'
                                                    conteudo={BancoMock}
                                                    fullWidth
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.banco)}
                                                    helperText={!isEmpty(errors.banco) && !isEmpty(errors.banco.message) ?
                                                        errors.banco.message : undefined}
                                                    onChange={(e: any) => {
                                                        setValue('banco', e.target.value)
                                                        const nomeBanco = BancoMock.find(x => x.Key === e.target.value)?.Value;
                                                        setValue('nomeBanco', nomeBanco || '')
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            control={control}
                                            name='agencia'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Agência/Código Cedente'
                                                    fullWidth
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.agencia)}
                                                    helperText={!isEmpty(errors.agencia) && !isEmpty(errors.agencia.message) ?
                                                        errors.agencia.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            control={control}
                                            name='conta'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Conta'
                                                    fullWidth
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.conta)}
                                                    helperText={!isEmpty(errors.conta) && !isEmpty(errors.conta.message) ?
                                                        errors.conta.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            control={control}
                                            name='carteira'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Carteira'
                                                    fullWidth
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.carteira)}
                                                    helperText={!isEmpty(errors.carteira) && !isEmpty(errors.carteira.message) ?
                                                        errors.carteira.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionSaurus>
                        </Grid>
                        <Grid item xs={12}>
                            <AccordionSaurus
                                labelPrimary='Dados do Boleto'
                                expanded={accordion.boleto}
                                onChange={() => setAccordion(prev => ({
                                    ...prev,
                                    boleto: !prev.boleto
                                }))}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={getValues('seuNumero') ? 2 : 4}>
                                        <Controller
                                            control={control}
                                            name='nossoNumero'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Nosso Número'
                                                    tipo={'TEXTO'}
                                                    fullWidth
                                                    disabled
                                                    {...field}
                                                    error={!isEmpty(errors.nossoNumero)}
                                                    helperText={!isEmpty(errors.nossoNumero) && !isEmpty(errors.nossoNumero.message) ?
                                                        errors.nossoNumero.message : undefined}
                                                    value={(() => {
                                                        return !field.value ? 'A GERAR' : field.value
                                                    })()}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {getValues('seuNumero') && <Grid item xs={12} md={2}>
                                        <Controller
                                            control={control}
                                            name='seuNumero'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Seu Número'
                                                    tipo={'TEXTO'}
                                                    fullWidth
                                                    disabled
                                                    {...field}
                                                    error={!isEmpty(errors.seuNumero)}
                                                    helperText={!isEmpty(errors.seuNumero) && !isEmpty(errors.seuNumero.message) ?
                                                        errors.seuNumero.message : undefined}
                                                    value={(() => {
                                                        return !field.value ? 'A GERAR' : field.value
                                                    })()}
                                                />
                                            )}
                                        />
                                    </Grid>}
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            control={control}
                                            name='especie'
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    label='Espécie'
                                                    conteudo={TpEspecieMock}
                                                    fullWidth
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.especie)}
                                                    helperText={!isEmpty(errors.especie) && !isEmpty(errors.especie.message) ?
                                                        errors.especie.message : undefined}
                                                    onChange={(e: any) => {
                                                        setValue('especie', e.target.value as EnumTpEspecie)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            control={control}
                                            name='codigoAceite'
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    conteudo={TpAceiteMock}
                                                    fullWidth
                                                    variant="outlined"
                                                    label={'Código Aceite'}
                                                    allowSubmit
                                                    select
                                                    disabled={loading}
                                                    {...field}
                                                    onChange={(event) => {
                                                        setValue('codigoAceite', Number(event.target.value) as EnumTpAceite);
                                                    }}
                                                    error={!isEmpty(errors.codigoAceite)}
                                                    helperText={!isEmpty(errors.codigoAceite) && !isEmpty(errors.codigoAceite.message) ?
                                                        errors.codigoAceite.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            control={control}
                                            name='dataEmissao'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label={<label className={classes.labelNegrito}>Data de Emissão</label>}
                                                    tipo='DATA'
                                                    fullWidth
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.dataEmissao)}
                                                    helperText={!isEmpty(errors.dataEmissao) && !isEmpty(errors.dataEmissao.message) ?
                                                        errors.dataEmissao.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            control={control}
                                            name='dataVencimento'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label={<label className={classes.labelNegrito}>Data de Vencimento</label>}
                                                    tipo='DATA'
                                                    fullWidth
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.dataVencimento)}
                                                    helperText={!isEmpty(errors.dataVencimento) && !isEmpty(errors.dataVencimento.message) ?
                                                        errors.dataVencimento.message : undefined}
                                                    onBlur={(e) => {
                                                        if (e.target.value.length === 10) {
                                                            const dtJurosMora = getValues('dataJurosMora');
                                                            const dtDesconto = getValues('dataDesconto1');
                                                            const valorMulta = getValues('valorMulta');
                                                            const valorJuros = getValues('valorJurosMoraDia');
                                                            if (!dtJurosMora && (valorJuros || valorMulta)) {
                                                                setValue('dataJurosMora', e.target.value)
                                                            }
                                                            if (!dtDesconto && getValues('tipoDesconto1') !== EnumTpDesconto.SemDesconto) {
                                                                setValue('dataDesconto1', e.target.value)
                                                            }
                                                            setRender(prev => !prev);
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            control={control}
                                            name='valorTitulo'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label={<label className={classes.labelNegrito}>Valor</label>}
                                                    tipo='DECIMAL'
                                                    showStartAdornment
                                                    manterMascara
                                                    fullWidth
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.valorTitulo)}
                                                    helperText={!isEmpty(errors.valorTitulo) && !isEmpty(errors.valorTitulo.message) ?
                                                        errors.valorTitulo.message : undefined}
                                                    onChange={(e) => {
                                                        const percMulta = getValues('percMulta')
                                                        const percJuros = getValues('percJurosMoraDia')

                                                        const valorMulta = toDecimal(toDecimal(e.target.value) * (toDecimal(percMulta) / 100));
                                                        const valorJuros = toDecimal(toDecimal(e.target.value) * (toDecimal(percJuros) / 100));

                                                        setValue('valorJurosMoraDia', valorJuros)
                                                        setValue('valorMulta', valorMulta)

                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            control={control}
                                            name='dataJurosMora'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Data de Juros de Mora'
                                                    tipo='DATA'
                                                    fullWidth
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.dataJurosMora)}
                                                    helperText={!isEmpty(errors.dataJurosMora) && !isEmpty(errors.dataJurosMora.message) ?
                                                        errors.dataJurosMora.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Controller
                                            control={control}
                                            name='percMulta'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Multa (%)'
                                                    tipo='DECIMAL'
                                                    manterMascara
                                                    fullWidth
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.percMulta)}
                                                    helperText={!isEmpty(errors.percMulta) && !isEmpty(errors.percMulta.message) ?
                                                        errors.percMulta.message : undefined}
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            const valor = getValues('valorTitulo');

                                                            const valorMulta = toDecimal(toDecimal(valor) * (toDecimal(e.target.value) / 100));

                                                            setValue('valorMulta', valorMulta)
                                                        } else {
                                                            setValue('valorMulta', 0)
                                                        }

                                                        field.onChange(e);
                                                        setRender(prev => !prev)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Controller
                                            control={control}
                                            name='valorMulta'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Multa (R$)'
                                                    tipo='DECIMAL'
                                                    manterMascara
                                                    showStartAdornment
                                                    readOnly
                                                    fullWidth
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.valorMulta)}
                                                    helperText={!isEmpty(errors.valorMulta) && !isEmpty(errors.valorMulta.message) ?
                                                        errors.valorMulta.message : undefined}
                                                    onChange={(e) => {
                                                        let multa = 0;
                                                        if (e.target.value) {
                                                            const valor = toDecimal(getValues('valorTitulo'));
                                                            multa = toDecimal((toDecimal(e.target.value) / valor) * 100);
                                                        }
                                                        setValue('percMulta', multa)
                                                        // setPercJurosMulta(prev => ({
                                                        //     ...prev,
                                                        //     multa
                                                        // }))
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Controller
                                            control={control}
                                            name='percJurosMoraDia'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Juros de Mora (%)'
                                                    tipo='DECIMAL'
                                                    manterMascara
                                                    fullWidth
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.percJurosMoraDia)}
                                                    helperText={!isEmpty(errors.percJurosMoraDia) && !isEmpty(errors.percJurosMoraDia.message) ?
                                                        errors.percJurosMoraDia.message : undefined}
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            const valor = getValues('valorTitulo');

                                                            const valorJuros = toDecimal(toDecimal(valor) * (toDecimal(e.target.value) / 100));

                                                            setValue('valorJurosMoraDia', valorJuros)
                                                        } else {
                                                            setValue('valorJurosMoraDia', 0)
                                                        }

                                                        field.onChange(e);
                                                        trigger('dataJurosMora');
                                                        setRender(prev => !prev)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Controller
                                            control={control}
                                            name='valorJurosMoraDia'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Juros de Mora (R$)'
                                                    tipo='DECIMAL'
                                                    manterMascara
                                                    showStartAdornment
                                                    fullWidth
                                                    disabled={loading}
                                                    readOnly
                                                    {...field}
                                                    error={!isEmpty(errors.valorJurosMoraDia)}
                                                    helperText={!isEmpty(errors.valorJurosMoraDia) && !isEmpty(errors.valorJurosMoraDia.message) ?
                                                        errors.valorJurosMoraDia.message : undefined}
                                                    onChange={(e) => {
                                                        let juros = 0;
                                                        if (e.target.value) {
                                                            const valor = toDecimal(getValues('valorTitulo'));
                                                            juros = toDecimal((toDecimal(e.target.value) / valor) * 100);
                                                        }

                                                        setValue('percJurosMoraDia', juros)
                                                        // setPercJurosMulta(prev => ({
                                                        //     ...prev,
                                                        //     juros
                                                        // }))
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            control={control}
                                            name='tipoDesconto1'
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    conteudo={TpDescontoMock}
                                                    fullWidth
                                                    variant="outlined"
                                                    label={'Tipo de Desconto'}
                                                    allowSubmit
                                                    select
                                                    disabled={loading}
                                                    {...field}
                                                    onChange={(event) => {
                                                        setValue('tipoDesconto1', Number(event.target.value) as EnumTpDesconto);
                                                        if (Number(event.target.value) === EnumTpDesconto.SemDesconto) {
                                                            setValue('valorDesconto1', 0)
                                                            setValue('dataDesconto1', '')
                                                        } else {
                                                            const dtVencimento = getValues('dataVencimento')
                                                            const dtDesconto = getValues('dataDesconto1')
                                                            if (dtVencimento && !dtDesconto) {
                                                                setValue('dataDesconto1', dtVencimento)
                                                            }
                                                        }
                                                        setRender(prev => !prev)
                                                    }}
                                                    error={!isEmpty(errors.tipoDesconto1)}
                                                    helperText={!isEmpty(errors.tipoDesconto1) && !isEmpty(errors.tipoDesconto1.message) ?
                                                        errors.tipoDesconto1.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            control={control}
                                            name='dataDesconto1'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Data de Desconto'
                                                    tipo='DATA'
                                                    fullWidth
                                                    disabled={getValues('tipoDesconto1') === EnumTpDesconto.SemDesconto || loading}
                                                    {...field}
                                                    error={!isEmpty(errors.dataDesconto1)}
                                                    helperText={!isEmpty(errors.dataDesconto1) && !isEmpty(errors.dataDesconto1.message) ?
                                                        errors.dataDesconto1.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            control={control}
                                            name='valorDesconto1'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Valor de Desconto'
                                                    tipo='DECIMAL'
                                                    disabled={getValues('tipoDesconto1') === EnumTpDesconto.SemDesconto || loading}
                                                    manterMascara
                                                    showStartAdornment={getValues('tipoDesconto1') === EnumTpDesconto.DescontoValor || getValues('tipoDesconto1') === EnumTpDesconto.DescontoValorAntecipacaoDiaCorrido}
                                                    fullWidth
                                                    {...field}
                                                    error={!isEmpty(errors.valorDesconto1)}
                                                    helperText={!isEmpty(errors.valorDesconto1) && !isEmpty(errors.valorDesconto1.message) ?
                                                        errors.valorDesconto1.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name='mensagemRecibo'
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Mensagem Recibo'
                                                    placeholder='Digite aqui a mensagem que será exibida no recibo do pagamento'
                                                    rows={4}
                                                    multiline
                                                    disabled={loading}
                                                    {...field}
                                                    error={Boolean(errors.mensagemRecibo)}
                                                    helperText={Boolean(errors.mensagemRecibo && errors.mensagemRecibo.message) ?
                                                        errors.mensagemRecibo?.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name='mensagemFichaCompensacao'
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Mensagem da Ficha de Compensação'
                                                    placeholder='Digite aqui a mensagem que será exibida na ficha de compensação'
                                                    rows={4}
                                                    multiline
                                                    disabled={loading}
                                                    {...field}
                                                    error={Boolean(errors.mensagemFichaCompensacao)}
                                                    helperText={Boolean(errors.mensagemFichaCompensacao && errors.mensagemFichaCompensacao.message) ?
                                                        errors.mensagemFichaCompensacao?.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionSaurus>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <AccordionSaurus
                                labelPrimary='Dados do Beneficiário Final'
                                expanded={accordion.beneficiarioFinal}
                                onChange={() => setAccordion(prev => ({
                                    ...prev,
                                    beneficiarioFinal: !prev.beneficiarioFinal
                                }))}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="documentoSacadorAvalista"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo={'CNPJ_CPF'}
                                                    fullWidth
                                                    variant="outlined"
                                                    label="CNPJ/CPF"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    autoComplete="off"
                                                    error={Boolean(
                                                        errors.documentoSacadorAvalista && errors.documentoSacadorAvalista.message
                                                    )}
                                                    helperText={
                                                        errors?.documentoSacadorAvalista
                                                            ? errors?.documentoSacadorAvalista?.message
                                                            : undefined
                                                    }
                                                    disabled={loading}
                                                    {...field}
                                                    onChange={(e) => setValue('documentoSacadorAvalista', stringNumeros(e.target.value))}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name="cepSacadorAvalista"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo="CEP"
                                                    disabled={props.loading}
                                                    fullWidth
                                                    searchable
                                                    autoComplete={'off'}
                                                    label="CEP"
                                                    placeholder=""
                                                    onSearch={async (value: string) => {
                                                        if (isEmpty(value)) return
                                                        try {
                                                            let res = await consultaCEP(value);
                                                            setValue('cepSacadorAvalista', res.CEP);
                                                            setValue('bairroSacadorAvalista', res.Bairro);
                                                            setValue('enderecoSacadorAvalista', res.Logradouro);
                                                            setValue('ufSacadorAvalista', res.UF);
                                                            setValue('cidadeSacadorAvalista', res.Municipio);
                                                            txtNroBeneficiarioFinal.current?.focus();
                                                        } catch (e: any) {
                                                            showAviso('error', e.message);
                                                        }
                                                    }}
                                                    error={Boolean(errors.cepSacadorAvalista)}
                                                    helperText={
                                                        errors.cepSacadorAvalista?.message || undefined
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            control={control}
                                            name='bairroSacadorAvalista'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Bairro'
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.bairroSacadorAvalista)}
                                                    helperText={!isEmpty(errors.bairroSacadorAvalista) && !isEmpty(errors.bairroSacadorAvalista.message) ?
                                                        errors.bairroSacadorAvalista.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            control={control}
                                            name='enderecoSacadorAvalista'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Endereço do Beneficiario Final'
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.enderecoSacadorAvalista)}
                                                    helperText={!isEmpty(errors.enderecoSacadorAvalista) && !isEmpty(errors.enderecoSacadorAvalista.message) ?
                                                        errors.enderecoSacadorAvalista.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            control={control}
                                            name='numeroSacadorAvalista'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Número'
                                                    inputRef={txtNroBeneficiarioFinal}
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.numeroSacadorAvalista)}
                                                    helperText={!isEmpty(errors.numeroSacadorAvalista) && !isEmpty(errors.numeroSacadorAvalista.message) ?
                                                        errors.numeroSacadorAvalista.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            control={control}
                                            name='complementoSacadorAvalista'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Complemento'
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.complementoSacadorAvalista)}
                                                    helperText={!isEmpty(errors.complementoSacadorAvalista) && !isEmpty(errors.complementoSacadorAvalista.message) ?
                                                        errors.complementoSacadorAvalista.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            name="ufSacadorAvalista"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    conteudo={UFMock}
                                                    fullWidth
                                                    variant="outlined"
                                                    label={'UF'}
                                                    allowSubmit
                                                    select
                                                    disabled={loading}
                                                    {...field}
                                                    value={UFMock.find(uf => uf.Value === getValues('ufSacadorAvalista'))?.Key}
                                                    onChange={(event) => {
                                                        if (event) {
                                                            const item = UFMock.filter(
                                                                (item) => item.Key === event.target.value,
                                                            )[0];
                                                            if (item) {
                                                                setValue('ufSacadorAvalista', item.Value);
                                                            }
                                                        }
                                                    }}
                                                    error={!isEmpty(errors.ufSacadorAvalista)}
                                                    helperText={!isEmpty(errors.ufSacadorAvalista) && !isEmpty(errors.ufSacadorAvalista.message) ?
                                                        errors.ufSacadorAvalista.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Controller
                                            control={control}
                                            name='cidadeSacadorAvalista'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Cidade do Beneficiário Final'
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.cidadeSacadorAvalista)}
                                                    helperText={!isEmpty(errors.cidadeSacadorAvalista) && !isEmpty(errors.cidadeSacadorAvalista.message) ?
                                                        errors.cidadeSacadorAvalista.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionSaurus>
                        </Grid> */}
                        <Grid item xs={12}>
                            <AccordionSaurus
                                labelPrimary='Dados do Pagador'
                                expanded={accordion.pagador}
                                onChange={() => setAccordion(prev => ({
                                    ...prev,
                                    pagador: !prev.pagador
                                }))}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name="documentoPagador"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo='CNPJ_CPF'
                                                    fullWidth
                                                    searchable
                                                    onSearch={async (value) => {
                                                        try {
                                                            if (value.length > 11) {
                                                                let res = await consultarCNPJ(value)

                                                                setValue('nomePagador', res.razaoSocial)
                                                                trigger();
                                                            } else {
                                                                showAviso('info', 'Busca disponível apenas para CNPJ');
                                                                return
                                                            }
                                                        }
                                                        catch (erro: any) {
                                                            return showAviso('error', erro.message)
                                                        }
                                                    }}
                                                    variant="outlined"
                                                    label="CNPJ/CPF"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    autoComplete="off"
                                                    error={Boolean(
                                                        errors.documentoPagador && errors.documentoPagador.message
                                                    )}
                                                    helperText={
                                                        errors?.documentoPagador
                                                            ? errors?.documentoPagador?.message
                                                            : undefined
                                                    }
                                                    disabled={loading}
                                                    {...field}
                                                    onChange={(e) => setValue('documentoPagador', stringNumeros(e.target.value))}

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            control={control}
                                            name='nomePagador'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Nome do Pagador'
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.nomePagador)}
                                                    helperText={!isEmpty(errors.nomePagador) && !isEmpty(errors.nomePagador.message) ?
                                                        errors.nomePagador.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name="cepPagador"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo="CEP"
                                                    fullWidth
                                                    searchable
                                                    autoComplete={'off'}
                                                    label="CEP"
                                                    placeholder=""
                                                    onSearch={async (value: string) => {
                                                        if (isEmpty(value)) return
                                                        try {
                                                            let res = await consultaCEP(value);
                                                            setValue('cepPagador', res.CEP);
                                                            setValue('bairroPagador', res.Bairro);
                                                            setValue('enderecoPagador', res.Logradouro);
                                                            setValue('ufPagador', res.UF);
                                                            setValue('cidadePagador', res.Municipio);
                                                            trigger();
                                                            txtNroPagador.current?.focus();
                                                        } catch (e: any) {
                                                            showAviso('error', e.message);
                                                        }
                                                    }}
                                                    error={Boolean(errors.cepPagador)}
                                                    helperText={
                                                        errors.cepPagador?.message || undefined
                                                    }
                                                    disabled={loading}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            control={control}
                                            name='bairroPagador'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Bairro'
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.bairroPagador)}
                                                    helperText={!isEmpty(errors.bairroPagador) && !isEmpty(errors.bairroPagador.message) ?
                                                        errors.bairroPagador.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            control={control}
                                            name='enderecoPagador'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Endereço do Pagador'
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.enderecoPagador)}
                                                    helperText={!isEmpty(errors.enderecoPagador) && !isEmpty(errors.enderecoPagador.message) ?
                                                        errors.enderecoPagador.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            control={control}
                                            name='numeroPagador'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Número'
                                                    inputRef={txtNroPagador}
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.numeroPagador)}
                                                    helperText={!isEmpty(errors.numeroPagador) && !isEmpty(errors.numeroPagador.message) ?
                                                        errors.numeroPagador.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            control={control}
                                            name='complementoPagador'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Complemento'
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.complementoPagador)}
                                                    helperText={!isEmpty(errors.complementoPagador) && !isEmpty(errors.complementoPagador.message) ?
                                                        errors.complementoPagador.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controller
                                            name="ufPagador"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    conteudo={UFMock}
                                                    fullWidth
                                                    variant="outlined"
                                                    label={'UF'}
                                                    allowSubmit
                                                    select
                                                    disabled={loading}
                                                    {...field}
                                                    value={UFMock.find(uf => uf.Value === getValues('ufPagador'))?.Key}
                                                    onChange={(event) => {
                                                        if (event) {
                                                            const item = UFMock.filter(
                                                                (item) => item.Key === event.target.value,
                                                            )[0];
                                                            if (item) {
                                                                setValue('ufPagador', item.Value);
                                                            }
                                                        }
                                                    }}
                                                    error={!isEmpty(errors.ufPagador)}
                                                    helperText={!isEmpty(errors.ufPagador) && !isEmpty(errors.ufPagador.message) ?
                                                        errors.ufPagador.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Controller
                                            control={control}
                                            name='cidadePagador'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Cidade do Pagador'
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.cidadePagador)}
                                                    helperText={!isEmpty(errors.cidadePagador) && !isEmpty(errors.cidadePagador.message) ?
                                                        errors.cidadePagador.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            control={control}
                                            name='emailPagador'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='E-mail do Pagador'
                                                    tipo='EMAIL'
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.emailPagador)}
                                                    helperText={!isEmpty(errors.emailPagador) && !isEmpty(errors.emailPagador.message) ?
                                                        errors.emailPagador.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            control={control}
                                            name='numeroCelularPagador'
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='Telefone do Pagador'
                                                    tipo='TELEFONE'
                                                    disabled={loading}
                                                    {...field}
                                                    error={!isEmpty(errors.numeroCelularPagador)}
                                                    helperText={!isEmpty(errors.numeroCelularPagador) && !isEmpty(errors.numeroCelularPagador.message) ?
                                                        errors.numeroCelularPagador.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionSaurus>
                        </Grid>
                    </Grid>
                    <DialogContaBancariaList
                        aberto={dialogContas}
                        handleClose={() => setDialogContas(false)}
                        onClick={onSelectConta}
                        preencherAutomaticamente={preencherConfigAuto}
                        setLoading={setLoadingConfig}
                    />
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
});