import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    listBottomCard: {
        boxShadow: '0px 1px 2px 0px #00000040',
        width: '100%',
        display: 'flex',
        alignItems: 'stretch',
        gap: theme.spacing(4),
        background: theme.palette.background.default,
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            gap: 0
        }
    },
    items: {
        display: 'flex',
        alignItems: 'stretch',
        gridGap: theme.spacing(10),
        flexGrow: 1,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            gap: 0,
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            height: 80,
            flexBasis: '50%'
        }
    },
    tagStatus: {
        width: "5px",
        borderRadius: "5px",
        margin: 7,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.warning.light
    },
    itemInfo: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '50%',
            margin: '4px 0'
        }
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center'
    },
    textValor: {
        fontWeight: 500,
        color: theme.palette.text.primary
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        }
    }
}));