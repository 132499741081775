import { useCallback } from 'react';
import * as Yup from 'yup';

export const useValidationYupRazaoSocial = () => {
    const razaoSocialYup = useCallback((validationText?:string)=>{
        return(
            Yup.string().required(validationText?validationText:'A razão social é obrigatória.')
        )
    }, [])
   
    return{
        razaoSocialYup
    }
}
