import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/use-cases/events/event-tools";
import { AppEventEnum } from "model/enums/enum-event-app";
import { DialogHistoricoBoleto } from "views/components/dialog/dialog-historico-boleto/dialog-historico-boleto";

class HistoricoBoletoDialogProps {
    constructor(
        public aberto: boolean = false,
        public id: string = '',
    ) { }
}


export const HistoricoBoletoDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<HistoricoBoletoDialogProps>(
        new HistoricoBoletoDialogProps()
    );

    const modalAlterado = useCallback(({ aberto, id }: HistoricoBoletoDialogProps) => {
        setModalState({
            aberto,
            id
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogHistoricoBoleto, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogHistoricoBoleto, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogHistoricoBoleto aberto={modalState.aberto} id={modalState.id} />
            )}
        </>
    )
}