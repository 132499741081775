import { useCadastros } from "services/app/hooks/cadastros";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { Box } from "@material-ui/core";
import { useDefaultPageStyles } from "views/theme/pages-styles";
import { useCallback, useEffect, useState } from "react";
import { BoletoHistoricoModel } from "model/api/gestao/boleto/boleto-historico-model";
import { CardHistoricoBoleto } from "views/components/cards/card-historico-boleto/card-historico-boleto";
import { useShowAviso } from "services/app/hooks/show-aviso";

interface DialogHistoricoBoletoProps {
    aberto: boolean;
    id: string;
}

export const DialogHistoricoBoleto = ({
    aberto,
    id
}: DialogHistoricoBoletoProps) => {

    const { fecharDialogHistoricoBoleto } = useCadastros();
    const { showAviso } = useShowAviso();
    const classes = useDefaultPageStyles();

    const [list, setList] = useState<BoletoHistoricoModel[]>([])

    const getHistoricoBoleto = useCallback(async () => {
        try{
            setList([
                // new BoletoHistoricoModel('0', '13/10/2023', 'Testando histórico do boleto', 456, 'boleto.xml')
            ])
        }catch(e: any){
            showAviso('error', e.message)
        }
    }, [showAviso])

    useEffect(() => {
        getHistoricoBoleto()
    }, [getHistoricoBoleto])

    return <DialogSaurus
        tamanho='lg'
        isButtonTitleClose
        titulo="Histórico do Boleto"
        aberto={aberto}
        handleClickClose={fecharDialogHistoricoBoleto}
    >
        <Box className={classes.list}>
            {list.map(historico => (
                <CardHistoricoBoleto
                    model={historico}
                />
            ))}
        </Box>
    </DialogSaurus>
}