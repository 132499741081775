import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetEmpresaValidarExistente() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getEmpresaValidarExitente = useCallback(
        (documento: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/empresas/${documento}/validar-cadastro`,
                method: 'GET',
                enviarTokenUsuario: false
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getEmpresaValidarExitente,
    };
}
