import { makeUseAxios, Options } from "axios-hooks";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { isEmpty, merge } from "lodash";
import LRU from "lru-cache";
import { VariaveisAmbiente } from "../../../config";
import {
  GestaoStorageKeys,
  useGestaoStorage,
} from "../../../services/app/use-cases/gestao-storage";
import { ApiBaseProps } from "./api-base-props";
import { ApiBaseResponse, EnumRetornoApiBase } from './api-base-response';
import { RetornoApiModel } from "../../../model";
import { useGestaoToken, useSessaoAtual } from "services/app";
import { useHistory } from "react-router-dom";

type RequestParams = AxiosRequestConfig & {
  enviarTokenUsuario: boolean;
};

// type Validacoes = {
//   propriedade: string
//   mensagem: string
// }

// type ErrorSaurus = {
//   type: string
//   title: string
//   status: number
//   detail: string
//   instance: string
//   validacoes: Validacoes[]
//   urlRedirect: string
//   options: string
// }

class ExpiredError extends Error {
  public response: Object;
  constructor(message: string, statusCode: number) {
    super(message)
    this.response = {
      status: statusCode
    }
  }
}

const defaultTimeout = 10 * 1000; /* 10 Segundos */
const useAxios = makeUseAxios({
  axios: axios.create({
    baseURL: `${VariaveisAmbiente.apiUrl}/api`,
    timeout: defaultTimeout,
    timeoutErrorMessage:
      "O servidor não respondeu a solicitação, tente novamente mais tarde.",
  }),
  cache: new LRU({ max: 10 }),
});


export function useApiBase<T = any>(props?: ApiBaseProps) {

  const { deslogar, validaUsuarioConectado, refreshUser} = useSessaoAtual()
  const history = useHistory()
  const { getRegistro } = useGestaoStorage();
  const { isTokenExpired, convertToken } = useGestaoToken();
  const { config, opcoes } = props || {};
  const [{ loading: carregandoAxios }, invocarAxios] = useAxios<any>(
    config || "",
    merge({ manual: true }, opcoes)
  );

  const [carregandoInterno, setCarregandoInterno] = useState(false);

  useEffect(() => {
    setCarregandoInterno(carregandoAxios);
  }, [carregandoAxios]);

  const invocarApi = useCallback(async (cfg?: RequestParams, options?: Options, tentarNovamente: boolean = true): Promise<RetornoApiModel<T>> => {
    const objRet = { tipoRetorno: EnumRetornoApiBase.Servidor, statusCode: 0 };
    let resultado: AxiosResponse<any> | undefined;
    let erro: Error | undefined;
    resultado = undefined;
    erro = undefined;

    const tokenStorage = getRegistro(GestaoStorageKeys.Token, false);

    const userToken = convertToken(tokenStorage)

    try {

      if (cfg?.enviarTokenUsuario && !isEmpty(userToken) && isTokenExpired(userToken)) {
        throw new ExpiredError('Token Expirado', 401)
      }

      const tokenEnvio = cfg?.enviarTokenUsuario
        ? `Bearer ${tokenStorage}`
        : null;

      resultado = await invocarAxios(
        {
          ...cfg,
          headers: {
            'FrontUrl': VariaveisAmbiente.frontUrl,
            ...(!cfg?.enviarTokenUsuario ? null : {Authorization: tokenEnvio}),
            ...cfg?.headers,
          },
        },
        options
      );

      const { tipoRetorno: tpRetorno, statusCode: retStatus } = ApiBaseResponse(undefined, resultado);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpRetorno;

    } catch (e: Error | any) {
      erro = e;

      const { tipoRetorno: tpRetorno, statusCode: retStatus } = ApiBaseResponse(e, e.response);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpRetorno;

      //TRATAMENTO DE ERRO DE API
      if (e.response && e.response?.data?.title) {
        if (e.response?.data?.errors) {
          if (e.response?.data?.errors?.Mensagens && e.response?.data?.errors?.Mensagens.length > 1) {
            const erroFormatado = e.response?.data?.errors?.Mensagens.join('. ');
            erro = new Error(erroFormatado);
          } else {
            const errorObj =  Object.values(e.response?.data?.errors) as any[]
            erro = new Error(errorObj.map(item => item[0]).join(', '))
          }
        } else {
          erro = new Error(e.response?.data?.title);
        }
      }
      switch (objRet.tipoRetorno) {
        case EnumRetornoApiBase.Local:
          let msg = erro?.message?.toString() || "";
          if (msg.indexOf("timeout") > -1 && msg.indexOf("exceeded") > -1) {
            erro = new Error(
              `O servidor demorou muito para processar a requisição (${(cfg?.timeout || defaultTimeout) / 1000
              }s).`
            );
          }
          else if (msg.toLowerCase().indexOf("network error") > -1) {
            erro = new Error(
              `A comunicação com o servidor foi perdida. Tente novamente em alguns instantes.` +
              (msg.length > 0 ? `Detalhe: ${msg}` : ``)
            );
          }
          break;
        case EnumRetornoApiBase.Api:
          if (retStatus === 401) {
            if (cfg?.enviarTokenUsuario) {
              // TRATAMENTO DO REFRESHTOKEN AUTOMÁTICO
              if (validaUsuarioConectado() && tentarNovamente) {
                const retRefresh = await refreshUser(userToken!.originalToken);
                if (retRefresh) {
                  return await invocarApi(cfg, options);
                }
                else {
                  erro = new Error(
                    `Ocorreu um problema ao executar novamente. Realize o login novamente.`
                  );
                  deslogar(userToken?.usuarioId || '');
                  history.push('/login/expirou');
                }

              } else {
                erro = new Error(
                  `Sua sessão expirou. Realize o login novamente para continuar. (401)`
                );
                deslogar(userToken?.usuarioId || '');
                history.push('/login/expirou');
              }

            }
          }
          if (retStatus === 402) {
            resultado = e.response
          }
          break;
        case EnumRetornoApiBase.Servidor:
          erro = new Error(
            `O procedimento solicitado causou um erro no servidor. Tente novamente em alguns instantes. Detalhe: ${erro?.message}`
          );
          break;
      }
    }
    setCarregandoInterno(false);

    return {
      resultado,
      erro,
      tipoRetorno: objRet.tipoRetorno,
      statusCode: objRet.statusCode,
    };
  },
    [convertToken, deslogar, getRegistro, history, invocarAxios, isTokenExpired, refreshUser, validaUsuarioConectado]
  );

  return {
    invocarApi,
    carregando: carregandoInterno,
  };
}
