import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';
import { useSessaoAtual } from 'services/app';

export class TermoAceiteProps {
    constructor(
        public termoId: string = '',
        public referencia: string = '',
        public localicazao: string = '',
        public aceito: boolean = true,
        public nome: string = '',
        public documento: string = '',
        public ip: string = '',
    ) { }
}

export function usePutPoliticaAceitar() {

    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const { getEmpresa } = useSessaoAtual();

    const putPoliticaAceitar = useCallback(
        (props: TermoAceiteProps) => {
            props.referencia = getEmpresa().ContratoId;
            return invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/aceitar-termo`,
                method: 'PUT',
                data: props,
                enviarTokenUsuario: true
            })
        }
        ,
        [getEmpresa, invocarApi],
    );

    return {
        ...outrasPropriedades,
        putPoliticaAceitar,
    };

}
