import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const DocumentoIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <path d="M34.7368 2H10.1053C7.84737 2 6 3.88182 6 6.18182V35.4545H10.1053V6.18182H34.7368V2ZM32.6842 10.3636H18.3158C16.0579 10.3636 14.2311 12.2455 14.2311 14.5455L14.2105 43.8182C14.2105 46.1182 16.0374 48 18.2953 48H40.8947C43.1526 48 45 46.1182 45 43.8182V22.9091L32.6842 10.3636ZM18.3158 43.8182V14.5455H30.6316V25H40.8947V43.8182H18.3158Z" />
      </DefaultIcon>
    </>
  );
};

