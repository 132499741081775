import { KeyValueModel } from "model";


export const CamposBoletoMock: KeyValueModel[] = [
    new KeyValueModel('banco', "Banco"),
    new KeyValueModel('agencia', "Agência"),
    new KeyValueModel('conta', "Conta"),
    new KeyValueModel('nomeEmpresa', "Beneficiário"),
    new KeyValueModel('documentoEmpresa', "Documento Beneficiário"),
    new KeyValueModel('carteira', "Carteira"),
    new KeyValueModel('nossoNumero', "Nosso Número"),
    new KeyValueModel('dataVencimento', "Data de Vencimento"),
    new KeyValueModel('valorTitulo', "Valor do Título"),
    new KeyValueModel('especie', "Espécie"),
    new KeyValueModel('codigoAceite', "Código de Aceite"),
    new KeyValueModel('dataEmissao', "Data de Emissão"),
    new KeyValueModel('dataJurosMora', "Data de Juros de Mora"),
    new KeyValueModel('valorJurosMoraDia', "Valor do Juros de Mora por Dia"),
    new KeyValueModel('valorMulta', "Valor da Multa"),
    new KeyValueModel('tipoDesconto1', "Tipo de Desconto 1"),
    new KeyValueModel('dataDesconto1', "Data do Desconto 1"),
    new KeyValueModel('valorDesconto1', "Valor do Desconto 1"),
    new KeyValueModel('documentoPagador', "Documento do Pagador"),
    new KeyValueModel('nomePagador', "Nome do Pagador"),
    new KeyValueModel('enderecoPagador', "Endereço do Pagador"),
    new KeyValueModel('bairroPagador', "Bairro do Pagador"),
    new KeyValueModel('cidadePagador', "Cidade do Pagador"),
    new KeyValueModel('ufPagador', "UF do Pagador"),
    new KeyValueModel('cepPagador', "CEP do Pagador")
];
