import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardConta: {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        width: '100%'
    },
    searchBar: {
        display: 'flex',
        padding: theme.spacing(1),
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        },
        '&>div': {
            display: 'flex',
            alignSelf: 'flex-start',
            gap: theme.spacing(2),
            flexBasis: '65%',
            '& .MuiFormControl-root': {
                height: 20,
                background: theme.palette.background.paper
            },
        },
        '& h4': {
            fontSize: '1rem',
            fontWeight: 600,
            flexBasis: '35%'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    ordenarButton: {
        height: 40,
        '& svg': {
            marginRight: 0,
        }
    },
    listContainer: {
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: theme.spacing(2)
    },
    criarButtonContainer: {
        width: '100%'
    }
}));