import { useDefaultPageStyles } from "views/theme/pages-styles"
import { ImportacaoList } from "./components/importacao-list/importacao-list";

export const ImportacaoPage = () => {
    const classes = useDefaultPageStyles();

    return (
        <div className={classes.root}>
            <ImportacaoList/>
        </div>
    )
}