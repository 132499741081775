import { Theme, makeStyles } from "@material-ui/core";
import { EnumStatusBoleto } from "model/enums/enum-status-boleto";

const retornarCorStatus = (situacao: EnumStatusBoleto, theme: Theme): string => {
    switch (situacao) {
        case EnumStatusBoleto.Aceito:
            return theme.palette.success.light;
        case EnumStatusBoleto.Abortada:
            return theme.palette.error.light;
        case EnumStatusBoleto.ErroInterno:
            return theme.palette.error.main;
        case EnumStatusBoleto.Autorizada:
            return theme.palette.success.main;
        case EnumStatusBoleto.Pendente:
            return theme.palette.warning.light;
        case EnumStatusBoleto.Rejeitada:
            return theme.palette.error.dark;
        case EnumStatusBoleto.ProcessandoEstorno:
            return theme.palette.info.main;
        case EnumStatusBoleto.Estornada:
            return theme.palette.warning.dark;
        case EnumStatusBoleto.Expirada:
            return theme.palette.warning.main;
        default:
            return theme.palette.info.light;
    }
}

interface StylesProps {
    status: EnumStatusBoleto;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
    container: {
        width: '100%',
    },
    bottom: {
        display: 'flex',
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        justifyContent: 'flex-end'
    },
    header: {
        padding: `0 ${theme.spacing(3)}px`,
        display: 'flex',
        gap: theme.spacing(2)
    },
    select: {
        marginBottom: theme.spacing(2)
    },
    badgeStatus: {
        padding: theme.spacing(1),
        background: ({ status }) => retornarCorStatus(status, theme),
        borderRadius: theme.shape.borderRadius,
        height: '100%',
        width: 'auto',
        alignSelf: 'center',
        color: ({ status }) => {
            if ([
                EnumStatusBoleto.Rejeitada,
                EnumStatusBoleto.Autorizada,
                EnumStatusBoleto.ProcessandoEstorno,
                EnumStatusBoleto.Expirada,
            ].includes(status)) {
                return theme.palette.common.white;
            }
            return theme.palette.primary.main
        },
        fontWeight: 500
    }
}));