import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        gridGap: 8
    },
    buttonHeader: {
        display: 'flex',
        color: theme.palette.primary.contrastText,
        flexDirection: 'column',
        alignItems: 'center',
        '& svg': {
            margin: 0
        }
    },
    listItem: {
        display: 'flex',
        alignItems: 'center'
    }
}));