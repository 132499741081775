export enum AppEventEnum {
    DialogImportarArquivo,
    DialogBoleto,
    DialogDetalhesLote,
    DialogAviso,
    DialogConfirm,
    DialogContaBancaria,

    ConfirmEvent,
    CancelEvent,

    DialogHistoricoBoleto
}