import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { ContaBancariaListModel } from "model/api/gestao/conta-bancaria/conta-bancaria-list-model";
import { ContaBancariaList } from "./components/conta-bancaria-list";
import { ContasVinculadas } from "./components/contas-vinculadas";

interface DialogContaBancariaListProps {
    aberto: boolean;
    handleClose: () => void;
    onClick?: (model: ContaBancariaListModel, showError?: boolean) => void;
    setLoading?: (loading: boolean) => any;
    id?: string;
    preencherAutomaticamente?: boolean;
}

export const DialogContaBancariaList = ({
    aberto,
    id,
    handleClose,
    onClick,
    setLoading,
    preencherAutomaticamente = true
}: DialogContaBancariaListProps) => {



    return (
        <DialogSaurus
            aberto={aberto}
            tamanho='lg'
            titulo={id ? 'Vincular Contas' : 'Selecione uma Conta'}
            handleClickClose={handleClose}
            isButtonTitleClose
        >
            {(!id && onClick) && (
                <ContaBancariaList
                    aberto={aberto}
                    onClick={onClick}
                    preencherAutomaticamente={preencherAutomaticamente}
                />
            )}
            {id && (
                <ContasVinculadas
                    id={id}
                />
            )}
        </DialogSaurus>
    )
}