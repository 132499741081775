import { CardNaoEncontrado } from "views/components/cards";
import { InformacaoIcon } from "views/components/icons";
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from "./historico-list-styles";
import { BoletoHistoricoModel } from "model/api/gestao/boleto/boleto-historico-model";
import { CardHistoricoBoleto } from "views/components/cards/card-historico-boleto/card-historico-boleto";
import { useMemo } from "react";
import { toDateString } from "utils/to-date";

interface HistoricoListDataProps {
    list: BoletoHistoricoModel[];
    carregando: boolean;
    onCardClick: (model: BoletoHistoricoModel) => void;
}

interface CardByDate {
    dataOcorrencia: string;
    list: BoletoHistoricoModel[]
}

export const HistoricoListData = ({
    list,
    carregando,
    onCardClick
}: HistoricoListDataProps) => {
    const classes = useStyles();

    const listsByDate = useMemo(() => {
        return list.reduce<CardByDate[]>((prev, curr) => {
            const prevArr = [...prev]
            const foundObj = prevArr.find(item => {
                const dia = new Date(item.dataOcorrencia).getDate()
                const mes = new Date(item.dataOcorrencia).getMonth()
                const ano = new Date(item.dataOcorrencia).getFullYear()

                return dia === new Date(curr.data).getDate() &&
                    mes === new Date(curr.data).getMonth() &&
                    ano === new Date(curr.data).getFullYear()
            })

            if (foundObj) {
                const index = prevArr.indexOf(foundObj)
                prevArr[index].list.push(curr)

                return prevArr
            }

            return [
                ...prevArr,
                {
                    dataOcorrencia: curr.data,
                    list: [curr]
                }
            ]
        }, [])
    }, [list])

    return (
        <>
            {list.length > 0 && <Grid container spacing={2} className={classes.listLabels}>
                <Grid item xs={2}>
                    <Typography variant='caption' color='textSecondary'>
                        Número do Boleto
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='caption' color='textSecondary'>
                        Nome
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='caption' color='textSecondary'>
                        Ocorrência
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant='caption' color='textSecondary'>
                        Valor Total
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='caption' color='textSecondary'>
                        Nome do Arquivo
                    </Typography>
                </Grid>
            </Grid>}
            {(list.length === 0 && !carregando) && (
                <CardNaoEncontrado
                    icon={<InformacaoIcon tipo='GERAL' />}
                    mensagem="Nenhum Histórico foi encontrado."
                />
            )}
            {listsByDate.length > 0 && (
                listsByDate.map(item => (
                    <>
                        <Typography className={classes.cardDate}>{toDateString(item.dataOcorrencia)}</Typography>
                        {item.list.map(boleto => (
                        <CardHistoricoBoleto
                            model={boleto}
                        />
                        ))}
                    </>
                ))
            )}
        </>
    )
}