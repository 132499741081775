import { useCallback, useEffect, useRef, useState } from "react";
import { useStyles } from "./drag-drop-input-styles";
import { Button, Typography } from "@material-ui/core";
import { DedoPraCimaIcon } from "views/components/icons/dedo-pra-cima-icon";
import { isEmpty } from "lodash";
import { useToastSaurus } from "services/app";
import { fileToBase64 } from "utils/file-to-base64";
import { DragDropIcon } from "views/components/icons/drag-drop-icon";

export interface FileBase64 {
    base64: string;
    file: File;
}

interface DragDropInputProps {
    setFiles: React.Dispatch<React.SetStateAction<FileBase64[]>>
    extensoes?: string[]
    onDragOverlay?: boolean;
    mostrarExtensoes?: boolean;
}

export const DragDropInput = ({
    setFiles,
    extensoes,
    onDragOverlay,
    mostrarExtensoes
}: DragDropInputProps) => {
    const classes = useStyles();
    const { showToast } = useToastSaurus();

    const [dragging, setDragging] = useState<boolean>(false)
    const dragDropRef = useRef<HTMLDivElement>(null)
    const overlay = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const onUpload = useCallback(async (files: File[]) => {
        const arrFiles = Array.from(files).filter(file => {
            if (!isEmpty(extensoes)) {
                const nomeQuebrado = file.name.split('.')
                const extensao = nomeQuebrado[nomeQuebrado.length - 1].toLowerCase()

                if (!extensoes?.includes(extensao)) {
                    showToast('error', `Extensão do Arquivo ${file.name} não suportada.`)
                    return false
                }
            }
            return true
        })
        let objFiles: FileBase64[] = []
        for (const file of arrFiles) {
            const base64 = await fileToBase64(file)

            objFiles.push({
                base64,
                file
            })
        }

        setFiles(prev => [...prev, ...objFiles])

    }, [extensoes, setFiles, showToast])

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, [])

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(false)

        const { files } = e.dataTransfer;

        if (files && files.length) {
            onUpload(files);
        }
    }, [onUpload])

    const handleDragEnter = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.target !== overlay.current) {
            setDragging(true);
        }
    }, [])

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.target === overlay.current) {
            setDragging(false);
        }

    }, [])

    useEffect(() => {
        let drop: HTMLDivElement;
        setTimeout(() => {
            dragDropRef.current!.addEventListener('dragover', handleDragOver);
            dragDropRef.current!.addEventListener('drop', handleDrop);

            if (onDragOverlay) {
                dragDropRef.current!.addEventListener('dragenter', handleDragEnter);
                dragDropRef.current!.addEventListener('dragleave', handleDragLeave);
            }
            drop = dragDropRef.current!;
        }, 500)


        return () => {
            drop.removeEventListener('dragover', handleDragOver);
            drop.removeEventListener('drop', handleDrop);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classes.uploadBox} ref={dragDropRef}>
            <DragDropIcon tipo='GERAL' class={classes.pdfIcon} />
            <Typography variant='h4' align='center'>
                Arraste e solte o arquivo ou
            </Typography>
            <input
                id='button-file-upload'
                type='file'
                ref={inputRef}
                multiple
                accept={
                    isEmpty(extensoes) ? '*' :
                        extensoes?.reduce<string>((prev, curr) => `${prev},.${curr}`, '')
                }
                onChange={(e: any) => {
                    e.preventDefault();
                    const files = e.target.files;
                    onUpload(files)
                }}
                hidden
            />
            <Button variant='contained' color='primary' onClick={() => {
                if (inputRef.current) {
                    inputRef.current.click();
                }
            }}>
                <DedoPraCimaIcon tipo='BUTTON_PRIMARY' />
                Selecione o desejado
            </Button>
            {mostrarExtensoes && <Typography variant='caption' color='textSecondary'>
                {extensoes?.reduce<string>((prev, curr, i) => `${prev}.${curr}${i + 1 < extensoes.length ? ', ' : ''}`, '')}
            </Typography>}
            {dragging && <div className={classes.overlay} ref={overlay}>
                <Typography align='center' variant='h4'>
                    Solte para adicionar
                </Typography>
            </div>}
        </div>
    )
}