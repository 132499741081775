import { AvisoDialog } from "./components/aviso-dialog";
import { BoletoDialog } from "./components/boleto-dialog";
import { ConfirmDialog } from "./components/confirm-dialog";
import { ContaBancariaDialog } from "./components/conta-bancaria-dialog";
import { DetalhesLoteDialog } from "./components/detalhes-lote-dialog";
import { HistoricoBoletoDialog } from "./components/historico-boleto-dialog";
import { ImportarArquivoDialog } from "./components/importar-arquivo-dialog";

export const RenderModais = () => {

    return (
        <>
            <ImportarArquivoDialog />
            <BoletoDialog />
            <DetalhesLoteDialog />
            <AvisoDialog />
            <ConfirmDialog />
            <ContaBancariaDialog />
            <HistoricoBoletoDialog />
        </>
    );
};
