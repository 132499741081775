import { Box, Typography } from "@material-ui/core";
import { CircularLoading } from "views/components";
import { InicialPageSearchProps } from "../inicial-page-search/inicial-page-search-props";
import { useDefaultPageStyles } from "views/theme/pages-styles";
import { useCallback, useEffect, useState } from "react";
import { useToastSaurus } from "services/app";
import { ApiListModel } from "model/api/api-list-model/api-list-model";
import { Paginacao } from "views/components/paginacao";
import { useCadastros } from "services/app/hooks/cadastros";
import { HistoricoListData } from "./historico-list-data";
import { useStyles } from "./historico-list-styles";
import { DividerSaurus } from "views/components/divider/divider-saurus";
import { toDateString } from "utils/to-date";
import { BoletoHistoricoModel } from "model/api/gestao/boleto/boleto-historico-model";
import { useGetBoletosHistorico } from "data/api/gestao/boleto/get-boleto-historico";
import { isFalseUndefinedNull } from "utils/check-type";

interface HistoricoListProps {
    searchProps: InicialPageSearchProps;
}

export const HistoricoList = ({
    searchProps
}: HistoricoListProps) => {

    const classes = useDefaultPageStyles();
    const listClasses = useStyles();
    const { showToast } = useToastSaurus();
    const { abrirDialogBoleto } = useCadastros();

    const [queryList, setQueryList] = useState<ApiListModel<BoletoHistoricoModel>>({
        list: [],
        pageIndex: 1,
        pageSize: 0,
        totalPages: 0,
        totalResults: 0
    })

    const { getBoletosHistorico, carregando } = useGetBoletosHistorico();

    const search = useCallback(async (page: number) => {
        try {

            const query = new URLSearchParams();

            if(!isFalseUndefinedNull(searchProps.dataInicial)){
                query.append('dInicial', searchProps.dataInicial)
            }

            if(!isFalseUndefinedNull(searchProps.dataFinal)){
                query.append('dFinal', searchProps.dataFinal)
            }

            query.append('Page', page.toString())
            query.append('PageSize', '30')

            const res = await getBoletosHistorico(query.toString())

            if (res.erro) {
                throw res.erro
            }

            if (!res.resultado?.data) {
                throw new Error('Erro ao listar boletos. Tente novamente.')
            }

            if (
                res.resultado?.data.pageIndex > res.resultado?.data.totalPages &&
                res.resultado?.data.totalResults > 0
            ) {
                search(res.resultado.data.totalPages);
                return;
            }

            if (res.resultado.data.list.length > 0) {
                setQueryList(res.resultado.data)
            }


        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getBoletosHistorico, searchProps.dataFinal, searchProps.dataInicial, showToast])

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryList.totalPages || newPage > 0) {
                search(newPage);
            }
        },
        [queryList.totalPages, search]
    );

    useEffect(() => {
        search(1)
    }, [search])

    const onCardClick = useCallback((model: BoletoHistoricoModel) => {
        abrirDialogBoleto(model.boletoId)
    }, [abrirDialogBoleto])

    return (
        <>
            <Box className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo='FULLSIZED' />}
                <Box className={classes.list}>
                    <Paginacao
                        pageChanged={pageChanged}
                        totalPages={queryList.totalPages}
                        totalRegisters={queryList.totalResults}
                        currentPage={queryList.pageIndex}
                    />
                    <Box className={classes.listData}>
                        <Box className={listClasses.indicadorData}>
                            <Typography>Exibindo Histórico - {toDateString(searchProps.dataInicial, 'DD/MM/yyyy')} à {toDateString(searchProps.dataFinal, 'DD/MM/yyyy')}</Typography>
                            <DividerSaurus color="secondary" height={1} />
                        </Box>
                        <Box className={listClasses.listContainer}>
                            <HistoricoListData
                                carregando={carregando}
                                list={queryList.list}
                                onCardClick={onCardClick}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}