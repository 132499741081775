import { Box, Divider } from "@material-ui/core"
import { useStyles } from "./divider-saurus-styles"

interface DividerSaurusProps{
    dashed?: boolean;
    height?: number;
    color?: 'primary' | 'secondary' | 'divider';
}

export const DividerSaurus = ({
    dashed,
    color,
    height
}: DividerSaurusProps) => {

    const classes = useStyles({
        color,
        height
    });

    if(dashed){
        return (
            <Box className={classes.dashedDivider}/>
        )
    }

    return (
        <Divider className={classes.divider} />
    )
}