import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export class IdentidadeAtualizarSenhaProps {
    constructor(
        public email: string = '',
        public senha: string = '',
        public senhaConfirmacao: string = '',
        public redefinirCodigo: string = ''
    ) { }
}

export function usePostIdentidadeAtualizarSenha() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeAtualizarSenha = useCallback(
        (atualizarSenhaProps: IdentidadeAtualizarSenhaProps) =>
            invocarApi({
                url: `${VariaveisAmbiente.apiVersion}/Auth/confirmar-redefinir-senha`,
                method: 'POST',
                data: atualizarSenhaProps,
                enviarTokenUsuario: false
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeAtualizarSenha,
    };
}
