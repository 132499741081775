import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { BoletoImprimirModel } from 'model/api/gestao/boleto/boleto-imprimir-model';
import { useSessaoAtual } from 'services/app';


export function useGetBoletoImprimir() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<BoletoImprimirModel>();
    const { getEmpresa } = useSessaoAtual();

    const getBoletoImprimir = useCallback(
        (id: string) =>
            invocarApi({
                method: 'GET',
                url: `/${VariaveisAmbiente.apiVersion}/Empresa/${getEmpresa().Id}/boleto/${id}/imprimir`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            }),
        [getEmpresa, invocarApi],
    );
    return {
        ...outrasPropriedades,
        getBoletoImprimir,
    };
}