import { useGetBoletoImprimir } from "data/api/gestao/boleto/get-boleto-imprimir";
import { BoletoListModel } from "model/api/gestao/boleto/boleto-list-model"
import { useCallback } from "react";
import { useToastSaurus } from "services/app";
import { CircularLoading } from "views/components";
import { CardNaoEncontrado } from "views/components/cards";
import { CardBoleto } from "views/components/cards/card-boleto/card-boleto";
import { InformacaoIcon } from "views/components/icons";

interface ArquivoListDataProps {
    list: BoletoListModel[];
    carregando: boolean;
    onCardClick: (model: BoletoListModel) => void;
}

export const ArquivoListData = ({
    list,
    carregando,
    onCardClick
}: ArquivoListDataProps) => {

    const { showToast } = useToastSaurus();
    const { getBoletoImprimir, carregando: carregandoImprimir } = useGetBoletoImprimir();

    const handleImprimir = useCallback(async (id: string) => {
        try{
            const res = await getBoletoImprimir(id);

            if(res.erro) throw res.erro

            window.open(res.resultado?.data.url, '_blank')?.print();
        }catch(e: any){
            showToast('error', e.message)
        }
    }, [getBoletoImprimir, showToast])

    const loading = carregando || carregandoImprimir


    return (
        <>
            {loading && <CircularLoading tipo='FULLSIZED'/>}
            {(list.length === 0 && !carregando) && (
                <CardNaoEncontrado
                    icon={<InformacaoIcon tipo='GERAL' />}
                    mensagem="Nenhum Boleto foi encontrado."
                />
            )}
            {list.length > 0 && (
                list.map(boleto => (
                    <CardBoleto
                        onClick={onCardClick}
                        model={boleto}
                        onClickImprimir={handleImprimir}
                    />
                ))
            )}
        </>
    )
}