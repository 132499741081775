import { Route, Switch } from 'react-router-dom';
import { RenderModais } from 'views/components/render-modais/render-modais';
import { ConfiguracoesPage } from 'views/pages/private/configuracoes/configuracoes-page';
import { ImportacaoPage } from 'views/pages/private/importacao/importacao-page';
import { InicialPage } from 'views/pages/private/inicial/inicial-page';
import { MainPage } from 'views/pages/private/main/main-page';

export const RouterMain = () => {
  return (
    <MainPage>
      <Switch>
        <Route path="/inicial/:modo?/:gerenciar?/:dialog?/:id?" exact component={InicialPage} />
        <Route path="/importacao" exact component={ImportacaoPage} />
        <Route path="/configuracoes/:gerenciar?/:id?" exact component={ConfiguracoesPage} />
      </Switch>
      <RenderModais />
    </MainPage>
  );
};
export default RouterMain;