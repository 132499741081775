import { Box, Typography } from "@material-ui/core";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { useStyles } from './dialog-aviso-styles'
import { useCallback } from "react";
import { CancelarIcon, InformacaoIcon } from "views/components/icons";
import { RoundConfirmIcon } from "views/components/icons/aceito-icon";
import { useCadastros } from "services/app/hooks/cadastros";

interface DialogAvisoProps {
    aberto: boolean;
    text: string;
    mode: 'success' | 'error' | 'warning' | 'info'
}

export const DialogAviso = ({
    aberto,
    mode,
    text
}: DialogAvisoProps) => {

    const classes = useStyles({
        mode
    });

    const { fecharDialogAviso } = useCadastros()

    const Icon = useCallback(() => {
        switch (mode) {
            case 'error':
                return <CancelarIcon tipo='GERAL' class={classes.icon} />;
            case 'success':
                return <RoundConfirmIcon tipo='GERAL' class={classes.icon} />;
            case 'info':
                return <InformacaoIcon tipo='GERAL' class={classes.icon} />;
            case 'warning':
                return <InformacaoIcon tipo='GERAL' class={classes.icon} />;
        }
    }, [classes.icon, mode])
    
    return (
        <>
            <DialogSaurus
                tamanho='xs'
                aberto={aberto}
            >
                <Box className={classes.container} onClick={(e) => e.stopPropagation()}>
                    {Icon()}
                    <Typography className={classes.text}>
                        {text.includes('/n') ? text.split('/n').map(value => {
                            return <span>{value}<br/></span>
                        }) : text}
                    </Typography>
                </Box>
            </DialogSaurus>
            <Box className={classes.fecharBox} onClick={() => fecharDialogAviso()}/>
        </>
    )
}