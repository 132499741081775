import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ApiListModel } from 'model/api/api-list-model/api-list-model';
import { useSessaoAtual } from 'services/app';
import { ContaBancariaVinculoModel } from 'model/api/gestao/conta-bancaria/conta-bancaria-vinculo-model';


export function useGetContaBancariaVinculo() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<ApiListModel<ContaBancariaVinculoModel>>();
    const { getEmpresa } = useSessaoAtual();

    const empresaId = getEmpresa().Id;

    const getContasBancariasVinculo = useCallback(
        //page size 0 é sem paginação
        (contaBancariaId: string, page: number = 1, pageSize: number = 0) =>
            invocarApi({
                method: 'GET',
                url: `/${VariaveisAmbiente.apiVersion}/empresa/${empresaId}/conta-bancaria/${contaBancariaId}/vinculo?PageSize=${pageSize}&Page=${page}`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            }),
        [empresaId, invocarApi],
    );
    return {
        ...outrasPropriedades,
        getContasBancariasVinculo,
    };
}