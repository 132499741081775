import { Grid } from "@material-ui/core";
import { useDefaultPageStyles } from "views/theme/pages-styles"
import { AccordionDados } from "./components/accordion-dados/accordion-dados";
import { AccordionSenha } from "./components/accordion-senha/accordion-senha";
import { AccordionEmpresa } from "./components/accordion-empresa/accordion-empresa";
import { AccordionContasBancarias } from "./components/accordion-contas-bancarias/accordion-contas-bancarias";

export const ConfiguracoesPage = () => {

    const classes = useDefaultPageStyles();

    return (
        <div className={classes.root}>
          <Grid container spacing={2} className={classes.accordionContainer}>
            <Grid item xs={12}>
                <AccordionDados />
            </Grid>
            <Grid item xs={12}>
                <AccordionSenha />
            </Grid>
            <Grid item xs={12}>
                <AccordionEmpresa />
            </Grid>
            <Grid item xs={12}>
                <AccordionContasBancarias />
            </Grid>
          </Grid>
        </div>
    )
}