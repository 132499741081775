import { Box, Grid, Typography } from '@material-ui/core';
import { PublicPageHeader } from 'views/components/headers';
import { CircularLoading, useThemeQueries } from 'views';
import { useStyles } from './novo-contrato-styles';
import { useNovoContratoPageLogic } from './novo-contrato-logic';
import { useMemo } from 'react';
import { useStepperStyles } from 'views/components/stepper/stepper-styles';

const NovoContratoPage = () => {
  const { formStepper, formArray, carregando } = useNovoContratoPageLogic();
  const { isMobile } = useThemeQueries();
  const classes = useStyles();
  const stepClasses = useStepperStyles();


  const getButtons = useMemo(() => {
    return (
      <div className={stepClasses.stepButtons}>
        <Box mt={3}>
          <Grid spacing={2} container>
            {formArray[formStepper.currentStep].previousButton && (
              <Grid item xs={4}>
                {formArray[formStepper.currentStep].previousButton}
              </Grid>
            )}

            {formArray[formStepper.currentStep].nextButton && (
              <Grid item xs={8}>
                {formArray[formStepper.currentStep].nextButton}
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    );
  }, [stepClasses.stepButtons, formArray, formStepper.currentStep]);

  return (
    <>
      <PublicPageHeader topOpacity="0.8" />
      <Grid className={classes.root} container justifyContent="center">
        <Grid item xs={11} md={10} lg={6} style={{ maxWidth: 649 }}>
          <div className={classes.stepperOuterContainer}>
            <div className={classes.divForm}>
              {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
              <div className={stepClasses.stepFix}>
                <div className={stepClasses.step}>
                  <div className={stepClasses.stepTitleContent}>
                    <Typography
                      className={stepClasses.stepTitle}
                      variant="h3"
                      color="primary"
                    >
                      {formArray[formStepper.currentStep].formTitle}
                    </Typography>
                    <Box mt={3}>
                      <Typography variant="body1">
                        {formArray[formStepper.currentStep].formDesc}
                      </Typography>
                    </Box>
                  </div>
                  <div className={stepClasses.stepForm}>
                    {formArray[formStepper.currentStep].form}
                  </div>
                  {!isMobile && getButtons}
                </div>
              </div>
              {isMobile && getButtons}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default NovoContratoPage;
