import { Button, Tooltip } from "@material-ui/core"
import { useButtonIconStyles } from "./button-icon-styles";
import { MouseEvent } from "react";

interface ButtonIconProps {
    icon: JSX.Element;
    onClick: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => any;
    color?: 'primary' | 'secondary' | 'error' | 'success' | 'info';
    border?: boolean;
    size?: 'small' | 'medium' | 'large';
    tooltipMessage?: string;
}

export const ButtonIcon = ({
    icon,
    onClick,
    color,
    border,
    size,
    tooltipMessage
}: ButtonIconProps) => {

    const classes = useButtonIconStyles({ color, border });

    return (
        <Tooltip title={tooltipMessage || ''}>
            <Button onClick={(e) => onClick(e)} className={classes.buttonIcon} size={size || 'medium'}>
                {icon}
            </Button>
        </Tooltip>
    )

}

