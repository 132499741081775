import { KeyValueModel } from "model";
import { EnumStatusBoleto } from "model/enums/enum-status-boleto";

export const StatusBoletoMock: KeyValueModel[] = [
    new KeyValueModel(EnumStatusBoleto.Pendente, "Pendente"),
    new KeyValueModel(EnumStatusBoleto.Aceito, "Aceito"),
    new KeyValueModel(EnumStatusBoleto.Autorizada, "Autorizado"),
    new KeyValueModel(EnumStatusBoleto.Estornada, 'Estornado'),
    new KeyValueModel(EnumStatusBoleto.SemRemessa, 'Sem Remessa'),
    new KeyValueModel(EnumStatusBoleto.Expirada, 'Expirado'),
    new KeyValueModel(EnumStatusBoleto.Rejeitada, 'Rejeitado'),
    new KeyValueModel(EnumStatusBoleto.ProcessandoEstorno, 'Processando Estorno'),
    new KeyValueModel(EnumStatusBoleto.ErroInterno, 'Erro Interno'),
    new KeyValueModel(EnumStatusBoleto.Abortada, 'Abortado'),
    new KeyValueModel(EnumStatusBoleto.Todos, "Todos"),
];