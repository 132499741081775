import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerBody: {
        display: "flex",
        flexDirection: 'column',
        position: "relative",
        width: "100%",
        padding: "0px",
        margin: "0px",
        height: '100%'
    },
    containerTela: {
        flex: "0 1 auto",
        position: "relative",
        zIndex: 1,

    },

    mainHeaderContainer: {
        height: "50px",
        width: "inherit",
        [theme.breakpoints.down("sm")]: {
            height: "50px",
        },
        position: "fixed",
        zIndex: 10,
        display: "flex",
        alignItems: "center",
        top: 0,

        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,

        "& svg": {
            width: "1.3rem",
            height: "1.3rem",
            [theme.breakpoints.down("sm")]: {
                width: "1.5rem",
                height: "1.5rem",
            },
        },
    },
    title: {
        fontWeight: 600,
        marginLeft: "15px",
        fontSize: "1.3rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem",
        },
    },
}));