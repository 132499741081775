import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/use-cases/events/event-tools";
import { DialogGeneralProps } from "./general-props";
import { AppEventEnum } from "model/enums/enum-event-app";
import { DialogImportarArquivo } from "views/components/dialog/dialog-importar-arquivo/dialog-importar-arquivo";
import { useLocation } from "react-router-dom";

const importarRoute = `/gerenciar/importar-arquivo`;
const inicialRoute = `/inicial`

export const ImportarArquivoDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const location = useLocation();
    const [modalState, setModalState] = useState<DialogGeneralProps>(
        new DialogGeneralProps()
    );

    useEffect(() => {
        if (location.pathname.includes(importarRoute) && location.pathname.includes(inicialRoute)) {
            const posrota = location.pathname.replace(importarRoute, '');
            if (posrota.length > 0) {
                setModalState(prev => ({
                    aberto: true
                }));
                return;
            }
        }
    }, [location.pathname]);

    const modalAlterado = useCallback(({ aberto }: DialogGeneralProps) => {
        setModalState({
        aberto,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogImportarArquivo, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogImportarArquivo, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogImportarArquivo aberto={modalState.aberto}/>
            )}
        </>
    )
}