import { makeStyles, Theme } from "@material-ui/core";

interface StylesProps{
  possuiClick?: boolean;
}

export const useDefaultCardStyles = makeStyles<Theme, StylesProps>((theme) => ({
  cardContainer: {
    padding: `0 ${theme.spacing(1)}px`,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "transparent",
    transition: "0.3s ease-in-out border",
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "row",
    position: "relative",
    marginTop: "5px",
    boxShadow: '0px 2px 2px 0px #00000040',
    "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0) !important",
    outline: "none",
    cursor: ({possuiClick}) => possuiClick ? 'pointer' : 'auto',
  },
  cardSelected: {
    borderColor: theme.palette.primary.main,
  },
  tagStatus: {
    width: "5px",
    borderRadius: "5px",
    margin: 7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardIcon: {
    width: "50px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: 0,
      width: "22px",
      height: "22px",
      fill: theme.palette.text.primary,
    },
  },
  cardColor: {
    width: "25px",
    height: "25px",
    borderRadius: "5px",
    borderColor: theme.palette.grey[700],
    borderStyle: "solid",
    marginBottom: 10,
    margin: 0,
    boxShadow: theme.shadows[6],
  },
  cardRightButton: {
    width: "50px",
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.divider,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& svg": {
      margin: 0,
      width: "22px",
      height: "22px",
      fill: theme.palette.text.primary,
    },
  },
  cardContent: {
    flex: "1 1 100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    // "& div:last-child": {
    //   marginRight: theme.spacing(1),
    // },
    overflow: "hidden",
    [theme.breakpoints.up('md')]:{
      '& span': {
        fontSize: '11px !important'
      }
    }
  },
  cardContentMobile: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: 'column',
    paddingRight: theme.spacing(1),
    paddingTop: "3px",
    // "& div:last-child": {
    //   marginRight: theme.spacing(1),
    // },
  },
  celulaGrid: {
    marginLeft: theme.spacing(2),
    minWidth: 0,
    "& p": {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },
  celulaGridFull: {
    marginLeft: theme.spacing(2),
    flex: "1 1 auto",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& p": {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },

  celulaGridFullDescriptionNcm: {
    marginLeft: theme.spacing(2),
    flex: "1 1 auto",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& p": {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }
  },

  celulaGridFullMobile: {
    marginLeft: theme.spacing(1),
    flex: "1 1 auto",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& p": {
      maxWidth: '270px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },

  celulaGridFullMobileDialog: {
    marginLeft: theme.spacing(2),
    flex: "1 1 auto",
    flexWrap: "wrap",

    overflow: "hidden",
    "& p": {
      overflow: 'hidden',
    }
  },

  cardImg: {
    display: "flex",
    objectFit: "cover",
    zIndex: 1,
    position: 'relative',
    background: theme.palette.common.white,
    width: "50px",
    height: "50px",
    borderRadius: "5px",
    transition: "0.2s ease-in-out transform",
    "&:hover": {
      transform: "scale(1.5)",
    },
    boxShadow: '0px 1.9698734283447266px 3.939746856689453px 0px #00000026'
  },
  cardinativo: {
    opacity: 0.6,
    filter: 'grayscale(1)',
  },
  divider: {
    backgroundColor: '#f8f8f8',
    width: "100%",
    height: '1px',
  }
}));

