import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const AddReloadIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
            >
                <path d="M16.3267 41.1672C16.3267 39.6024 16.3267 38.2087 16.3267 36.8151C16.3267 36.106 16.547 35.5681 17.3303 35.5681C18.1135 35.5681 18.3093 36.1305 18.3093 36.8395C18.3093 39.1623 18.3093 41.4851 18.3093 43.8079C18.3093 44.2724 18.2114 44.4436 17.6974 44.4436C15.3232 44.4191 12.949 44.4191 10.5748 44.4436C9.88949 44.4436 9.42444 44.1502 9.42444 43.4411C9.44892 42.732 9.93844 42.4631 10.5993 42.4631C11.7742 42.4631 12.9735 42.4631 14.1728 42.4631C14.1239 42.0719 13.8302 42.023 13.6099 41.8763C7.83348 37.7931 4.77395 32.2184 4.50471 25.1278C4.48024 24.541 4.52919 24.0031 5.19005 23.7831C5.85091 23.5386 6.41386 24.0765 6.43834 24.9811C6.53624 28.1108 7.27053 31.0692 8.81253 33.8077C10.5993 36.9129 13.0469 39.3579 16.3267 41.1672Z" />
                <path d="M7.4913 13.6851C8.71512 12.1692 9.8655 10.7022 11.2606 9.43075C15.1034 5.9588 19.5826 4.14948 24.7715 4.00278C25.4814 3.97833 26.1422 4.10058 26.1667 4.95634C26.1912 5.78765 25.5548 5.93436 24.845 5.95881C17.9916 6.27666 12.7292 9.3574 9.10674 15.2255C9.00883 15.3722 8.9354 15.5189 8.78855 15.8123C10.2571 15.8123 11.6278 15.8123 12.974 15.8123C13.9286 15.8123 14.4181 16.1546 14.3691 16.8392C14.2957 17.6949 13.6838 17.8172 12.974 17.8172C10.7466 17.8172 8.51931 17.7927 6.31644 17.8172C5.75349 17.8172 5.5332 17.7194 5.5332 17.0837C5.58216 14.7854 5.55768 12.5115 5.5332 10.2132C5.5332 9.52855 5.70454 8.94174 6.48778 8.94174C7.31997 8.91729 7.51578 9.52855 7.51578 10.2621C7.46683 11.3868 7.4913 12.5359 7.4913 13.6851Z" />
                <path d="M34.0227 8.20823C34.0227 9.7486 34.0227 11.1178 34.0227 12.487C34.0227 13.1961 33.8758 13.8318 33.0191 13.8318C32.1625 13.8318 32.0156 13.1961 32.0156 12.487C32.0156 10.2376 32.0401 7.96372 32.0156 5.7143C32.0156 5.15194 32.1625 4.98079 32.7254 5.00524C35.0507 5.02969 37.3514 5.02969 39.6767 5.00524C40.3375 5.00524 40.8516 5.22529 40.876 5.93435C40.9005 6.71676 40.3865 6.98571 39.6767 6.98571C38.5018 6.98571 37.327 6.98571 35.8829 6.98571C37.1312 7.91482 38.2081 8.62388 39.1382 9.52854C43.3481 13.5139 45.5755 18.404 45.7713 24.1987C45.7713 24.3454 45.7713 24.4921 45.7713 24.6633C45.7468 25.2501 45.4531 25.6902 44.8412 25.7146C44.1803 25.7391 43.8621 25.3234 43.8377 24.6633C43.7642 22.6095 43.495 20.5801 42.8097 18.6485C41.2187 14.1008 38.3305 10.6777 34.0227 8.20823Z" />
                <path d="M41.5865 33.5876C40.069 33.5876 38.6983 33.5876 37.3521 33.5876C36.6423 33.5876 36.0059 33.4409 36.0304 32.5852C36.0304 31.7294 36.6668 31.5827 37.3766 31.5827C39.6284 31.5827 41.8802 31.6072 44.1321 31.5583C44.6705 31.5583 44.8663 31.6805 44.8663 32.2673C44.8419 34.5901 44.8419 36.9129 44.8663 39.2357C44.8663 39.9447 44.6216 40.4826 43.8628 40.4582C43.153 40.4337 42.9082 39.9203 42.9082 39.2601C42.9082 38.0865 42.9082 36.9129 42.9082 35.4703C41.9781 36.7173 41.2439 37.8175 40.3138 38.7955C36.3241 43.001 31.4533 45.226 25.6525 45.3971C25.0406 45.4216 24.4776 45.3482 24.2573 44.6636C24.1349 44.2235 24.3308 43.8812 24.6734 43.6367C24.9671 43.4167 25.3098 43.49 25.6525 43.4656C32.5058 43.0988 37.7437 40.0181 41.3662 34.15C41.3907 34.0033 41.4397 33.8566 41.5865 33.5876Z" />
                <path d="M25.2117 14.8587C19.778 14.8343 15.3722 19.2109 15.3478 24.6388C15.3233 30.1402 19.7045 34.5657 25.1627 34.5657C30.5965 34.5657 34.9777 30.1891 34.9777 24.7366C35.0022 19.2842 30.6209 14.8832 25.2117 14.8587ZM25.1627 32.5852C20.7815 32.5852 17.2814 29.0399 17.3303 24.6388C17.3548 20.2867 20.8549 16.8147 25.1872 16.8147C29.5685 16.8147 33.0686 20.36 33.0441 24.7366C32.9951 29.1132 29.495 32.5852 25.1627 32.5852Z"  />
                <path d="M24.1841 27.8174C24.1841 25.6902 24.1841 25.6902 22.1036 25.6902C21.5651 25.6902 21.0022 25.6902 20.4637 25.6902C19.8028 25.6657 19.3133 25.3723 19.3378 24.6633C19.3623 24.0031 19.8273 23.7097 20.4392 23.7097C21.4917 23.7097 22.5687 23.6853 23.6211 23.7097C24.0617 23.7342 24.1841 23.563 24.1841 23.1473C24.1596 22.1204 24.1841 21.0691 24.1841 20.0422C24.1841 19.382 24.4289 18.8685 25.1387 18.8441C25.8974 18.8196 26.1667 19.3575 26.1667 20.0666C26.1667 21.0446 26.2156 22.0226 26.1422 23.0006C26.0932 23.6364 26.338 23.7586 26.901 23.7342C27.88 23.6853 28.8346 23.7097 29.8136 23.7342C30.4745 23.7342 30.9885 24.0031 30.9885 24.7122C30.9885 25.4212 30.499 25.7146 29.8381 25.7146C28.8101 25.7146 27.7821 25.7391 26.7296 25.7146C26.2401 25.6902 26.0932 25.8369 26.1177 26.3259C26.1422 27.3283 26.1177 28.3308 26.1177 29.3333C26.1177 30.0179 25.873 30.5802 25.1142 30.5802C24.3554 30.5802 24.1107 29.9934 24.1351 29.3088C24.1841 28.7954 24.1841 28.3064 24.1841 27.8174Z" />
            </DefaultIcon>
        </>
    );
};

