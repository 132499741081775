import * as Yup from 'yup'
import { useValidationYupEmail } from '../../../form-validations'

export const useFormRecuperarSenhaValidations = () => {
    const { emailYup } = useValidationYupEmail()
    const FormRecuperarSenhaYupValidations = Yup.object().shape({
        email: emailYup(),
    });

    return {
        FormRecuperarSenhaYupValidations
    }
}

