import { isEmpty } from "lodash";
import { CardNaoEncontrado } from "views/components/cards";
import { CardImportacao } from "views/components/cards/card-importacao/card-importacao";
import { InformacaoIcon } from "views/components/icons";
import { ImportacaoListModel } from "./importacao-list";

interface ImportacaoListDataProps {
    list: ImportacaoListModel[];
    carregando: boolean;
    onCardClick: (model: ImportacaoListModel) => void;
    onSelect: (model: ImportacaoListModel) => void;
    selecionados: ImportacaoListModel[];
}

export const ImportacaoListData = ({
    list,
    carregando,
    onCardClick,
    selecionados,
    onSelect
}: ImportacaoListDataProps) => {


    return (
        <>
            {(list.length === 0 && !carregando) && (
                <CardNaoEncontrado
                    icon={<InformacaoIcon tipo='GERAL' />}
                    mensagem="Nenhum Boleto foi encontrado."
                />
            )}
            {list.length > 0 && (
                list.map(boleto => (
                    <CardImportacao
                        onClick={onCardClick}
                        model={boleto}
                        selecionado={!isEmpty(selecionados.find(model => model.identificador === boleto.identificador))}
                        onSelect={onSelect}
                    />
                ))
            )}
        </>
    )
}