import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    usuarioFormContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&>div': {
            width: '80%',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            '& button': {
                width: '100%'
            }
        }
    },
    contasBancarias: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        gridGap: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'flex-start'
        }
    }
}));