import { CancelarIcon } from "views/components/icons";
import { useStyles } from "./confirmar-importacao-button-styles";
import { Box, Button } from '@material-ui/core'
import { RoundConfirmIcon } from "views/components/icons/aceito-icon";
import { BoletoListModel } from "model/api/gestao/boleto/boleto-list-model";
import { useConfirmSaurus } from "services/app/hooks/confirm-saurus";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { usePostAdicionarBoletos } from "data/api/gestao/boleto/post-adicionar-boletos";
import { picker } from "utils/picker";
import { BoletoFormModel } from "model/api/gestao/boleto/boleto-add-form-model";
import { useShowAviso } from "services/app/hooks/show-aviso";
import { useFormBoletoValidation } from "views/components/form/master/boleto/form-boleto-validation";
import { isEmpty } from "lodash";
import { isEmptyCustom } from "utils/check-type";
import { CamposBoletoMock } from "data/mocks/campos-boleto-mock";

interface ConfirmarImportacaoButtonProps {
    selecionados: BoletoListModel[];
    setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConfirmarImportacaoButton = ({
    selecionados,
    setCarregando
}: ConfirmarImportacaoButtonProps) => {
    const classes = useStyles();
    const { showConfirm } = useConfirmSaurus();
    const { showAviso } = useShowAviso();
    const { push } = useHistory();

    const { postAdicionarBoletos } = usePostAdicionarBoletos();
    const { FormBoletoValidationYup } = useFormBoletoValidation();

    const confirm = useCallback(async () => {
        try {
            setCarregando(true)

            const models = selecionados.map(boleto => picker<BoletoFormModel>(boleto, new BoletoFormModel()));
            const valoresObrigatorios = Object.keys(FormBoletoValidationYup.fields)
            const isValueEmpty = models.reduce<string[]>((prev, curr) => {
                const valores = Object.entries(curr)
                const invalidValue = valores.filter(val => {
                    const found = valoresObrigatorios.find(x => x === val[0] && isEmptyCustom(val[1], true, true, true, false))
                    return found
                })

                if(isEmpty(invalidValue)){
                    return [...prev]
                }

                const strField = `Boleto ${curr.nossoNumero}: ${invalidValue.map((item, i) => {
                    return `${CamposBoletoMock.find(x => x.Key === item[0])?.Value || item[0]}${i + 1 === invalidValue.length && invalidValue.length < 2 ? '' : ','}`
                })}`

                return [...prev, strField]
            }, [])
            if (!isEmpty(isValueEmpty)) {
                throw new Error(`Há campos inválidos nos boletos importados: ${isValueEmpty.reduce<string>((prev, curr) => `${prev}${curr}/n`, '')}`)
            }

            const res = await postAdicionarBoletos(models);

            if (res.erro) throw res.erro

            showAviso('success', 'Boletos adicionados com sucesso!')

            push('/inicial/boletos')

        } catch (e: any) {
            showAviso('error', e.message)
        } finally {
            setCarregando(false)
        }
    }, [FormBoletoValidationYup.fields, postAdicionarBoletos, push, selecionados, setCarregando, showAviso])

    return (
        <Box className={classes.container}>
            <Button
                variant='outlined'
                className={classes.cancelarButton}
                size='small'
                onClick={() => {
                    showConfirm({
                        primaryButtonText: 'Não, desejo continuar',
                        secondaryButtonText: 'Sim, cancelar',
                        title: 'Tem certeza que deseja cancelar?',
                        description: 'Caso cancele a adição dos boletos, será necessário recomeçar novamente.',
                    }).then(() => {

                    }).catch(() => {
                        push('/boletos/arquivo')
                    })
                }}
            >
                <CancelarIcon tipo='BUTTON' />
                Cancelar
            </Button>

            <Button
                variant='contained'
                color='primary'
                size='small'
                disabled={selecionados.length === 0}
                onClick={confirm}
            >
                <RoundConfirmIcon tipo='BUTTON_PRIMARY' />
                Confirmar
            </Button>
        </Box>
    )
}