import { useCallback, useEffect } from "react";
import { BoletoFormModel } from "model/api/gestao/boleto/boleto-add-form-model";
import { FormBoleto } from "views/components/form/master/boleto/form-boleto";
import { useShowAviso } from "services/app/hooks/show-aviso";
import { useCadastros } from "services/app/hooks/cadastros";
import { useHistory } from "react-router-dom";
import { BoletoFormRef } from "../../dialog-boleto";
import { usePostBoleto } from "data/api/gestao/boleto/post-boleto";
import { Grid, Button } from '@material-ui/core'
import { RoundConfirmIcon } from "views/components/icons/aceito-icon";
import { isEmpty } from "lodash";
import { guidEmpty } from "utils/guid-empty";
import { EnumStatusBoleto } from "model/enums/enum-status-boleto";

interface CadastroBoletoProps {
    formRef: React.RefObject<BoletoFormRef>;
    carregando: boolean;
}

export const CadastroBoleto = ({
    formRef,
    carregando
}: CadastroBoletoProps) => {

    const { showAviso } = useShowAviso();
    const { fecharDialogBoleto } = useCadastros();
    const { push } = useHistory();

    const { postBoleto, carregando: carregandoPost } = usePostBoleto()

    const loading = carregando || carregandoPost

    const handleSubmit = useCallback(async (model: BoletoFormModel) => {
        try {
            if(isEmpty(model.contaBancariaId)) model.contaBancariaId = guidEmpty();
            const res = await postBoleto(model);

            if (res.erro) throw res.erro

            const data = res.resultado?.data;
            
            if(data?.status.toString() === EnumStatusBoleto.Rejeitada){
                push('/inicial/boletos/gerenciar/boleto/' + data.id);
                return
            }
            push('/boletos/arquivo')
            showAviso('success', 'Boleto adicionado com sucesso!')
            fecharDialogBoleto();
        } catch (e: any) {
            showAviso('error', e.message, undefined, true)
        }
    }, [fecharDialogBoleto, postBoleto, push, showAviso])

    const fillForm = useCallback(() => {
        formRef.current?.fillForm(new BoletoFormModel());
    }, [formRef])

    useEffect(() => {
        fillForm();
    }, [fillForm])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormBoleto
                    loading={loading}
                    showLoading
                    onSubmit={handleSubmit}
                    ref={formRef}
                    preencherConfigAuto
                />
            </Grid>
            <Grid item xs={12} container justifyContent='flex-end' alignItems="center">
                <Button variant='contained' color='primary' size='small' onClick={() => formRef.current?.submitForm()}>
                    <RoundConfirmIcon tipo='BUTTON_PRIMARY' />
                    Confirmar
                </Button>
            </Grid>
        </Grid>
    )
}