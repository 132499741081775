import { ContaBancariaListModel } from "model/api/gestao/conta-bancaria/conta-bancaria-list-model";
import { CardNaoEncontrado } from "views/components/cards";
import { CardContaBancaria } from "views/components/cards/card-conta-bancaria/card-conta-bancaria";
import { Grid } from '@material-ui/core'
import { useCallback } from "react";
import { useDeleteContaBancaria } from "data/api/gestao/conta-bancaria/delete-conta-bancaria";
import { CircularLoading } from "views/components";
import { useConfirmSaurus } from "services/app/hooks/confirm-saurus";
import { useShowAviso } from "services/app/hooks/show-aviso";

interface ContasListDataProps {
    list: ContaBancariaListModel[];
    carregando: boolean;
    onCardClick: (model: ContaBancariaListModel) => void;
    triggetList: () => Promise<any>;
}

export const ContasListData = ({
    list,
    carregando,
    onCardClick,
    triggetList
}: ContasListDataProps) => {

    const { deleteContaBancaria, carregando: carregandoDelete } = useDeleteContaBancaria();
    const { showAviso } = useShowAviso();
    const { showConfirm } = useConfirmSaurus();

    const deleteContaBancariaWrapper = useCallback(async (id: string) => {
        try {
            const res = await deleteContaBancaria(id);

            if (res.erro) throw res.erro

            if (res.statusCode === 204) {
                showAviso('success', 'Conta excluída com sucesso');
                triggetList()
            }
        } catch (e: any) {
            showAviso('error', e.message)
        }
    }, [deleteContaBancaria, showAviso, triggetList])

    const confirmDelete = useCallback((id: string) => {
        showConfirm({
            title: 'Tem certeza que deseja remover a conta bancária?',
            primaryButtonText: 'Manter conta cadastrada.',
            secondaryButtonText: 'Sim, remover',
        }).then(() => { })
            .catch(() => deleteContaBancariaWrapper(id))
    }, [deleteContaBancariaWrapper, showConfirm])


    return (
        <>
            {carregandoDelete && <CircularLoading tipo='FULLSIZED' />}
            {(list.length === 0) && (
                <Grid item xs={12}>
                    <CardNaoEncontrado
                        icon={<></>}
                        mensagem={carregando ? "Carregando..." : "Nenhuma conta encontrada."}
                        fontType="body1"
                    />
                </Grid>
            )}
            {list.length > 0 && (
                list.map(conta => (
                    <Grid item xs={12}>
                        <CardContaBancaria
                            model={conta}
                            onClick={onCardClick}
                            onDelete={confirmDelete}
                        />
                    </Grid>
                ))
            )}
        </>
    )
}