import { useGetContasBancarias } from "data/api/gestao/conta-bancaria/get-contas-bancarias";
import { ContaBancariaListModel } from "model/api/gestao/conta-bancaria/conta-bancaria-list-model";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CircularLoading } from "views/components/utils";
import { ContasListData } from "views/pages/private/configuracoes/components/accordion-contas-bancarias/components/contas-origem/components/contas-list/contas-list-data";
import { ContasSearchBar, ContasSearchProps } from "views/pages/private/configuracoes/components/accordion-contas-bancarias/components/contas-searchbar/contas-searchbar";
import { Grid } from '@material-ui/core'
import { isEmpty } from "lodash";
import { useShowAviso } from "services/app/hooks/show-aviso";

interface ContaBancariaListProps {
    aberto: boolean;
    onClick: (model: ContaBancariaListModel, showError?: boolean) => void;
    preencherAutomaticamente: boolean;
    setLoading?: (loading: boolean) => any;
}

export const ContaBancariaList = ({
    aberto,
    onClick,
    preencherAutomaticamente = false,
    setLoading,
}: ContaBancariaListProps) => {

    const { showAviso } = useShowAviso();
    const { getContasBancarias, carregando } = useGetContasBancarias();

    const [list, setList] = useState<ContaBancariaListModel[]>([])

    const [searchProps, setSearchProps] = useState<ContasSearchProps>(new ContasSearchProps());

    const search = useCallback(async () => {
        try {
            const res = await getContasBancarias(1, 0);

            if (res.erro) throw res.erro

            if (!res.resultado?.data) {
                throw new Error('Erro ao listar Contas. Tente novamente.')
            }

            setList(res.resultado?.data.list)

            return res.resultado.data.list;
        } catch (e: any) {
            showAviso('error', e.message)
            return false;
        }
    }, [getContasBancarias, showAviso])


    useEffect(() => {
        if (aberto) {
            search()
        }
    }, [aberto, search])

    useEffect(() => {
        (async () => {
            if (preencherAutomaticamente) {
                if (setLoading) setLoading(true);
                const res = await search();
                if (!res) {
                    if (setLoading) setLoading(false);
                    return
                }
                const padrao = res.find(x => x.padrao);

                if (padrao) {
                    onClick(padrao, false);
                    return
                }
                if(res && res.length > 0)
                    onClick(res[0], false);
            }
        })()
    }, [onClick, preencherAutomaticamente, search, setLoading])

    const filteredList = useMemo<ContaBancariaListModel[]>(() => {
        let filtro = list;

        if (!isEmpty(searchProps.query)) {
            filtro = filtro.filter(conta => {
                return conta[searchProps.tipo].toString().toLowerCase().includes(searchProps.query.toLowerCase());
            })

            filtro = filtro.sort((a, b) => {
                const bancoA = a.nomeBanco
                const bancoB = b.nomeBanco

                if (bancoA < bancoB) {
                    return searchProps.alfabetico ? -1 : 1;
                }
                if (bancoA > bancoB) {
                    return searchProps.alfabetico ? 1 : -1;
                }
                return 0;
            })
        }

        return filtro
    }, [list, searchProps.alfabetico, searchProps.query, searchProps.tipo])

    return (
        <Grid container spacing={2}>
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            <Grid item xs={12}>
                <ContasSearchBar
                    searchProps={searchProps}
                    setSearchProps={setSearchProps}
                />
            </Grid>
            <Grid item xs={12}>
                <ContasListData
                    onCardClick={onClick!}
                    triggetList={search}
                    carregando={carregando}
                    list={filteredList}
                />
            </Grid>
        </Grid>
    )
}