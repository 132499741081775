import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { BoletoListModel } from 'model/api/gestao/boleto/boleto-list-model';
import { ImportacaoArquivoPostModel } from 'model/api/gestao/importacao-arquivo/importacao-arquivo-post-model';
import { useSessaoAtual } from 'services/app';


export function usePostImportacaoArquivo() {
    const { invocarApi, ...outrasPropriedades } = useApiBase<BoletoListModel[]>();
    const { getEmpresa } = useSessaoAtual();

    const postImportacaoArquivo = useCallback(
        (model: ImportacaoArquivoPostModel) => {
            model.empresaId = getEmpresa().Id;
            return invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/Empresa/${getEmpresa().Id}/importacao-arquivo`,
                data: model,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true
            })
        },
        [getEmpresa, invocarApi],
    );
    return {
        ...outrasPropriedades,
        postImportacaoArquivo,
    };
}