import { Box, Tooltip, Typography } from "@material-ui/core";
import { useStyles } from "../dialog-importar-arquivo-styles";
import { ButtonIcon } from "views/components/controles";
import { FileBase64 } from "views/components/controles/inputs/drag-drop-input/drag-drop-input";
import { LixoIcon } from "views/components/icons";

interface CardArquivoSelecionadoProps {
    file: File;
    setFile: React.Dispatch<React.SetStateAction<FileBase64[]>>;
}

export const CardArquivoSelecionado = ({
    file,
    setFile
}: CardArquivoSelecionadoProps) => {

    const classes = useStyles();

    return (
        <Box className={classes.card}>
            <Box className={classes.tagStatus} />
            <Box className={classes.textContainer}>
                <Typography variant='caption' color='textSecondary'>
                    Nome do Arquivo
                </Typography>
                <Typography variant='caption' color='textPrimary'>
                    {file.name}
                </Typography>
            </Box>
            <Box>
                <Tooltip title='Remover Arquivo'>
                    <Box>
                        <ButtonIcon
                            icon={<LixoIcon tipo='BUTTON' />}
                            onClick={() => setFile(prev => {
                                return [...prev].filter(arq => arq.file.name !== file.name)
                            })}
                        />
                    </Box>
                </Tooltip>
            </Box>
        </Box>
    )
}