import { KeyValueModel } from "model";
import { EnumBanco } from "model/enums/enum-banco";

import safraLogo from 'assets/img/safra.png'
import itauLogo from 'assets/img/itau.png'
import bbLogo from 'assets/img/bb.png'
import caixaLogo from 'assets/img/caixa.png'
import santanderLogo from 'assets/img/santander.png'
import bradescoLogo from 'assets/img/bradesco.png'

export const BancoLogoMock: KeyValueModel[] = [
    new KeyValueModel(EnumBanco.Safra, safraLogo),
    new KeyValueModel(EnumBanco.Itau, itauLogo),
    new KeyValueModel(EnumBanco.BancoBrasil, bbLogo),
    new KeyValueModel(EnumBanco.Santander, santanderLogo),
    new KeyValueModel(EnumBanco.Caixa, caixaLogo),
    new KeyValueModel(EnumBanco.Bradesco, bradescoLogo)
];