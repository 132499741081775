import { useThemeQueries } from "views/theme";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { ContaBancariaCadastro } from "./components/conta-bancaria-cadastro/conta-bancaria-cadastro";
import { ContaBancariaEdicao } from "./components/conta-bancaria-edicao/conta-bancaria-edicao";
import { useCadastros } from "services/app/hooks/cadastros";

interface DialogContaBancariaProps {
    aberto: boolean;
    id?: string;
    handleClose?: () => void;
}

export const DialogContaBancaria = ({
    aberto,
    id,
    handleClose
}: DialogContaBancariaProps) => {

    const { isMobile } = useThemeQueries();
    const { fecharDialogContaBancaria } = useCadastros();

    return (
        <DialogSaurus
            aberto={aberto}
            tamanho='sm'
            fullScreen={isMobile}
            handleClickClose={() => {
                if (handleClose) {
                    handleClose()
                }
                fecharDialogContaBancaria()
            }}
            isButtonTitleClose
        >
            {!id ? <ContaBancariaCadastro /> : <ContaBancariaEdicao id={id} />}
        </DialogSaurus>
    )
}