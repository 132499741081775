import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { useSessaoAtual } from 'services/app';
import { ContaBancariaVinculoPostModel } from 'model/api/gestao/conta-bancaria/conta-bancaria-vinculo-model';


export function usePostContaBancariaVinculo() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const { getEmpresa } = useSessaoAtual();

    const empresaId = getEmpresa().Id;

    const postContaBancariaVinculo = useCallback(
        (contaBancariaId: string, model: ContaBancariaVinculoPostModel) =>{
            model.empresaId = getEmpresa().Id;
            model.contratoId = getEmpresa().ContratoId;
            return invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.apiVersion}/empresa/${empresaId}/conta-bancaria/${contaBancariaId}/vinculo`,
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                enviarTokenUsuario: true,
                data: model
            })
        },
        [empresaId, getEmpresa, invocarApi],
    );
    return {
        ...outrasPropriedades,
        postContaBancariaVinculo,
    };
}