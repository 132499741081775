import { Button } from '@material-ui/core';
import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { useFormStepper } from '../../../components/form-stepper';
import { ContratoUsuarioFormModel } from '../../../../model/app/forms/master/contrato-usuario-form';
import { FormContratoUsuario } from '../../../components/form/master/contrato-usuario/form-contrato-usuario';
import { DefaultFormRefs } from '../../../components/form/utils/form-default-props';
import { AvancarIcon } from '../../../components/icons/avancar-icon';
import { FormStep } from '../../../../model/app/form-stepper';
import { NovaPessoaIcon } from '../../../components/icons/nova-pessoa-icon';
import { VoltarIcon } from '../../../components/icons/voltar-icon';
import { useGetUsuarioValidarCadastro } from '../../../../data/api/gestao/usuario/get-validar-cadastro';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { LojaIcon } from '../../../components/icons/loja-icon';
import { FormContratoDocumento } from '../../../components/form/master/contrato-documento/form-contrato-documento';
import { ContratoDocumentoFormModel } from '../../../../model/app/forms/master/contrato-documento-form';
import { useGetEmpresaValidarExistente } from '../../../../data/api/gestao/empresa/get-empresa-validar';
import { useConsultaCnpj } from '../../../../data/api/wsmaster/consultar-cnpj';
import { EmpresaFormModel } from '../../../../model/app/forms/master/empresa-form';
import { stringNumeros } from "utils/string-numeros"
import { FormEmpresa } from '../../../components/form/master/empresa/form-empresa';
import { OkIcon } from '../../../components/icons/ok-icon';
import { useGetSegmentoCnae } from '../../../../data/api/gestao/segmentos';
import { SegmentoModel } from '../../../../model/api/gestao/master/segmento';
import { EmpresaModel } from '../../../../model/api/gestao/master/empresa';
import { IdentidadeNovoContratoProps, usePostIdentidadeNovoContrato } from '../../../../data/api/gestao/identidade/post-novo-contrato';
import { picker } from '../../../../utils/picker';
import { LocalizacaoModel } from 'model/api/gestao/localizacao/localizacao-model';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { EmpresaEnderecoFormModel } from 'model/app/forms/master/empresa-endereco-form';
import { DocumentoIcon } from 'views/components/icons';
import { FormEnderecoEmpresa } from 'views/components/form/master/empresa/form-empresa-endereco';
import { chaveDemonstracao, sistemaId } from 'config';
import { isEmpty } from 'lodash';

export const useNovoContratoPageLogic = () => {
    const { showToast } = useToastSaurus();
    const confirm = useConfirm();
    const history = useHistory();
    const { currentStep, nextStep, prevStep } = useFormStepper(4);
    const contratoUsuarioRefs = useRef<DefaultFormRefs<ContratoUsuarioFormModel>>(null);
    const contratoDocumentoRefs = useRef<DefaultFormRefs<ContratoDocumentoFormModel>>(null);
    const empresaFormRefs = useRef<DefaultFormRefs<EmpresaFormModel>>(null);
    const empresaEnderecoFormRefs = useRef<DefaultFormRefs<EmpresaEnderecoFormModel>>(null);
    const [localizacao, setLocalizacao] = useState<LocalizacaoModel | undefined>(undefined)
    const { consultarCNPJ, carregando: carregandoCNPJ } = useConsultaCnpj();
    const { postIdentidadeNovoContrato, carregando: carregandoNovoContrato } = usePostIdentidadeNovoContrato()

    const { carregando: carregandoGetUsuarioValidarCadastro, getUsuarioValidarCadastro } = useGetUsuarioValidarCadastro();
    const { carregando: carregandoGetEmpresaValidarExitente, getEmpresaValidarExitente } = useGetEmpresaValidarExistente();
    const [carregandoFromForms, setCarregandoFromForms] = useState(false);
    const { carregando: carregandoGetSegmentos, getSegmentoCnae } = useGetSegmentoCnae();
    const { carregando: carregandoLogin, setarUser, usuario } = useSessaoAtual();

    const loading: boolean =
        carregandoGetSegmentos ||
        carregandoGetUsuarioValidarCadastro ||
        carregandoGetEmpresaValidarExitente ||
        carregandoCNPJ ||
        carregandoFromForms ||
        carregandoNovoContrato ||
        carregandoLogin;

    const usuarioModel = useRef<ContratoUsuarioFormModel>(new ContratoUsuarioFormModel());
    const documentoModel = useRef<ContratoDocumentoFormModel>(new ContratoDocumentoFormModel());
    const empresaModel = useRef<EmpresaFormModel & EmpresaEnderecoFormModel>({
        ...new EmpresaFormModel(),
        ...new EmpresaEnderecoFormModel()
    });
    const segmentoSelecionado = useRef<SegmentoModel>(new SegmentoModel());

    const finalizarCadastro = useCallback(async () => {
        try {
            const novoContrato = new IdentidadeNovoContratoProps();
            novoContrato.nome = usuarioModel.current.saudacao;
            novoContrato.email = usuarioModel.current.email;
            novoContrato.senha = usuarioModel.current.senha;
            novoContrato.apelido = usuarioModel.current.apelido;
            novoContrato.telefone = usuarioModel.current.fone;
            novoContrato.senhaConfirmacao = usuarioModel.current.confirmar;
            novoContrato.sistemaId = sistemaId;

            novoContrato.empresa = picker<EmpresaModel>(empresaModel.current, novoContrato.empresa);
            novoContrato.empresa.segmento = segmentoSelecionado.current.descricao;
            novoContrato.empresa.segmentoId = segmentoSelecionado.current.id;

            const ret = await postIdentidadeNovoContrato(novoContrato, chaveDemonstracao);
            if (ret.erro) {
                throw ret.erro;
            }

            try {
                await setarUser(ret.resultado?.data.accessToken, false)
                showToast("success", "Bem vindo! Seu cadastro foi finalizado e agora fique à vontade para utilizar o sistema.");
            } catch (e: Error | any) {
                showToast("error", "Erro ao realizar o Login após o cadastramento. Tente realizar o login manualmente. Detalhe: " + e.message);
            }
        } catch (e: Error | any) {
            showToast("error", "Erro ao Inicializar seu Contrato. Tente novamente em alguns instantes. Detalhe: " + e.message);
        }
    }, [postIdentidadeNovoContrato, setarUser, showToast]);

    useEffect(() => {
        // EFFECT PARA DIRECIONAR APOS FINALIZAR O CONTRATO
        if (usuario) {
            history.push("/termos");
        }
    }, [history, usuario]);

    const voltarStep = useCallback(() => {
        switch (currentStep) {
            case 0:
                history.push('/login');
                break;
            case 1:
            case 2:
                prevStep();
                break;
            case 3:
                prevStep();
                break;
        }
    }, [currentStep, history, prevStep]);

    const avancarStep = useCallback(() => {
        switch (currentStep) {
            case 0:
                contratoUsuarioRefs.current?.submitForm();
                break;
            case 1:
                contratoDocumentoRefs.current?.submitForm();
                break;
            case 2:
                empresaFormRefs.current?.submitForm();
                break;
            case 3:
                empresaEnderecoFormRefs.current?.submitForm();
        }
    }, [currentStep]);

    const voltarButton = useMemo(() => {
        return (
            <Button
                type="submit"
                color="primary"
                variant="outlined"
                fullWidth={true}
                disabled={loading}
                onClick={voltarStep} >
                <VoltarIcon tipo="BUTTON" />
                Voltar
            </Button>
        )
    }, [voltarStep, loading]);

    const avancarButton = useMemo(() => {
        return (
            <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth={true}
                disabled={loading}
                onClick={avancarStep} >
                <AvancarIcon tipo="BUTTON_PRIMARY" />
                Continuar
            </Button>
        )
    }, [loading, avancarStep]);

    const finalizarButton = useMemo(() => {
        return (
            <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth={true}
                disabled={loading}
                onClick={avancarStep} >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Finalizar Cadastro
            </Button>
        )
    }, [loading, avancarStep]);

    useEffect(() => {
        switch (currentStep) {
            case 0:
                contratoUsuarioRefs.current?.fillForm(usuarioModel.current);
                break;
            case 1:
                contratoDocumentoRefs.current?.fillForm(documentoModel.current);
                break;
            case 2:
                empresaFormRefs.current?.fillForm(empresaModel.current);
                break;
            case 3:
                empresaEnderecoFormRefs.current?.fillForm(empresaModel.current)
        }
    }, [confirm, currentStep, getEmpresaValidarExitente, history, showToast]);

    const getFormUsuario = useCallback((): JSX.Element => {
        const handleSubmitFormUsuario = async (usuario: ContratoUsuarioFormModel) => {
            try {
                const ret = await getUsuarioValidarCadastro(usuario.email, usuario.fone, usuario.apelido);
                if (ret.statusCode === 400 || ret.resultado?.data === true) {
                    confirm({
                        title: 'Já está cadastrado?',
                        description: 'Detectamos que seus dados cadastrais (apelido, e-mail ou telefone) já constam em nossa base de dados. Deseja recuperar sua conta?',
                        cancellationText: 'Continua Novo Cadastro',
                        confirmationText: 'Recuperação de Senha',
                    })
                        .then(() => {
                            history.push('/recuperar-senha');
                        })
                        .catch(() => {
                            contratoUsuarioRefs.current?.fillForm(usuario);
                        });
                    return;
                } else if (ret.erro) {
                    throw ret.erro;
                }
                usuarioModel.current = usuario;
                nextStep();
            } catch (e: Error | any) {
                showToast("error", e.message);
            }
        };
        return (
            <FormContratoUsuario showLoading={false} ref={contratoUsuarioRefs} loading={loading} onSubmit={handleSubmitFormUsuario} />
        );
    }, [confirm, getUsuarioValidarCadastro, history, loading, nextStep, showToast]);

    const getFormDocumento = useCallback((): JSX.Element => {

        if ('geolocation' in navigator) {
            var options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    setLocalizacao(
                        new LocalizacaoModel(position.coords.latitude, position.coords.longitude)
                    );
                },
                function (error) {

                },
                options,
            );
        }

        const handleSubmitFormDocumento = async (modelo: ContratoDocumentoFormModel) => {
            try {
                empresaModel.current = {
                    ...new EmpresaFormModel(modelo.documento),
                    ...new EmpresaEnderecoFormModel()
                }

                documentoModel.current = modelo

                const ret = await getEmpresaValidarExitente(stringNumeros(modelo.documento));

                if (ret.erro) {
                    throw ret.erro;
                }
                if (ret?.resultado?.data === true) {
                    throw new Error(`O CNPJ ${modelo.documento} já está em uso, favor entrar em contato com o administrador da empresa.`);
                }

                if (stringNumeros(modelo.documento).length === 14) {
                    //SE FOR CNPJ
                    try {
                        let empresa = await consultarCNPJ(modelo.documento as string, localizacao);
                        empresaModel.current.bairro = empresa.bairro;
                        empresaModel.current.cep = empresa.cep;
                        empresaModel.current.cMunicipio = empresa.cMunicipio;
                        empresaModel.current.complemento = empresa.complemento;
                        empresaModel.current.uf = empresa.cMunicipio.substring(0, 2);
                        empresaModel.current.logradouro = empresa.logradouro;
                        empresaModel.current.municipio = empresa.municipio;
                        empresaModel.current.nomeFantasia = empresa.nomeFantasia;
                        empresaModel.current.regime = empresa.regime;
                        empresaModel.current.numero = empresa.numero;
                        empresaModel.current.cnae = empresa.cnae;
                        empresaModel.current.razaoSocial = empresa.razaoSocial;
                        empresaModel.current.uf = empresa.uf;
                    } catch (e: Error | any) {
                        console.error(e.message);
                    }
                } else if (stringNumeros(modelo.documento).length === 11) {
                    empresaModel.current.razaoSocial = usuarioModel.current.saudacao
                    empresaModel.current.nomeFantasia = usuarioModel.current.saudacao
                }
                nextStep();
            } catch (e: Error | any) {
                showToast("error", e.message);
            }
        };

        return (
            <FormContratoDocumento showLoading={false} ref={contratoDocumentoRefs} loading={loading} onSubmit={handleSubmitFormDocumento} />
        );
    }, [loading, getEmpresaValidarExitente, nextStep, consultarCNPJ, localizacao, showToast]);

    const getFormEmpresa = useCallback((): JSX.Element => {
        const handleSubmitFormEmpresa = async (modelo: EmpresaFormModel) => {
            try {
                empresaModel.current = {
                    ...empresaModel.current,
                    ...modelo,
                    fone: usuarioModel.current.fone,
                };
                if (!isEmpty(modelo.cnae) && modelo.cnae !== '0000000') {
                    const ret = await getSegmentoCnae(empresaModel.current.cnae);
                    if (ret?.resultado?.data?.length > 0) {
                        segmentoSelecionado.current = ret?.resultado?.data[0];
                    }
                }

                if (stringNumeros(empresaModel.current.cpfcnpj).length === 14 && empresaModel.current.cnae.length !== 0) {
                    finalizarCadastro();
                    return
                }

                nextStep();
            } catch (e: Error | any) {
                showToast("error", e.message);
            }
        };
        return (
            <FormEmpresa showLoading={false} ref={empresaFormRefs} setCarregandoExterno={setCarregandoFromForms} loading={loading} onSubmit={handleSubmitFormEmpresa} />
        );
    }, [loading, getSegmentoCnae, nextStep, finalizarCadastro, showToast]);

    const getFormEmpresaEndereco = useCallback((): JSX.Element => {
        const handleSubmitFormEmpresaEndereco = async (modelo: EmpresaEnderecoFormModel) => {
            try {
                empresaModel.current = {
                    ...empresaModel.current,
                    ...modelo
                };

                finalizarCadastro();

            } catch (e: Error | any) {
                showToast("error", e.message);
            }
        };
        return (
            <FormEnderecoEmpresa showLoading={false} ref={empresaEnderecoFormRefs} setCarregandoExterno={setCarregandoFromForms} loading={loading} onSubmit={handleSubmitFormEmpresaEndereco} />
        );
    }, [loading, finalizarCadastro, showToast]);


    const getFormArray = useMemo(() => {
        return [
            new FormStep(
                "Criar Conta",
                "Legal! Para ingressar, preencha os campos abaixo para iniciarmos seu cadastro. São apenas alguns passos para finalizarmos.",
                <NovaPessoaIcon tipo="GERAL" />,
                "Usuário",
                <NovaPessoaIcon tipo="GERAL" />,
                getFormUsuario(),
                voltarButton,
                avancarButton
            ), new FormStep(
                "Informe o Documento da sua Empresa",
                "Agora informe o CNPJ ou CPF da sua empresa para podermos personalizar seu ambiente corretamente.",
                <DocumentoIcon tipo="GERAL" />,
                "Documento",
                <DocumentoIcon tipo="GERAL" />,
                getFormDocumento(),
                voltarButton,
                avancarButton
            ), new FormStep(
                stringNumeros(empresaModel.current.cpfcnpj).length === 11 ?
                    "Último Passo"
                    :
                    stringNumeros(empresaModel.current.cpfcnpj).length === 14 && empresaModel.current.cnae.length !== 0 ?
                        "Último Passo"
                        :
                        "Último Passo",
                stringNumeros(empresaModel.current.cpfcnpj).length === 11 ?
                    `Seu cadastro está quase no final, ${usuarioModel.current.saudacao.split(' ')[0]}. Informe seu contato abaixo e clique em Finalizar Cadastro.`
                    :
                    stringNumeros(empresaModel.current.cpfcnpj).length === 14 && empresaModel.current.cnae.length !== 0 ?
                        `Seu cadastro está quase no final, ${usuarioModel.current.saudacao.split(' ')[0]}. Informe seu contato abaixo e clique em Finalizar Cadastro.`
                        :
                        "Não identificamos os dados da sua empresa, informe os dados abaixo para finalizar seu cadastro.",
                <LojaIcon tipo="GERAL" />,
                "Empresa",
                <LojaIcon tipo="GERAL" />,
                getFormEmpresa(),
                voltarButton,
                stringNumeros(empresaModel.current.cpfcnpj).length === 14 && empresaModel.current.cnae.length !== 0 ? finalizarButton : avancarButton
            ),
            new FormStep(
                'Endereço',
                'Informe o Endereço da sua Empresa',
                <OkIcon tipo="GERAL" />,
                "Conclusão",
                <OkIcon tipo="GERAL" />,
                getFormEmpresaEndereco(),
                voltarButton,
                finalizarButton
            )
        ];
    }, [getFormUsuario, voltarButton, avancarButton, getFormDocumento, getFormEmpresa, finalizarButton, getFormEmpresaEndereco]);

    return {
        formStepper: {
            currentStep,
            nextStep,
            prevStep
        },
        formArray: getFormArray,
        carregando: loading
    };


}